import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
// import ConfirmationModal from '@components/ConfirmationModal';
import { BUY_SIMS_LINK } from '@core/constants/links';
import { PaymentIntent, PaymentSuccess, SetupIntent, SetupIntentSuccess } from '@modules/dashboard/billing';
import { getItem } from '@utils/localStorage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BillingBalance, BillingDetails, Countries } from 'src/types/types';
import { InfoToolTip } from '../InfoToolTip/InfoToolTip';

type HeaderNavigationProps = {
  balance: BillingBalance;
  sidebarOpen: boolean;
  countriesData: Countries;
  tax: number;
  billingDetails: BillingDetails;
  updateBillingDetails: (details: Partial<BillingDetails>) => void;
  topUpIsProcessing: boolean;
};

export const HeaderNavigation = ({
  balance,
  sidebarOpen,
  countriesData,
  tax,
  billingDetails,
  updateBillingDetails,
  topUpIsProcessing,
}: HeaderNavigationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBillingModalOpen, setIsBillingModalOpen] = useState<boolean>(false);
  const [isSetupIntentModalOpen, setIsSetupIntentModalOpen] = useState<boolean>(false);
  const [isPaymentSuccessOpen, setIsPaymentSuccessOpen] = useState<boolean>(false);
  const [isSetupIntentSuccessOpen, setIsSetupIntentSuccessOpen] = useState<boolean>(false);

  const TopUpInfoBox = (
    <div className="tw-flex tw-max-w-40 tw-flex-col tw-items-center tw-gap-6">
      <span className="tw-text-sm tw-text-sb-blue-500">{t('home.button.topUp')}</span>
    </div>
  );

  const handleClosePaymentSuccessModal = () => {
    setIsPaymentSuccessOpen(false);
    setIsSetupIntentSuccessOpen(false);
    navigate({ search: '' }, { replace: true });
  };

  const blueDivClasses = [
    sidebarOpen ? 'tw-pl-60 sm:tw-h-10' : 'tw-pl-[63px] headerNavigation:tw-h-10',
    'tw-bg-sb-blue-500',
    'tw-flex',
    'tw-h-20',
    'tw-w-full',
    'tw-flex-shrink-0',
    'tw-justify-center',
    'tw-pr-6',
  ].join(' ');

  const role: string = getItem('role');

  return (
    <div className="tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-z-30">
      <div className={blueDivClasses}>
        <div
          className={`tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col tw-items-end tw-justify-end ${sidebarOpen ? 'sm:tw-flex-row' : 'headerNavigation:tw-flex-row'} ${sidebarOpen ? 'sm:tw-items-center' : 'headerNavigation:tw-items-center'}`}
        >
          <Button
            size="small"
            className="!tw-text-sm !tw-font-normal tw-text-white tw-underline"
            newTab={true}
            variant="borderless"
            asLink={true}
            href={BUY_SIMS_LINK}
          >
            {t('home.buySims')}
          </Button>
          <Button
            size="small"
            className="!tw-text-sm !tw-font-normal tw-text-white tw-underline hover:tw-no-underline"
            newTab={true}
            variant="borderless"
            onClick={() => {
              navigate('/dashboard/activate-sims');
            }}
          >
            {t('home.registerSims')}
          </Button>
          {!balance?.postpaid && (
            <Button
              size="small"
              variant="borderless"
              onClick={() => setIsBillingModalOpen(true)}
              disabled={topUpIsProcessing || role === 'user'}
            >
              <div className={`tw-flex tw-items-center tw-gap-2`}>
                <span
                  className={`tw-text-sm tw-text-white ${role === 'user' ? '' : 'tw-underline hover:tw-no-underline'}`}
                >{`${t('home.balance')}: `}</span>
                {balance && (
                  <span
                    data-testid="user-balance"
                    className={`tw-text-xl tw-font-bold ${balance?.balance > 0 ? 'tw-text-green-400' : 'tw-text-sb-orange-400'}`}
                  >{`${balance.currency_symbol}${balance?.balance.toFixed(2)}`}</span>
                )}
                {role !== 'user' && (
                  <InfoToolTip className="tw-mt-2" title={TopUpInfoBox}>
                    <div className="tw-flex tw-items-center">
                      <Icon name="question_info" width={14} height={14} />
                    </div>
                  </InfoToolTip>
                )}
              </div>
            </Button>
          )}
        </div>
      </div>
      <PaymentIntent
        isOpen={isBillingModalOpen}
        closeModal={() => setIsBillingModalOpen(false)}
        initialVat={1 + tax || 1}
        countries={countriesData}
        billingDetails={billingDetails}
        updateBillingDetails={updateBillingDetails}
      />
      <PaymentSuccess isOpen={isPaymentSuccessOpen} handleClose={handleClosePaymentSuccessModal} />
      <SetupIntentSuccess isOpen={isSetupIntentSuccessOpen} handleClose={handleClosePaymentSuccessModal} />
      <SetupIntent isOpen={isSetupIntentModalOpen} handleCloseModal={() => setIsSetupIntentModalOpen(false)} />
    </div>
  );
};
