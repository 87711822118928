import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';

const baseClass = `tw-px-2.5 tw-py-0.5 tw-rounded tw-border tw-border-solid tw-inline-flex tw-gap-2.5 tw-items-center tw-text-xs`;

const stateClasses = {
  enabled: 'tw-border-sb-green-400 tw-bg-sb-green-50',
  disabled: 'tw-border-sb-orange-400 tw-bg-sb-orange-100',
  enabling: 'tw-border-sb-blue-100 tw-bg-sb-blue-grey-100',
  disabling: 'tw-border-sb-yellow-400 tw-bg-sb-yellow-100',
  deleted: 'tw-border-sb-orange-200 tw-bg-sb-orange-50 tw-text-sb-orange-400',
  'insufficient funds': 'tw-border-sb-orange-500 tw-bg-sb-orange-200 tw-text-sb-orange-600',
};

const iconClasses = {
  enabled: '!tw-text-sb-green-400',
  disabled: '!tw-text-sb-orange-400',
  enabling: '!tw-text-sb-blue-200',
  disabling: '!tw-text-sb-yellow-400',
  deleted: '!tw-text-sb-orange-400',
  'insufficient funds': '!tw-text-sb-orange-600 ',
};

export const StateCells = React.memo(
  ({
    simState,
  }: {
    simState: 'enabled' | 'disabled' | 'enabling' | 'disabling' | 'deleted' | 'insufficient funds';
  }) => {
    const { t } = useTranslation();
    const [state, setState] = useState(simState ? simState : 'enabling');

    useEffect(() => {
      if (simState) {
        setState(simState);
      }
    }, [simState]);
    return (
      <div className={`${baseClass} ${stateClasses[state]}`}>
        <Icon name="dot" className={iconClasses[state]}></Icon>
        <span>{t(`simcard.${simState ? state.split('').join('') : state}`)}</span>
      </div>
    );
  },
);
