import { TAG_COLORS } from '@core/constants/simcards_constants';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from '../Icon/Icon';

type ColorPickerDropdownProps = {
  setSelectedTag: (color: string) => void;
  selectedTag?: string;
};

export const ColorPickerDropdown = ({ selectedTag = 'orange-300', setSelectedTag }: ColorPickerDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const triggerRef = useRef(null);
  const [dropdownStyles, setDropdownStyles] = useState({});

  useEffect(() => {
    if (dropdownOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setDropdownStyles({
        position: 'absolute',
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        zIndex: 1000,
      });
    }
  }, [dropdownOpen]);

  const handleColorClick = (colorClassName: string) => {
    setSelectedTag(colorClassName);
    setDropdownOpen(false);
  };

  const COLORS_ARRAY = useMemo(
    () =>
      Object.entries(TAG_COLORS).map(([className, { name, hexCode }]) => ({
        className: 'tw-bg-sb-' + className,
        name,
        hexCode,
      })),
    [TAG_COLORS],
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const selectedColorHex = TAG_COLORS[selectedTag]?.hexCode || '#FF8C80';

  return (
    <div className="tw-relative" ref={dropdownRef}>
      <button
        ref={triggerRef}
        onClick={() => setDropdownOpen((prev) => !prev)}
        className={`tw-flex tw-h-11 tw-w-12 tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-solid ${
          dropdownOpen ? 'tw-border-sb-blue-300 tw-shadow-seach-modal-shadow' : 'tw-border-sb-blue-grey-300'
        } tw-m-0 tw-appearance-none tw-bg-transparent tw-p-0`}
        aria-haspopup="listbox"
        aria-expanded={dropdownOpen}
        aria-label="Select a color"
      >
        <div className="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center">
          <Icon name="tag" height={20} width={14} strokeColor={selectedColorHex} />
        </div>
      </button>
      {dropdownOpen &&
        ReactDOM.createPortal(
          <div
            ref={dropdownMenuRef}
            className="tw-grid tw-w-80 tw-grid-cols-9 tw-gap-2 tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5 tw-shadow-color-picker"
            style={dropdownStyles}
            role="listbox"
          >
            {COLORS_ARRAY.map(({ name, className }) => (
              <button
                key={className}
                onClick={() => handleColorClick(className.slice(9))}
                className={`${className} tw-m-0 tw-flex tw-h-7 tw-w-7 tw-cursor-pointer tw-appearance-none tw-items-center tw-justify-center tw-rounded tw-border-none tw-p-0`}
                aria-label={`Select ${name}`}
              >
                {selectedTag === className.slice(9) && <Icon name="check" />}
              </button>
            ))}
          </div>,
          document.body,
        )}
    </div>
  );
};
