import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { FC, Fragment } from 'react';
import { createPortal } from 'react-dom';
import TransparentInput from '../TrasparentInput/TransparentInput';

type ModalProps = {
  modalOpen: boolean;
  closeModal: () => void;
  placeholder: string;
  inputValue: string;
  onChange: (newValue: string) => void;
  icon?: string;
  className?: string;
  buttonText?: string;
  buttonFunction?: () => void;
  setInputRef: (element: HTMLInputElement | null) => void;
};

export const InputModal: FC<ModalProps> = ({
  modalOpen,
  closeModal,
  placeholder,
  inputValue,
  onChange,
  className,
  icon,
  buttonText,
  buttonFunction,
  setInputRef,
}) => {
  const modalContent = (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog as="div" className="tw-fixed tw-inset-0 tw-z-20 tw-overflow-y-auto" onClose={closeModal}>
        <div
          className="tw-fixed tw-inset-0 tw-overflow-visible tw-bg-black/50 tw-backdrop-blur-sm"
          aria-hidden="true"
        />
        <div className="tw-mt-[20vh] tw-flex tw-h-auto tw-justify-center tw-p-4">
          <TransitionChild
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <DialogPanel
              className="tw-relative tw-w-full tw-max-w-md tw-rounded-xl tw-bg-white tw-backdrop-blur-2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <TransparentInput
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => onChange(e.target.value)}
                className={className}
                icon={icon}
                buttonText={buttonText}
                buttonFunction={buttonFunction}
                ref={setInputRef}
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
  return createPortal(modalContent, document.body);
};
