import { AlternateModal } from '@components/Atoms/AlternateModal/AlternateModal';
import { Button } from '@components/Atoms/Button/Button';
import { useSendSmsMutation } from '@modules/dashboard/simCard/simcards-api-slice';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const MAX_SMS_BYTES = 140;
interface SendSmsModalProps {
  open: boolean;
  onClose: () => void;
  iccids: string[];
}

export const SendSmsModal: React.FC<SendSmsModalProps> = React.memo(({ open, onClose, iccids }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const [sendSms, sendSmsResponse] = useSendSmsMutation();

  const recipientText = useMemo(() => {
    const count = iccids.length;
    return t('simcards.sendSmsToRecipient', {
      noOfRecipients: count,
      recipientOrRecipients: count > 1 ? t('recipients') : t('recipient'),
    });
  }, [iccids.length, t]);

  const byteCount = useMemo(() => {
    const textEncoder = new TextEncoder();
    return textEncoder.encode(message).length;
  }, [message]);

  const handleMessageChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  }, []);

  const sendMessage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await toast.promise(sendSms({ iccids, message: message }).unwrap(), {
          pending: t('simcards.sendSms.sendingSms'),
          success: t('simcard.messageSentSuccessfully'),
        });
        onClose();
      } catch (error) {
        toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
      }
    },
    [iccids, message],
  );

  return (
    <AlternateModal modalOpen={open} closeModal={onClose} title={t('sendSms')} modalSize="large" showClose>
      <div className="tw-flex tw-flex-col tw-gap-6 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-p-6">
        <span className="tw-text-base">{recipientText}</span>
        <textarea
          value={message}
          onChange={handleMessageChange}
          className="tw-min-h-[161px] tw-w-full tw-rounded tw-border tw-border-sb-blue-grey-300 tw-p-4"
          placeholder={`${t('simcards.sendSms.enterYourMessage')}...`}
        />
        <div className="tw-flex tw-justify-end">
          <span
            className={`tw-text-sm ${byteCount > MAX_SMS_BYTES ? `!tw-text-sb-orange-600` : `tw-text-sb-blue-grey-600`}`}
          >{`${byteCount} / ${MAX_SMS_BYTES}`}</span>
        </div>
      </div>
      <div className="tw-flex tw-justify-end tw-gap-2.5 tw-bg-sb-blue-grey-100 tw-px-6 tw-py-3.5">
        <Button variant="outline" onClick={onClose} className="tw-capitalize">
          {t('cancel')}
        </Button>
        <Button
          variant="primaryDark"
          onClick={sendMessage}
          disabled={sendSmsResponse.isLoading || !message.trim() || byteCount > MAX_SMS_BYTES}
          className="tw-capitalize"
        >
          {t('send')}
        </Button>
      </div>
    </AlternateModal>
  );
});
