import { Button as HeadlessButton } from '@headlessui/react';
import React from 'react';

type ButtonVariant =
  | 'primaryDark'
  | 'primaryLight'
  | 'primaryLightV2'
  | 'secondary'
  | 'third'
  | 'fourth'
  | 'borderless'
  | 'outlineLight'
  | 'outline'
  | 'newUnderline';

type ButtonSize = 'small' | 'medium' | 'large';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  variant?: ButtonVariant;
  className?: string;
  'data-testid'?: string;
  asLink?: boolean;
  href?: string;
  size?: ButtonSize;
  newTab?: boolean;
};

const baseStyles =
  'tw-h-min tw-transition tw-duration-300 tw-inline-flex tw-items-center tw-justify-center tw-text-center ';

const variantStyles: Record<ButtonVariant, string> = {
  primaryDark:
    'tw-text-white tw-border-transparent tw-border-[1px] tw-bg-sb-blue-300 hover:tw-bg-sb-blue-400 active:tw-bg-sb-blue-500 disabled:tw-bg-sb-blue-grey-300',
  primaryLight:
    'tw-text-sb-blue-300 tw-border-transparent tw-bg-sb-blue-grey-100 hover:tw-bg-sb-blue-50 active:tw-bg-sb-blue-300 active:tw-text-white disabled:tw-text-sb-blue-grey-500 disabled:tw-bg-sb-blue-grey-300',
  primaryLightV2:
    'tw-bg-sb-blue-50 tw-text-sb-blue-300 tw-border-transparent hover:tw-bg-sb-blue-100 hover:tw-text-sb-blue-400 active:tw-text-white active:tw-bg-sb-blue-300 disabled:tw-text-sb-blue-grey-500 disabled:tw-bg-sb-blue-grey-300',
  secondary: 'tw-text-black tw-bg-gray-300 tw-hover:bg-gray-400 tw-disabled:bg-gray-200',
  third: 'tw-text-button-blue tw-bg-white tw-border-2 tw-border-button-blue tw-border-solid tw-disabled:bg-blue-300',
  outline:
    'tw-bg-transparent tw-text-sb-blue-300 tw-border-[1px] tw-border-solid tw-border-sb-blue-300 hover:tw-bg-sb-blue-grey-50 active:tw-text-sb-blue-400 active:tw-border-sb-blue-400 active:tw-bg-sb-blue-50 disabled:tw-text-sb-blue-grey-500 disabled:tw-border-sb-blue-grey-500 disabled:tw-bg-sb-blue-grey-50',
  outlineLight:
    'tw-text-sb-blue-grey-25 tw-border-[1px] tw-border-solid tw-border-sb-blue-grey-25 tw-bg-transparent hover:tw-bg-sb-blue-400 active:tw-bg-transparent active:tw-border-white active:tw-text-white disabled:tw-border-sb-blue-grey-500 disabled:tw-text-sb-blue-grey-500 disabled:tw-bg-transparent',
  fourth: 'tw-text-white tw-border-none tw-bg-red-500 tw-hover:bg-red-600 disabled:tw-bg-red-200',
  borderless: 'tw-bg-transparent tw-border-none',
  newUnderline:
    'tw-m-0 tw-appearance-none tw-gap-2.5 hover:!tw-bg-sb-blue-grey-200 tw-text-sb-blue-300 tw-rounded tw-border-none !tw-bg-transparent',
};

const buttonSize: Record<ButtonSize, string> = {
  small: 'tw-text-xs tw-py-[2px] tw-px-[11px] tw-rounded',
  medium: 'tw-text-sm tw-font-semibold tw-py-2.5 tw-px-5 tw-rounded-md',
  large: 'tw-text-xl tw-font-semibold tw-py-2.5 tw-px-16 tw-rounded-lg ',
};

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primaryDark',
  className,
  'data-testid': dataTestid,
  asLink = false,
  href,
  size = 'medium',
  newTab = false,
  ...rest
}) => {
  const buttonClassName = `${baseStyles} ${variantStyles[variant]} ${buttonSize[size]} tw-lift ${className || ''}`;

  if (asLink && href) {
    return (
      <a
        href={href}
        target={newTab ? '_blank' : undefined}
        rel={newTab ? 'noopener noreferrer' : undefined}
        className={buttonClassName}
        data-testid={dataTestid}
      >
        {children}
      </a>
    );
  }

  return (
    <HeadlessButton className={buttonClassName} data-testid={dataTestid} {...rest}>
      {children}
    </HeadlessButton>
  );
};
