import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type NoFilteredSimsTableBodyProps = {
  clearFilters: () => void;
};
export const NoFilteredSimsTableBody = ({ clearFilters }: NoFilteredSimsTableBodyProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="tw-flex tw-w-full tw-flex-grow tw-flex-col tw-items-center tw-justify-center tw-gap-5 tw-pb-56 tw-pt-36">
      <svg width="230" height="211" viewBox="0 0 230 211" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_257_4211)">
          <path
            d="M197.802 210.247H27.6758V71.1968C27.6758 66.6958 31.3337 63.047 35.8459 63.047H155.692V60.7091C155.692 56.7981 158.868 53.6299 162.789 53.6299H210.189C214.701 53.6299 218.359 57.2787 218.359 61.7797V189.741C218.359 201.07 209.159 210.247 197.802 210.247Z"
            fill="#F9FBFE"
            stroke="#DBE4F6"
            strokeWidth="1.37"
            strokeLinejoin="round"
          />
          <path
            d="M205.304 208.837C204.252 209.241 202.993 209.624 201.569 209.908C199.083 210.399 196.893 210.389 195.228 210.257H175.624V89.4082H188.985C192.742 89.4082 195.797 92.4453 195.797 96.2034V192.199C195.907 194.165 196.4 198.087 198.908 202.195C201.054 205.702 203.726 207.788 205.315 208.848L205.304 208.837Z"
            fill="#F1F5FC"
          />
          <path
            d="M197.802 210.247H27.6758V71.1968C27.6758 66.6958 31.3337 63.047 35.8459 63.047H155.692V60.7091C155.692 56.7981 158.868 53.6299 162.789 53.6299H210.189C214.701 53.6299 218.359 57.2787 218.359 61.7797V189.741C218.359 201.07 209.159 210.247 197.802 210.247Z"
            stroke="#DBE4F6"
            strokeWidth="1.37"
            strokeLinejoin="round"
          />
          <path
            d="M171.933 132.615C180.53 134.09 189.27 135.423 197.955 134.637C206.64 133.85 215.412 130.725 221.446 124.433C229.441 116.108 231.435 102.999 227.525 92.1503C223.615 81.3021 214.415 72.7808 203.748 68.3344C193.081 63.8881 181.1 63.2107 169.666 64.9587C155.823 67.0781 142.768 72.5514 129.484 76.9431C116.188 81.3239 102.06 84.6778 88.2609 82.329C81.5036 81.1819 74.8011 78.549 70.0151 73.6657C65.2291 68.7823 62.7102 61.3645 64.813 54.8643C66.0943 50.9314 69.1937 47.4464 73.2131 46.4413C77.2324 45.4362 82.0074 47.4027 83.5297 51.2482C85.4134 56.0004 81.9198 61.4082 77.4076 63.8334C68.3723 68.6949 56.0515 64.2923 50.8494 55.4651C45.6472 46.6379 47.0929 34.8611 52.9959 26.4818C58.899 18.1026 68.5804 12.9024 78.6233 10.7939"
            stroke="#DBE4F6"
            strokeWidth="0.98"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="1.96 4.96"
          />
          <path
            d="M196.98 210.246H8.30169C4.12903 210.246 0.755859 206.881 0.755859 202.719V85.9666C0.755859 81.8043 4.12903 78.4395 8.30169 78.4395H168.078C172.251 78.4395 175.624 81.8043 175.624 85.9666V188.932C175.624 200.709 185.196 210.246 196.991 210.246H196.98Z"
            fill="white"
            stroke="#DBE4F6"
            strokeWidth="1.37"
            strokeMiterlimit="10"
          />
          <path
            d="M107.81 179.625H57.7275C56.0081 179.625 54.6172 178.237 54.6172 176.522V117.168C54.6172 115.453 56.0081 114.065 57.7275 114.065H99.3884L110.921 125.569V176.511C110.921 178.226 109.53 179.614 107.81 179.614V179.625Z"
            fill="white"
          />
          <path
            d="M108.007 179.625H64.4405C62.8306 179.625 61.5273 178.325 61.5273 176.719V116.971C61.5273 115.365 62.8306 114.065 64.4405 114.065H100.111L110.909 124.837V176.708C110.909 178.314 109.606 179.614 107.996 179.614L108.007 179.625Z"
            fill="#F9FBFE"
          />
          <path
            d="M107.81 179.625H57.7275C56.0081 179.625 54.6172 178.237 54.6172 176.522V117.168C54.6172 115.453 56.0081 114.065 57.7275 114.065H99.3884L110.921 125.569V176.511C110.921 178.226 109.53 179.614 107.81 179.614V179.625Z"
            stroke="#DBE4F6"
            strokeWidth="1.37"
            strokeLinejoin="round"
          />
          <path
            d="M93.9778 160.375L70.7051 137.16"
            stroke="#FF9387"
            strokeWidth="3.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.7051 160.375L93.9778 137.16"
            stroke="#FF9387"
            strokeWidth="3.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M79.883 15.3387L78.0759 5.92162C77.9883 5.47371 78.284 5.04764 78.733 4.96024L89.3673 2.93917C89.8163 2.85177 90.2434 3.14674 90.3311 3.59465L91.9191 11.8756L90.353 14.1807L80.8577 15.9942C80.4087 16.0816 79.9815 15.7866 79.8939 15.3387H79.883Z"
            fill="white"
          />
          <path
            d="M79.872 15.3167L78.0759 5.9324C77.9883 5.47356 78.284 5.03657 78.744 4.94917L89.8601 2.82978C90.3201 2.74238 90.7582 3.03735 90.8458 3.49619L92.4228 11.7225L90.8239 14.0713L80.8686 15.9722C80.4087 16.0596 79.9706 15.7646 79.883 15.3058L79.872 15.3167Z"
            stroke="#DBE4F6"
            strokeWidth="0.59"
            strokeLinejoin="round"
          />
          <path
            d="M87.5164 8.81641C87.845 9.73408 89.0935 13.4594 87.9655 16.2561C87.7683 16.7587 87.3631 17.5562 86.4979 18.1133C85.8299 18.5503 84.9099 18.6705 84.2309 18.2663C84.0885 18.1789 83.5957 17.8839 83.2014 16.8133C82.4238 14.6611 83.2014 12.6182 83.2452 12.5308C84.3295 9.81056 87.1222 8.93658 87.5164 8.82733V8.81641Z"
            fill="#F1F5FC"
          />
          <path
            d="M87.5164 8.81641C87.845 9.73408 89.0935 13.4594 87.9655 16.2561C87.7683 16.7587 87.3631 17.5562 86.4979 18.1133C85.8299 18.5503 84.9099 18.6705 84.2309 18.2663C84.0885 18.1789 83.5957 17.8839 83.2014 16.8133C82.4238 14.6611 83.2014 12.6182 83.2452 12.5308C84.3295 9.81056 87.1222 8.93658 87.5164 8.82733V8.81641Z"
            stroke="#DBE4F6"
            strokeWidth="0.59"
            strokeLinejoin="round"
          />
          <path
            d="M87.5161 8.8049C87.5161 7.82168 87.4285 3.89971 85.4243 1.64921C85.0629 1.245 84.4167 0.633216 83.4201 0.392872C82.6425 0.207152 81.7335 0.392872 81.2297 1.01558C81.1202 1.14668 80.7588 1.58367 80.7588 2.73076C80.7588 5.01403 82.1825 6.67458 82.2482 6.75106C84.1867 8.94693 87.1109 8.82675 87.527 8.8049H87.5161Z"
            fill="#F1F5FC"
          />
          <path
            d="M87.5161 8.8049C87.5161 7.82168 87.4285 3.89971 85.4243 1.64921C85.0629 1.245 84.4167 0.633216 83.4201 0.392872C82.6425 0.207152 81.7335 0.392872 81.2297 1.01558C81.1202 1.14668 80.7588 1.58367 80.7588 2.73076C80.7588 5.01403 82.1825 6.67458 82.2482 6.75106C84.1867 8.94693 87.1109 8.82675 87.527 8.8049H87.5161Z"
            stroke="#DBE4F6"
            strokeWidth="0.59"
            strokeLinejoin="round"
          />
          <path
            d="M163.128 59.9004H210.615"
            stroke="#DBE4F6"
            strokeWidth="1.37"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_257_4211">
            <rect width="230" height="211" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className="tw-max-w-80">
        <div className="tw-text-center tw-text-2xl tw-font-semibold tw-text-sb-blue-grey-300">
          {t('simcard.noSimFoundForFilter')}
        </div>
      </div>
      <div className="tw-flex tw-gap-1">
        <div
          className="tw-cursor-pointer tw-whitespace-nowrap tw-text-base !tw-font-normal tw-text-sb-blue-grey-600 tw-underline tw-underline-offset-4"
          onClick={() => {
            clearFilters();
          }}
        >
          {t('simcard.filters.clearFilters')}
        </div>
        <div className="tw-text-base !tw-font-normal tw-text-sb-blue-grey-600">{t('or')}</div>
        <div
          className="tw-cursor-pointer tw-whitespace-nowrap tw-text-base !tw-font-normal tw-text-sb-blue-grey-600 tw-underline tw-underline-offset-4"
          onClick={() => {
            navigate('/dashboard/activate-sims');
          }}
        >
          {t('home.registerSims')}
        </div>
      </div>
    </div>
  );
};
