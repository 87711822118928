import { AlternateModal } from '@components/Atoms/AlternateModal/AlternateModal';
import { Button } from '@components/Atoms/Button/Button';
import { ColorPickerDropdown } from '@components/Atoms/ColorPickerDropdown/ColorPickerDropdown';
import { Icon } from '@components/Atoms/Icon/Icon';
import { TAG_COLORS } from '@core/constants/simcards_constants';
import { TransparentInput } from '@modules/dashboard/simCard';
import { useCreateTagMutation, useDeleteTagMutation } from '@modules/dashboard/simCard/simcards-api-slice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetURLSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MenuItemType } from 'src/types/types';
import { DeleteTagsModal } from '../DeleteTagsModal/DeleteTagsModal';

type ManageTagsModalProps = {
  open: boolean;
  onClose: () => void;
  tags: MenuItemType[];
  setSearchParams: SetURLSearchParams;
  searchParams: URLSearchParams;
};

export const ManageTagsModal = ({ open, onClose, tags, setSearchParams, searchParams }: ManageTagsModalProps) => {
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteTag] = useDeleteTagMutation();
  const [createTag] = useCreateTagMutation();

  const [value, setValue] = useState<string>('');
  const [showCreateTag, setShowCreateTag] = useState<boolean>(false);
  const [filteredTags, setFilteredTags] = useState<MenuItemType[]>(tags);
  const [updatedTags, setUpdatedTags] = useState<MenuItemType[]>(tags);

  const [newTagTitle, setNewTagTitle] = useState<string>(value);
  const [newTags, setNewTags] = useState<MenuItemType[]>([]);
  const [newTagColor, setNewTagColor] = useState('orange-300');

  const [editTagTitle, setEditTagTitle] = useState<string>('');
  const [editedTagColor, setEditedTagColor] = useState<string>('');
  const [editedTags, setEditedTags] = useState<MenuItemType[]>([]);

  const [tagAwaitingDeleteConfirmation, setTagAwaitingDeleteConfirmation] = useState<MenuItemType>(null);
  const [tagsToDelete, setTagsToDelete] = useState<MenuItemType[]>([]);

  const handleOpenDeleteTagModal = (tag: MenuItemType) => {
    setTagAwaitingDeleteConfirmation(tag);
    setDeleteModalOpen(true);
  };

  const handleDeleteTagConfirmation = () => {
    handleDeleteTag(tagAwaitingDeleteConfirmation);
    setDeleteModalOpen(false);
  };

  const [disableCreateConfirm, setDisableCreateConfirm] = useState<boolean>(false);
  const [tagTitleTooLong, setTagTitleTooLong] = useState<boolean>(false);

  useEffect(() => {
    setTagTitleTooLong(newTagTitle.length > 20);
  }, [newTagTitle]);

  useEffect(() => {
    const filtered = updatedTags.filter((tag) => tag.title.includes(value));
    setFilteredTags(filtered);
  }, [value, tags, updatedTags]);

  useEffect(() => {
    if (showCreateTag) {
      setNewTagTitle(value);
    }
  }, [showCreateTag]);

  const handleSelectedTag = (tag: string) => {
    setNewTagColor(tag);
  };

  const handleCreateTag = () => {
    const existsInOldTags = tags.find((tag) => tag.title === newTagTitle);
    const existsInNewTags = newTags.find((tag) => tag.title === newTagTitle);
    const existsInUpdatedTags = updatedTags.find((tag) => tag.title === newTagTitle);
    const existsInEditedTags = editedTags.find((tag) => tag.title === newTagTitle);
    const existsInTagsToDelete = tagsToDelete.find((tag) => tag.title === newTagTitle);

    // For updatedTags if the updatedTags doesn't have the exact matching tag add it, if it has it but with a different color replace it
    if (existsInUpdatedTags && existsInUpdatedTags.tagColor !== newTagColor) {
      setUpdatedTags((prev) =>
        prev.map((updatedTag) =>
          updatedTag.title === newTagTitle ? { title: updatedTag.title, tagColor: newTagColor } : updatedTag,
        ),
      );
    } else if (!existsInUpdatedTags) {
      setUpdatedTags((prev) => [...prev, { title: newTagTitle, tagColor: newTagColor }]);
    }

    // For the editedTags if there is an exact match for the tag in the original tags and there is an exact match in the edited tags , remove the tag from edited tags
    // If new tag title matches a tag in the original tags but the color is different then if that tag title does not exist in the edited tags add the new tag to editedTags
    // If that tag title does exist in edited tags but it's color is different replace it
    if (
      existsInOldTags &&
      existsInOldTags.tagColor === newTagColor &&
      existsInEditedTags &&
      existsInEditedTags.tagColor === newTagColor
    ) {
      setEditedTags((prev) => prev.filter((tag) => tag.title !== newTagTitle));
    } else if (existsInOldTags && existsInOldTags.tagColor !== newTagColor && !existsInEditedTags) {
      setEditedTags((prev) => [...prev, { title: newTagTitle, tagColor: newTagColor }]);
    } else if (
      existsInOldTags &&
      existsInOldTags.tagColor !== newTagColor &&
      existsInEditedTags &&
      existsInEditedTags.tagColor !== newTagColor
    ) {
      setEditedTags((prev) =>
        prev.map((tag) => (tag.title === newTagTitle ? { title: tag.title, tagColor: newTagColor } : tag)),
      );
    }

    //  For the newTags
    // If the title doesn't exist in the original tags
    // Then if the title doesn't exist in newTags add it
    // If the title does exist in new tags but the color is different then change the color
    if (!existsInOldTags) {
      if (!existsInNewTags) {
        setNewTags((prev) => [...prev, { title: newTagTitle, tagColor: newTagColor }]);
      } else if (existsInNewTags && existsInNewTags.tagColor !== newTagColor) {
        setNewTags((prev) =>
          prev.map((tag) => (tag.title === newTagTitle ? { title: tag.title, tagColor: newTagColor } : tag)),
        );
      }
    }

    // For the tagsToDelete
    // If the tag title matches a tag in the tagsToDelete remove that tag
    if (existsInTagsToDelete) {
      setTagsToDelete((prev) => prev.filter((tag) => tag.title !== newTagTitle));
    }

    setValue('');
    setNewTagColor('orange-300');
    setShowCreateTag(false);
  };

  const handleDeleteTag = (tag: MenuItemType) => {
    const params = new URLSearchParams(searchParams);

    const currentTags = params.get('tags') ? params.get('tags').split(',') : [];
    const newTagsParams = currentTags.filter((oldTag) => oldTag !== tag.title);
    if (newTagsParams.length > 0) {
      params.set('tags', newTagsParams.join(','));
    } else {
      params.delete('tags');
    }
    setSearchParams(params);

    const existingTag = tags.find((oldTag) => oldTag.title === tag.title);
    const existingCreatedTag = newTags.find((oldTag) => oldTag.title === tag.title);

    if (existingCreatedTag) {
      setNewTags((prev) => prev.filter((currentNewTag) => currentNewTag.title !== tag.title));
    }

    if (existingTag) {
      setTagsToDelete((prev) => [...prev, tag]);
    }

    setUpdatedTags((prev) => prev.filter((currentTag) => currentTag.title !== tag.title));
  };

  const handleEditTag = (tag: MenuItemType) => {
    setEditedTagColor(tag.tagColor);
    setEditTagTitle(tag.title);
  };

  useEffect(() => {
    const existingTag = updatedTags.find((tag) => tag.title === newTagTitle);
    const disable = !!existingTag || !newTagTitle || newTagTitle.length > 20;
    setDisableCreateConfirm(disable);
  }, [newTagTitle, updatedTags]);

  const handleEditConfirm = () => {
    const existingCreatedTag = newTags.find((newTag) => newTag.title === editTagTitle);
    const existingOldTag = tags.find((tag) => tag.title === editTagTitle);
    const existingEditedTag = editedTags.find((editedTag) => editedTag.title === editTagTitle);

    if (!existingEditedTag && existingOldTag && existingOldTag.tagColor !== editedTagColor) {
      setEditedTags((prev) => [...prev, { title: editTagTitle, tagColor: editedTagColor }]);
    } else if (existingEditedTag && existingOldTag && existingOldTag.tagColor === editedTagColor) {
      setEditedTags((prev) => prev.filter((tag) => tag.title !== editTagTitle));
    } else if (existingEditedTag && existingOldTag && existingOldTag.tagColor !== editedTagColor) {
      setEditedTags((prev) =>
        prev.map((editedTag) => {
          if (editedTag.title === editTagTitle) {
            return { title: editTagTitle, tagColor: editedTagColor };
          } else {
            return editedTag;
          }
        }),
      );
    }

    if (existingCreatedTag) {
      if (existingOldTag && existingOldTag.tagColor === editedTagColor) {
        setNewTags((prev) => prev.filter((tag) => tag.title !== editTagTitle));
      }
      if (existingCreatedTag.tagColor !== editedTagColor)
        setNewTags((prev) =>
          prev.map((currentNewTag) => {
            if (currentNewTag.title === editTagTitle) {
              return { title: editTagTitle, tagColor: editedTagColor };
            } else {
              return currentNewTag;
            }
          }),
        );
    }

    setUpdatedTags((prev) =>
      prev.map((tag) => {
        if (tag.title === editTagTitle) {
          return { title: editTagTitle, tagColor: editedTagColor };
        } else {
          return tag;
        }
      }),
    );

    setEditTagTitle('');
  };

  const saveButtonDisabled = useMemo(
    () => !editedTags.length && !newTags.length && !tagsToDelete.length,
    [editedTags, newTags, tagsToDelete],
  );

  const handleSave = useCallback(async () => {
    const saveOperation = async () => {
      if (editedTags.length > 0 || newTags.length > 0) {
        const tagsToCreate = [...editedTags, ...newTags];
        for (const tag of tagsToCreate) {
          await createTag({ tag: tag.title.trim(), color: tag.tagColor }).unwrap();
        }
      }

      if (tagsToDelete.length > 0) {
        for (const tag of tagsToDelete) {
          await deleteTag({ tag: tag.title }).unwrap();
        }
      }
    };

    try {
      await toast.promise(saveOperation(), {
        pending: t('simcard.tagsUpdating'),
        success: t('simcard.tagsUpdateSuccessful'),
      });
    } catch (error) {
      toast.error(error.data?.errors[0]?.msg || t('errorMessage.somethingWentWrongGetInTouch'));
    }

    setEditedTags([]);
    setNewTags([]);
    setTagsToDelete([]);

    onClose();
  }, [editedTags, newTags, tagsToDelete, createTag, deleteTag, t, onClose]);

  return (
    <AlternateModal
      modalOpen={open}
      closeModal={onClose}
      title={t('simcard.filters.manageTags')}
      modalSize="large"
      showClose
    >
      <div className="tw-flex tw-gap-6 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-p-6">
        <TransparentInput
          placeholder={t('simcard.searchTags')}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          icon="search"
          className="tw-h-11 !tw-rounded-lg"
        />
        <Button
          variant="primaryLightV2"
          onClick={() => setShowCreateTag(true)}
          className="tw-gap-2.5 tw-whitespace-nowrap"
        >
          <Icon name="plus" className="" />
          {t('simcard.addNewTag')}
        </Button>
      </div>
      {showCreateTag && (
        <div className="tw-flex tw-items-center tw-gap-2.5 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-6 tw-py-4">
          <div className="tw-flex tw-w-full tw-items-center tw-gap-1.5">
            <ColorPickerDropdown selectedTag={newTagColor} setSelectedTag={handleSelectedTag} />
            <TransparentInput
              placeholder={t('simcard.enterTagName')}
              value={newTagTitle}
              onChange={(e) => setNewTagTitle(e.target.value)}
              icon="search"
              className="tw-h-11 !tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300"
            />
          </div>
          <Button
            variant="outline"
            onClick={() => {
              setNewTagTitle('');
              setNewTagColor('orange-300');
              setShowCreateTag(false);
            }}
          >
            {t('Cancel')}
          </Button>
          <Button onClick={handleCreateTag} disabled={disableCreateConfirm}>
            {t('button.confirm')}
          </Button>
        </div>
      )}
      <div className="tw-h-[40vh] tw-overflow-auto tw-bg-white">
        {filteredTags.map((tag) => {
          return (
            <div
              className={`${editTagTitle !== tag.title && 'tw-p-1'} manage-tag-item tw-h-14 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300`}
            >
              <div
                className={`tw-group tw-flex tw-h-full tw-items-center tw-justify-between tw-px-6 ${editTagTitle === tag.title ? 'tw-bg-white' : 'tw-rounded-md hover:tw-bg-sb-blue-grey-50'}`}
              >
                <div className="tw-flex tw-items-center tw-gap-2.5">
                  {editTagTitle !== tag.title ? (
                    <div className="tw-flex tw-h-5 tw-w-5 tw-items-center">
                      <Icon name="tag" strokeColor={TAG_COLORS[tag.tagColor]?.hexCode} height={20} width={14} />
                    </div>
                  ) : (
                    <ColorPickerDropdown selectedTag={editedTagColor} setSelectedTag={setEditedTagColor} />
                  )}
                  <span className="tw-text-base">{tag.title}</span>
                </div>

                {editTagTitle !== tag.title ? (
                  <div className="tw-hidden tw-items-center tw-gap-6 group-hover:tw-flex">
                    <Button
                      variant="newUnderline"
                      onClick={() => handleEditTag(tag)}
                      size="small"
                      className="!tw-text-base"
                    >
                      <Icon name="edit" />
                      {t('simcard.editTag')}
                    </Button>
                    <Button
                      variant="newUnderline"
                      onClick={() => handleOpenDeleteTagModal(tag)}
                      size="small"
                      className="!tw-text-base"
                    >
                      <Icon name="delete_bin_alternate" />
                      {t('button.delete')}
                    </Button>
                  </div>
                ) : (
                  <div className="tw-flex tw-items-center tw-gap-2.5">
                    <Button variant="outline" onClick={() => setEditTagTitle('')}>
                      {t('button.cancel')}
                    </Button>
                    <Button onClick={handleEditConfirm}>{t('button.confirm')}</Button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`tw-flex ${tagTitleTooLong ? 'tw-justify-between' : 'tw-justify-end'} tw-items-center tw-gap-2.5 tw-bg-sb-blue-grey-100 tw-px-6 tw-py-3`}
      >
        {tagTitleTooLong && <span className="tw-text-sb-orange-400">{t('simcard.tagTooLong')}</span>}
        <div className="tw-flex tw-items-center tw-gap-2.5">
          <Button variant="outline" onClick={onClose}>
            {t('button.cancel')}
          </Button>
          <Button onClick={handleSave} disabled={saveButtonDisabled}>
            {t('Save')}
          </Button>
        </div>
      </div>
      {deleteModalOpen && (
        <DeleteTagsModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          handleDelete={handleDeleteTagConfirmation}
        />
      )}
    </AlternateModal>
  );
};
