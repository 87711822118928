import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';

type PaginationButtonsProps = {
  beginningOnClick: () => void;
  prevOnClick: () => void;
  prevDisabled: boolean;
  nextOnClick: () => void;
  nextDisabled: boolean;
  endOnClick: () => void;
};

const WRAPPER_CLASSNAME =
  'tw-flex tw-h-[30px] tw-w-[30px] tw-items-center tw-justify-center tw-rounded hover:tw-bg-sb-blue-grey-100 hover:tw-cursor-pointer tw-text-sb-blue-grey-600 active:tw-text-sb-blue-500 disabled:tw-text-sb-blue-grey-300 tw-border-0 tw-bg-transparent';

const BUTTON_CONFIGS = [
  {
    name: 'beginning_pagination',
    onClick: 'beginningOnClick',
    ariaLabel: 'pagination.goToFirstPage',
    disabled: 'prevDisabled',
  },
  {
    name: 'previous_pagination',
    onClick: 'prevOnClick',
    ariaLabel: 'pagination.goToPreviousPage',
    disabled: 'prevDisabled',
  },
  { name: 'next_pagination', onClick: 'nextOnClick', ariaLabel: 'pagination.goToNextPage', disabled: 'nextDisabled' },
  { name: 'end_pagination', onClick: 'endOnClick', ariaLabel: 'pagination.goToLastPage', disabled: 'nextDisabled' },
] as const;

export const PaginationButtons = React.memo(
  ({ beginningOnClick, prevOnClick, nextOnClick, endOnClick, prevDisabled, nextDisabled }: PaginationButtonsProps) => {
    const { t } = useTranslation();

    const onClickMap = {
      beginningOnClick,
      prevOnClick,
      nextOnClick,
      endOnClick,
    };

    const disabledMap = {
      prevDisabled,
      nextDisabled,
    };
    return (
      <div className="tw-flex tw-gap-2 tw-rounded-lg tw-bg-white tw-p-1.5">
        {BUTTON_CONFIGS.map((config) => (
          <button
            key={config.name}
            name={config.name}
            className={WRAPPER_CLASSNAME}
            onClick={onClickMap[config.onClick]}
            aria-label={t(config.ariaLabel)}
            disabled={disabledMap[config.disabled] ? disabledMap[config.disabled] : false}
          >
            <Icon name={config.name} strokeColor="currentColor" />
          </button>
        ))}
      </div>
    );
  },
);
