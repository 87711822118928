import { TAG_COLORS } from '@core/constants/simcards_constants';
import { SimcardsListViewItem } from '@modules/dashboard/simCard/simcards-slice';
import { Table } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { SetURLSearchParams } from 'react-router-dom';
import { CustomColumnDef, MappedUsageGraphData, MenuItemType, Usage } from 'src/types/types';

export type ParamType = 'network_ids' | 'coverage' | 'tags' | 'sim_state';

export const removeEmptyParams = (params: URLSearchParams): URLSearchParams => {
  const keysToDelete: string[] = [];

  for (const key of params.keys()) {
    if (!params.get(key)) {
      keysToDelete.push(key);
    }
  }

  for (const key of keysToDelete) {
    params.delete(key);
  }

  return params;
};

export const resetFilters = (
  setSearchParams: (setParams: (prev: URLSearchParams) => URLSearchParams) => void,
): void => {
  setSearchParams((prev) => {
    for (const key of prev.keys()) {
      prev.delete(key);
    }
    return prev;
  });
};

export function isChecked(
  paramType: ParamType,
  filterValue: string,
  network_ids: string[],
  coverage: string[],
  tags: string[],
  sim_state: string | null,
): boolean {
  const encodedFilterValue = encodeURIComponent(filterValue);

  const filterMap: { [key in ParamType]: string[] | string | null } = {
    network_ids,
    coverage,
    tags,
    sim_state,
  };

  const filter = filterMap[paramType];

  if (Array.isArray(filter)) {
    return filter.includes(encodedFilterValue);
  } else if (typeof filter === 'string') {
    return filter === encodedFilterValue;
  }

  return false;
}

export function getColumnFilters(
  coverage: string[],
  network_id: string | null,
  selectedTags: string[],
  sim_state: string | null,
): { id: string; value: any }[] {
  // Change value type to any to accommodate array values
  return [
    ...(coverage.length > 0
      ? [{ id: 'coverage', value: coverage.map((location) => decodeURIComponent(location)) }]
      : []),
    ...(network_id ? [{ id: 'network_id', value: decodeURIComponent(network_id) }] : []),
    ...(selectedTags.length > 0 ? [{ id: 'tags', value: selectedTags.map((tag) => decodeURIComponent(tag)) }] : []),
    ...(sim_state ? [{ id: 'sim_state', value: decodeURIComponent(sim_state) }] : []),
  ];
}

export const convertToMB = (usageData) => {
  const [value, unit] = usageData.split(' ');
  const numericValue = parseFloat(value);
  if (unit === 'GB') {
    return numericValue * 1024;
  }
  return numericValue;
};

export const usageDataSortingFn: any = (rowA, rowB, columnId) => {
  const usageA = convertToMB(rowA.original[columnId]);
  const usageB = convertToMB(rowB.original[columnId]);

  if (usageA < usageB) return -1;
  if (usageA > usageB) return 1;
  return 0;
};

export const updateColumnsWithCustomDataSorting = (
  columns: CustomColumnDef<SimcardsListViewItem>[],
): CustomColumnDef<SimcardsListViewItem>[] => {
  return columns.map((column) => {
    if (column.id === 'usage_data') {
      return {
        ...column,
        sortingFn: usageDataSortingFn,
        sortDescFirst: true,
      };
    }
    if (column.id === 'ip_address') {
      return {
        ...column,
        sortDescFirst: false,
      };
    }
    if (column.id === 'sim_state') {
      return {
        ...column,
        sortDescFirst: true,
      };
    }
    if (column.id === 'device_name') {
      return {
        ...column,
        sortDescFirst: true,
      };
    }
    if (column.id === 'sms') {
      return {
        ...column,
        sortDescFirst: true,
      };
    }
    return column;
  });
};

export const mapUsageGraphData = (data: Usage[]): MappedUsageGraphData => {
  const labels = [];
  const values = [];

  data.forEach(({ date, volume, month }) => {
    labels.push(date?.substring(5) || month);
    values.push(volume);
  });

  return {
    labels,
    datasets: [
      {
        data: values,
        borderWidth: 1,
        borderColor: '#2C7BE5',
        backgroundColor: '#2C7BE5',
        barPercentage: 0.2,
        borderRadius: 100,
        skipNull: true,
      },
    ],
  };
};

export function replaceWithDefaultJson(url) {
  const lastSlashIndex = url.lastIndexOf('/');
  const newUrl = url.substring(0, lastSlashIndex + 1) + 'default.json';
  return newUrl;
}

export const handleStateChange = (
  option: MenuItemType,
  setSearchParams: SetURLSearchParams,
  searchParams: URLSearchParams,
) => {
  const params = new URLSearchParams(searchParams);

  const currentSimState = params.get('sim_state');
  if (currentSimState?.toLowerCase() !== option.title.toLowerCase()) {
    params.set('sim_state', option.title.toLowerCase());
  } else {
    params.delete('sim_state');
  }
  setSearchParams(params);
};

export const handleCoverageChange = (
  option: MenuItemType,
  setSearchParams: SetURLSearchParams,
  searchParams: URLSearchParams,
) => {
  const params = new URLSearchParams(searchParams);

  const currentCoverage = params.get('coverage') ? params.get('coverage').split(',') : [];
  const coverageSet = new Set(currentCoverage);

  if (coverageSet.has(option.title)) {
    coverageSet.delete(option.title);
  } else {
    coverageSet.add(option.title);
  }

  const newCoverage = Array.from(coverageSet);
  if (newCoverage.length > 0) {
    params.set('coverage', newCoverage.join(','));
  } else {
    params.delete('coverage');
  }
  setSearchParams(params);
};

export const handleNetworkChange = (
  option: MenuItemType,
  setSearchParams: SetURLSearchParams,
  searchParams: URLSearchParams,
) => {
  const params = new URLSearchParams(searchParams);

  const currentNetworkId = params.get('network_ids');
  if (currentNetworkId !== option.id) {
    params.set('network_ids', option.id);
  } else {
    params.delete('network_ids');
  }
  setSearchParams(params);
};

export const handleTagChange = (
  option: MenuItemType,
  setSearchParams: SetURLSearchParams,
  searchParams: URLSearchParams,
) => {
  const params = new URLSearchParams(searchParams);

  const currentTags = params.get('tags') ? params.get('tags').split(',') : [];
  const tagsSet = new Set(currentTags);
  if (option.title === 'Devices with no tags') {
    if (tagsSet.has(option.title)) {
      tagsSet.delete(option.title);
    } else {
      tagsSet.clear();
      tagsSet.add(option.title);
    }
  } else {
    if (tagsSet.has('Devices with no tags')) {
      tagsSet.delete('Devices with no tags');
    }
    if (tagsSet.has(option.title)) {
      tagsSet.delete(option.title);
    } else {
      tagsSet.add(option.title);
    }
  }

  const newTags = Array.from(tagsSet);
  if (newTags.length > 0) {
    params.set('tags', newTags.join(','));
  } else {
    params.delete('tags');
  }
  setSearchParams(params);
};

export const getSimsCounter = <TData>(table: Table<TData>, t: TFunction<'translation', undefined>) => {
  if (table.getSelectedRowModel().rows.length > 0) {
    return t('simcard.simcardSelected', {
      counter: table.getSelectedRowModel().rows.length,
      total: table.getCoreRowModel().rows.length,
    });
  }

  if (table.getState().columnFilters.length > 0 || table.getState().globalFilter) {
    return t('simcard.filteredSimcards', { counter: table.getFilteredRowModel().flatRows.length });
  }

  return t('simcard.totalSimcards', { counter: table.getCoreRowModel().rows.length });
};

export const tagIsValidColor = (color) => Object.keys(TAG_COLORS).includes(color);
