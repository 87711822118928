import Loader from '@components/Loader';
// import { FilterDropdown } from '@components/Molecules/FilterDropdown/FilterDropdown';
import { hideTooltip, showTooltip } from '@utils/tableUtils';
import { useEffect, useMemo, useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { DeprecatedISimpleTableProps } from 'src/types/types';
import { PAGE_SIZE_OPTIONS } from '../../../core/constants';
// import { Select, TransparentInput } from '../../../modules/dashboard/simCard';
import { MemoizedTableBody } from './DeprecatedSimpleTableBody';
import { MemoizedTableHeader } from './DeprecatedSimpleTableHeader';

// import { MemoizedPagination } from './SimpleTablePagination';

interface ISimpleTablePropsExtended extends DeprecatedISimpleTableProps {
  onRowClick?: (row: any) => void;
}

const NewSimpleTable = ({
  columns,
  data,
  //   titleFontWeight = '500',
  //   hasExport = true,
  //   hasPerPage = true,
  hasActions = false,
  //   hasSearch = false,
  actionItems,
  noDataText = 'No data',
  processing = false,
  //   filters = [],
  executingActionRow = null,
  //   updateFilter,
  //   resetFilter,
  //   customActionButton,
  autoResetPage = true,
  autoResetSortBy = true,
  initialPageSize = PAGE_SIZE_OPTIONS[0].value,
  //   hasPagination = true,
  cellProps = {},
  onRowClick,
}: ISimpleTablePropsExtended) => {
  //   const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const tableOptions = useMemo(
    () => ({
      columns: memoizedColumns,
      data: memoizedData,
      autoResetPage,
      autoResetSortBy,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        hiddenColumns: columns.filter((column) => column.isVisible === false).map((column) => column.accessor),
      },
    }),
    [memoizedColumns, memoizedData, autoResetPage, autoResetSortBy, initialPageSize, columns],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state: { pageIndex, pageSize, globalFilter },
    // setGlobalFilter,
  } = useTable(tableOptions, useGlobalFilter, useSortBy, usePagination);

  const tableRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (tableRef.current && wrapperRef.current) {
      wrapperRef.current.style.height = `${tableRef.current.offsetHeight}px`;
    }
  }, [data, columns, processing]);

  const [tooltipVisibility, setTooltipVisibility] = useState({});

  //   const handleExportCallback = useCallback(() => handleExport(data), [data]);
  //   const memoizedSetGlobalFilter = useCallback((value: string) => setGlobalFilter(value), [setGlobalFilter]);
  //   const memoizedSetPageSize = useCallback((value: number) => setPageSize(value), [setPageSize]);

  //   const selectedPageSizeOption = useMemo(() => PAGE_SIZE_OPTIONS.find(({ value }) => value === pageSize), [pageSize]);

  //   const MemoizedFilterDropdown = useMemo(
  //     () =>
  //       filters.length > 0 ? (
  //         <FilterDropdown filters={filters} updateFilter={updateFilter} resetFilters={resetFilter} />
  //       ) : null,
  //     [filters, updateFilter, resetFilter],
  //   );

  if (processing) return <Loader />;

  return (
    <div className="tw-mb-0 tw-mt-0 tw-h-full tw-w-full">
      <div
        ref={wrapperRef}
        className="tw-relative tw-h-full tw-w-full tw-overflow-hidden tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300"
      >
        <div ref={tableRef} className="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-overflow-x-auto tw-bg-blue-300">
          <table className="tw-w-full tw-table-auto tw-bg-white tw-text-xs" {...getTableProps()}>
            <MemoizedTableHeader
              headerGroups={headerGroups}
              tooltipVisibility={tooltipVisibility}
              showTooltip={(columnId) => showTooltip(setTooltipVisibility, columnId)}
              hideTooltip={(columnId) => hideTooltip(setTooltipVisibility, columnId)}
            />
            {processing && (
              <tbody>
                <tr>
                  <td colSpan={100}>
                    <div className="tw-flex tw-justify-center">
                      <Loader />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!processing && data.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={100} className="tw-p-6">
                    <div className="tw-flex tw-justify-center">
                      <h3 className="tw-m-0 tw-py-2">{noDataText}</h3>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!processing && (
              <MemoizedTableBody
                getTableBodyProps={getTableBodyProps}
                page={page}
                prepareRow={prepareRow}
                cellProps={cellProps}
                hasActions={hasActions}
                actionItems={actionItems}
                executingActionRow={executingActionRow}
                onRowClick={onRowClick}
              />
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default NewSimpleTable;
