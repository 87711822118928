import { NavLink } from '@components/Atoms/NavLink/NavLink';
import { OpenCollapseButton } from '@components/Atoms/OpenCollapseButton/OpenCollapseButton';
import { SidebarLogo } from '@components/Atoms/SidebarLogo/SidebarLogo';
import { SideBarStatusBar } from '@components/Molecules/SideBarStatusBar/SideBarStatusBar';
import { SideBarUserBar } from '@components/Molecules/SideBarUserBar/SideBarUserBar';
import { MENU } from '@core/constants/sidebar';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { checkPermission } from 'src/lib/utils/permissions';
import { StatusSummaryStatus } from 'src/types/types';

const TooltipWrapper: React.FC<Omit<TooltipProps, 'children'> & { children: React.ReactElement }> = ({
  title,
  children,
  ...rest
}) => (
  <Tooltip
    title={title}
    placement="right"
    arrow
    slotProps={{
      tooltip: {
        className:
          '!tw-bg-sb-blue-grey-600 !tw-rounded-md tw-text-white !tw-px-[10px] !tw-text-[12px] tw-h-7 tw-inline-flex tw-justify-center tw-items-center',
      },
      arrow: { className: '!tw-text-sb-blue-grey-600' },
    }}
    {...rest}
  >
    {children}
  </Tooltip>
);

const DEFAULT_STATUS: StatusSummaryStatus = {
  indicator: 'none',
  description: 'All Systems Operational',
};

type SideBarProps = {
  user: string;
  status: StatusSummaryStatus;
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  url: string;
  usernameIsLoading: boolean;
};

export const Sidebar = ({
  user,
  status = DEFAULT_STATUS,
  sidebarOpen,
  setSidebarOpen,
  url,
  usernameIsLoading,
}: SideBarProps) => {
  const manuallyToggled = useRef(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (!manuallyToggled.current) {
        setSidebarOpen(window.innerWidth > 1073);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = (open: boolean) => {
    setSidebarOpen(open);
    manuallyToggled.current = true;
  };

  const getActiveUrl = (pathname: string) => {
    const parts = pathname.split('/');
    return parts.length > 2 ? parts[2] : '';
  };

  const filteredMenu = useMemo(() => MENU.filter(({ permission }) => !permission || checkPermission(permission)), []);

  return (
    <div
      className={`tw-fixed tw-left-0 tw-top-0 tw-h-screen ${
        sidebarOpen ? 'tw-w-60' : 'tw-w-[63px]'
      } tw-z-40 tw-transition-all tw-duration-100 tw-ease-in-out`}
      data-testid="sidebar"
    >
      <div className="tw-flex tw-h-full tw-flex-col tw-justify-between tw-border-x tw-border-y-0 tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white">
        <div className="tw-flex tw-flex-col tw-gap-2 tw-px-[6px]">
          <div className="tw-my-[5px] tw-flex tw-h-[42px] tw-flex-col tw-items-center tw-justify-end tw-px-2.5">
            <SidebarLogo open={sidebarOpen} />
          </div>
          <ul className="tw-flex tw-flex-col tw-gap-2 tw-p-0">
            {filteredMenu.map((item) => {
              return sidebarOpen ? (
                <li className="nav-item tw-list-none" key={item.name} data-testid={item.icon}>
                  <NavLink
                    sidebarOpen={sidebarOpen}
                    icon={item.icon}
                    title={item.title}
                    url={item.url}
                    state={{ active: item.url === getActiveUrl(location.pathname) }}
                  />
                </li>
              ) : (
                <TooltipWrapper title={item.title} key={item.name}>
                  <li className="nav-item tw-list-none" data-testid={item.icon}>
                    <NavLink
                      sidebarOpen={sidebarOpen}
                      icon={item.icon}
                      url={item.url}
                      state={{ active: item.url === getActiveUrl(location.pathname) }}
                    />
                  </li>
                </TooltipWrapper>
              );
            })}
          </ul>
        </div>
        <div className="tw-relative tw-h-10">
          <OpenCollapseButton open={sidebarOpen} setOpen={toggleSidebar} className="tw-absolute tw-right-[-1px]" />
        </div>
        <div className="tw-h-[97px] tw-w-full">
          <SideBarUserBar user={user} sidebarOpen={sidebarOpen} usernameIsLoading={usernameIsLoading} />
          <SideBarStatusBar status={status} sidebarOpen={sidebarOpen} url={url} />
        </div>
      </div>
    </div>
  );
};
