import { HomeIconName, HomeIcons } from '@assets/img/icons/HomeIcons';
import { IconName, icons } from '@assets/img/icons/icons';
import React from 'react';

type IconProps = {
  name: IconName | HomeIconName;
  width?: number;
  height?: number;
  className?: string;
  strokeColor?: string;
};

export const Icon: React.FC<IconProps> = ({ name, width, height, className = '', strokeColor }) => {
  const IconComponent = icons[name] || HomeIcons[name];
  return <IconComponent width={width} height={height} className={className} stroke={strokeColor} />;
};
