import { HTMLProps, useEffect, useRef } from 'react';

interface IndeterminateCheckboxProps extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
}

export const IndeterminateCheckbox: React.FC<IndeterminateCheckboxProps> = ({
  indeterminate = false,
  className = '',
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate && !rest.checked;
    }
  }, [indeterminate, rest.checked]);

  return (
    <div className="tw-form-check tw-flex tw-items-center tw-justify-center">
      <input
        type="checkbox"
        ref={ref}
        onClick={(e) => e.stopPropagation()}
        className={`${className} tw-form-check-input tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 checked:tw-border-sb-blue-500 checked:tw-bg-sb-blue-grey-600 indeterminate:tw-border-sb-blue-500 indeterminate:tw-bg-sb-blue-grey-600 hover:checked:tw-border-sb-blue-500 hover:checked:tw-bg-sb-blue-grey-600 hover:indeterminate:tw-border-sb-blue-500 hover:indeterminate:tw-bg-sb-blue-grey-600 focus:tw-ring-0 focus:tw-ring-offset-0 focus:checked:tw-border-sb-blue-500 focus:checked:tw-bg-sb-blue-grey-600 focus:indeterminate:tw-border-sb-blue-500 focus:indeterminate:tw-bg-sb-blue-grey-600`}
        {...rest}
      />
    </div>
  );
};
