type OverviewHeaderProps = {
  title: string;
  sidebarOpen: boolean;
  headerClasses?: string;
  wrapperClasses?: string;
};
const OverviewHeader = ({ title, sidebarOpen, headerClasses, wrapperClasses }: OverviewHeaderProps) => {
  const wrapperClassnames = `mb-3 tw-mt-28 ${sidebarOpen ? `sm:tw-mt-[68px]` : `headerNavigation:tw-mt-[68px]`} ${wrapperClasses}`;
  const headerClassnames = `tw-mb-[54px] tw-py-[10px] tw-text-[32px] tw-font-bold ${headerClasses}`;

  return (
    <div className={wrapperClassnames}>
      <h1 className={headerClassnames}>{title}</h1>
    </div>
  );
};

export default OverviewHeader;
