import { SelectBox } from '@components/Atoms/SelectBox';
import { LoadingWrapper } from '@components/LoadingWrapper';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Vas, VasOverviewItem } from 'src/types/types';
import { ErrorMessage } from '../../../../components';
import DeprecatedSimpleTable from '../../../../components/Organisms/SimpleTable/DeprecatedSimpleTable';
import SubscriptionDetails from './SubscriptionDetails';

const columns = [
  {
    Header: 'ID',
    accessor: 'vas_id',
  },
  {
    Header: 'STATE',
    accessor: 'state',
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'product',
  },
  {
    Header: 'CREATED',
    accessor: 'created',
  },
  {
    Header: 'BILLABLE',
    accessor: 'billable',
  },
  {
    Header: 'DAILY FEE',
    accessor: 'daily_fee',
  },
];

const overviewColumns = [
  {
    Header: 'ID',
    accessor: 'vas_id',
    Cell: ({ value, setSelectedId }) => (
      <span
        className="cursor-pointer btn-link"
        onClick={() => {
          setSelectedId(value);
        }}
      >
        {value}
      </span>
    ),
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'product',
  },
  {
    Header: 'DAYS BILLED',
    accessor: 'billed_days',
  },
  {
    Header: 'TOTAL COSTS',
    accessor: 'cost',
  },
];

type SubscriptionsParams = {
  months: string[];
  vas: Vas;
  vasIsSuccess: boolean;
  selectedMonth: string;
  setSelectedMonth: Dispatch<SetStateAction<string>>;
  vasOverviewItems: VasOverviewItem[];
  vasOverviewIsFetching: boolean;
  currentYearMonth: string;
};

const Subscriptions = ({
  months,
  vas,
  vasIsSuccess,
  selectedMonth,
  setSelectedMonth,
  vasOverviewItems,
  vasOverviewIsFetching,
  currentYearMonth,
}: SubscriptionsParams) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedId, setSelectedId] = useState<string | null>(null);

  if (selectedId) {
    return <SubscriptionDetails month={selectedMonth} id={selectedId} handleGoBack={() => setSelectedId(null)} />;
  }

  return (
    <div>
      <div className="d-flex">
        <LoadingWrapper isLoading={!vasIsSuccess}>
          <DeprecatedSimpleTable
            title="Subscription Overview"
            data={vas}
            columns={columns}
            hasPerPage={false}
            hasExport={false}
            hasPagination={false}
          />
        </LoadingWrapper>
      </div>
      <div className="mt-4">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h4 className="card-header-title text-capitalize">Subscription Report</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <small className="form-text text-muted">
              Costs listed here are costs for additional service subscriptions and may exclude taxes and certain service
              costs.
            </small>
            {months && (
              <SelectBox
                defaultValue={{
                  label: 'This month',
                  value: currentYearMonth,
                }}
                onChange={({ value }) => setSelectedMonth(value)}
                options={months.map((value) => ({
                  label: value === currentYearMonth ? 'This month' : dayjs(value).format('MMMM YYYY'),
                  value,
                }))}
              />
            )}
          </Card.Body>
        </Card>
        <div>
          <LoadingWrapper isLoading={!vasOverviewItems}>
            <DeprecatedSimpleTable
              columns={overviewColumns}
              data={vasOverviewItems}
              processing={vasOverviewIsFetching}
              cellProps={{ setSelectedId }}
              hasPagination={false}
              hasPerPage={false}
            />
          </LoadingWrapper>
        </div>
      </div>
      <ErrorMessage message={errorMessage} onClose={() => setErrorMessage('')} />
    </div>
  );
};

export default Subscriptions;
