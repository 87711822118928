import { AlternateModal } from '@components/Atoms/AlternateModal/AlternateModal';
import { Button } from '@components/Atoms/Button/Button';
import { ColorPickerDropdown } from '@components/Atoms/ColorPickerDropdown/ColorPickerDropdown';
import { Icon } from '@components/Atoms/Icon/Icon';
import { IndeterminateCheckbox } from '@components/Atoms/IndeterminateCheckbox/IndeterminateCheckbox';
import { TAG_COLORS } from '@core/constants/simcards_constants';
import { TransparentInput } from '@modules/dashboard/simCard';
import { useCreateTagMutation, useDeleteTagMutation } from '@modules/dashboard/simCard/simcards-api-slice';
import { SimcardsListViewItem } from '@modules/dashboard/simCard/simcards-slice';
import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetURLSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTags } from 'src/hooks/useTags';
import { MenuItemType, Simcard } from 'src/types/types';
import { DeleteTagsModal } from '../DeleteTagsModal/DeleteTagsModal';

const DEFAULT_NEW_TAG = {
  title: '',
  tagColor: 'orange-300',
  applied: false,
  indeterminate: false,
  initialApplied: false,
  initialIndeterminate: false,
};
type Tag = MenuItemType & {
  applied: boolean;
  indeterminate: boolean;
  initialApplied: boolean;
  initialIndeterminate: boolean;
};

interface AssignTagsModalProps {
  open: boolean;
  onClose: () => void;
  iccids: string[];
  tags: MenuItemType[];
  simcards: Simcard[] | SimcardsListViewItem[];
  setSearchParams: SetURLSearchParams;
  searchParams: URLSearchParams;
}

const createTagCounts = (simcards: Simcard[] | SimcardsListViewItem[]) => {
  const tagCounts = {};
  simcards.forEach((simcard) => {
    if (simcard?.tags) {
      simcard.tags.forEach((tag) => {
        tagCounts[tag] = (tagCounts[tag] || 0) + 1;
      });
    }
  });
  return tagCounts;
};

const filterAndMapTags = (
  tags: Tag[] | MenuItemType[],
  simcards: Simcard[] | SimcardsListViewItem[],
  t: TFunction<'translation', undefined>,
) => {
  const tagCounts = createTagCounts(simcards);
  const totalSimcards = simcards.length;
  return tags
    .filter((tag) => tag.title !== t('simcard.filters.devicesWithNoTags'))
    .map((tag) => {
      const count = tagCounts[tag.title] || 0;
      const applied = count === totalSimcards;
      const indeterminate = count > 0 && count < totalSimcards;
      return {
        ...tag,
        applied,
        indeterminate,
        initialApplied: applied,
        initialIndeterminate: indeterminate,
      };
    });
};

export const AssignTagsModal: React.FC<AssignTagsModalProps> = React.memo(
  ({ open, onClose, iccids, tags, simcards, searchParams, setSearchParams }) => {
    const { t } = useTranslation();
    const { addTagToSims, removeTagToSims } = useTags();

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const [deleteTag] = useDeleteTagMutation();
    const [createTag] = useCreateTagMutation();

    const [searchValue, setSearchValue] = useState<string>('');
    const [showCreateTag, setShowCreateTag] = useState<boolean>(false);

    const initialTags = useMemo(() => filterAndMapTags(tags, simcards, t), [tags, simcards, t]);

    const [updatedTags, setUpdatedTags] = useState<Tag[]>(initialTags);
    const [filteredTags, setFilteredTags] = useState<Tag[]>(initialTags);

    const [tagBeingCreated, setTagBeingCreated] = useState<Tag>(DEFAULT_NEW_TAG);
    const [newTags, setNewTags] = useState<Tag[]>([]);

    const [tagBeingEdited, setTagBeingEdited] = useState<Tag>(DEFAULT_NEW_TAG);
    const [editedTags, setEditedTags] = useState<Tag[]>([]);

    const [tagsToDelete, setTagsToDelete] = useState<Tag[]>([]);

    const [disableCreateConfirm, setDisableCreateConfirm] = useState<boolean>(false);
    const [tagTitleTooLong, setTagTitleTooLong] = useState<boolean>(false);

    const [iccidList, setIccidList] = useState([]);
    const [batchedIccids, setBatchedIccids] = useState([]);

    useEffect(() => {
      setProcessing(true);

      setTimeout(() => {
        const list = simcards.map((simcard) => simcard.iccid);
        setIccidList(list);
        setProcessing(false);
      }, 0);
    }, [simcards]);

    useEffect(() => {
      setProcessing(true);

      setTimeout(() => {
        const batches = batchArray(iccidList, 300);
        setBatchedIccids(batches);
        setProcessing(false);
      }, 0);
    }, [iccidList]);

    const [processing, setProcessing] = useState(false);
    const [tagsToAdd, setTagsToAdd] = useState<string[]>([]);
    const [tagsToRemove, setTagsToRemove] = useState<string[]>([]);
    const [tagAwaitingDeleteConfirmation, setTagAwaitingDeleteConfirmation] = useState<Tag>(null);

    useEffect(() => {
      const existingTag = updatedTags.find((tag) => tag.title === tagBeingCreated.title);
      const disable = !!existingTag || !tagBeingCreated.title || tagBeingCreated.title.length > 20;
      setDisableCreateConfirm(disable);
    }, [tagBeingCreated.title, updatedTags]);

    useEffect(() => {
      setTagTitleTooLong(tagBeingCreated.title.length > 20);
    }, [tagBeingCreated.title]);

    const handleCreateTag = () => {
      const existsInOldTags = tags.find((tag) => tag.title === tagBeingCreated.title);
      const existsInNewTags = newTags.find((tag) => tag.title === tagBeingCreated.title);
      const existsInFilteredTags = updatedTags.find((tag) => tag.title === tagBeingCreated.title);
      const existsInEditedTags = editedTags.find((tag) => tag.title === tagBeingCreated.title);
      const existsInTagsToDelete = tagsToDelete.find((tag) => tag.title === tagBeingCreated.title);

      // For updatedTags if the updatedTags doesn't have the exact matching tag add it, if it has it but with a different color replace it
      if (existsInFilteredTags && existsInFilteredTags.tagColor !== tagBeingCreated.tagColor) {
        setUpdatedTags((prev) =>
          prev.map((filteredTag) =>
            filteredTag.title === tagBeingCreated.title
              ? { ...filteredTag, title: filteredTag.title, tagColor: tagBeingCreated.tagColor }
              : filteredTag,
          ),
        );
      } else if (!existsInFilteredTags) {
        setUpdatedTags((prev) => [
          ...prev,
          {
            title: tagBeingCreated.title,
            tagColor: tagBeingCreated.tagColor,
            applied: false,
            indeterminate: false,
            initialApplied: false,
            initialIndeterminate: false,
          },
        ]);
      }

      // For the editedTags if there is an exact match for the tag in the original tags and there is an exact match in the edited tags , remove the tag from edited tags
      // If new tag title matches a tag in the original tags but the color is different then if that tag title does not exist in the edited tags add the new tag to editedTags
      // If that tag title does exist in edited tags but it's color is different replace it
      if (
        existsInOldTags &&
        existsInOldTags.tagColor === tagBeingCreated.tagColor &&
        existsInEditedTags &&
        existsInEditedTags.tagColor === tagBeingCreated.tagColor
      ) {
        setEditedTags((prev) => prev.filter((tag) => tag.title !== tagBeingCreated.title));
      } else if (existsInOldTags && existsInOldTags.tagColor !== tagBeingCreated.tagColor && !existsInEditedTags) {
        setEditedTags((prev) => [
          ...prev,
          { ...tagBeingCreated, title: tagBeingCreated.title, tagColor: tagBeingCreated.tagColor },
        ]);
      } else if (
        existsInOldTags &&
        existsInOldTags.tagColor !== tagBeingCreated.tagColor &&
        existsInEditedTags &&
        existsInEditedTags.tagColor !== tagBeingCreated.tagColor
      ) {
        setEditedTags((prev) =>
          prev.map((tag) =>
            tag.title === tagBeingCreated.title
              ? { ...tag, title: tag.title, tagColor: tagBeingCreated.tagColor }
              : tag,
          ),
        );
      }

      //  For the newTags
      // If the title doesn't exist in the original tags
      // Then if the title doesn't exist in newTags add it
      // If the title does exist in new tags but the color is different then change the color
      if (!existsInOldTags) {
        if (!existsInNewTags) {
          setNewTags((prev) => [
            ...prev,
            { ...tagBeingCreated, title: tagBeingCreated.title, tagColor: tagBeingCreated.tagColor },
          ]);
        } else if (existsInNewTags && existsInNewTags.tagColor !== tagBeingCreated.tagColor) {
          setNewTags((prev) =>
            prev.map((tag) =>
              tag.title === tagBeingCreated.title
                ? { ...tag, title: tag.title, tagColor: tagBeingCreated.tagColor }
                : tag,
            ),
          );
        }
      }

      // For the tagsToDelete
      // If the tag title matches a tag in the tagsToDelete remove that tag
      if (existsInTagsToDelete) {
        setTagsToDelete((prev) => prev.filter((tag) => tag.title !== tagBeingCreated.title));
      }

      setSearchValue('');
      setTagBeingCreated(DEFAULT_NEW_TAG);
      setShowCreateTag(false);
    };

    const handleEditConfirm = () => {
      const existingCreatedTag = newTags.find((newTag) => newTag.title === tagBeingEdited.title);
      const existingOldTag = tags.find((tag) => tag.title === tagBeingEdited.title);
      const existingEditedTag = editedTags.find((editedTag) => editedTag.title === tagBeingEdited.title);

      if (!existingEditedTag && existingOldTag && existingOldTag.tagColor !== tagBeingEdited.tagColor) {
        setEditedTags((prev) => [
          ...prev,
          { ...tagBeingEdited, title: tagBeingEdited.title, tagColor: tagBeingEdited.tagColor },
        ]);
      } else if (existingEditedTag && existingOldTag && existingOldTag.tagColor === tagBeingEdited.tagColor) {
        setEditedTags((prev) => prev.filter((tag) => tag.title !== tagBeingEdited.title));
      } else if (existingEditedTag && existingOldTag && existingOldTag.tagColor !== tagBeingEdited.tagColor) {
        setEditedTags((prev) =>
          prev.map((editedTag) => {
            if (editedTag.title === tagBeingEdited.title) {
              return { ...tagBeingEdited, title: tagBeingEdited.title, tagColor: tagBeingEdited.tagColor };
            } else {
              return editedTag;
            }
          }),
        );
      }

      if (existingCreatedTag) {
        if (existingOldTag && existingOldTag.tagColor === tagBeingEdited.tagColor) {
          setNewTags((prev) => prev.filter((tag) => tag.title !== tagBeingEdited.title));
        }
        if (existingCreatedTag.tagColor !== tagBeingEdited.tagColor)
          setNewTags((prev) =>
            prev.map((currentNewTag) => {
              if (currentNewTag.title === tagBeingEdited.title) {
                return { ...tagBeingEdited, title: tagBeingEdited.title, tagColor: tagBeingEdited.tagColor };
              } else {
                return currentNewTag;
              }
            }),
          );
      }

      setUpdatedTags((prev) =>
        prev.map((tag) => {
          if (tag.title === tagBeingEdited.title) {
            return { ...tagBeingEdited, title: tagBeingEdited.title, tagColor: tagBeingEdited.tagColor };
          } else {
            return tag;
          }
        }),
      );

      setTagBeingEdited(DEFAULT_NEW_TAG);
    };

    const handleOpenDeleteTagModal = (tag: Tag) => {
      setTagAwaitingDeleteConfirmation(tag);
      setDeleteModalOpen(true);
    };

    const handleDeleteTagConfirmation = () => {
      handleDeleteTag(tagAwaitingDeleteConfirmation);
      setDeleteModalOpen(false);
    };

    const handleDeleteTag = (tag: Tag) => {
      const params = new URLSearchParams(searchParams);

      const currentTags = params.get('tags') ? params.get('tags').split(',') : [];
      const newTagsParams = currentTags.filter((oldTag) => oldTag !== tag.title);
      if (newTagsParams.length > 0) {
        params.set('tags', newTagsParams.join(','));
      } else {
        params.delete('tags');
      }
      setSearchParams(params);

      const existingTag = tags.find((oldTag) => oldTag.title === tag.title);
      const existingCreatedTag = newTags.find((oldTag) => oldTag.title === tag.title);

      if (existingCreatedTag) {
        setNewTags((prev) => prev.filter((currentNewTag) => currentNewTag.title !== tag.title));
      }

      if (existingTag) {
        setTagsToDelete((prev) => [...prev, tag]);
      }

      setUpdatedTags((prev) => prev.filter((currentTag) => currentTag.title !== tag.title));
    };

    const handleEditTag = (tag: Tag) => {
      setTagBeingEdited(tag);
    };

    useEffect(() => {
      const filtered = updatedTags.filter((tag) => tag.title.includes(searchValue));
      setFilteredTags(filtered);
    }, [searchValue, tags, updatedTags]);

    useEffect(() => {
      const tagsToAddSet = new Set<string>();
      const tagsToRemoveSet = new Set<string>();

      updatedTags.forEach((tag) => {
        const { applied, initialApplied } = tag;

        if (applied && !initialApplied) {
          tagsToAddSet.add(tag.title);
        } else if (!applied && initialApplied) {
          tagsToRemoveSet.add(tag.title);
        }
      });

      setTagsToAdd(Array.from(tagsToAddSet));
      setTagsToRemove(Array.from(tagsToRemoveSet));
    }, [updatedTags]);

    const handleTagToggle = useCallback((tagTitle: string) => {
      setUpdatedTags((prevTags) =>
        prevTags.map((tag) => {
          if (tag.title === tagTitle) {
            const newApplied = !tag.applied;
            return {
              ...tag,
              applied: newApplied,
              indeterminate: false,
            };
          } else {
            return tag;
          }
        }),
      );
    }, []);

    const batchArray = (array, batchSize) => {
      const batches = [];
      for (let i = 0; i < array.length; i += batchSize) {
        batches.push(array.slice(i, i + batchSize));
      }
      return batches;
    };

    const processChunks = async (toastId) => {
      const allBatchPromises = [];

      for (const batch of batchedIccids) {
        if (tagsToAdd.length > 0) {
          allBatchPromises.push(addTagToSims({ iccids: batch, tags: tagsToAdd }));
        }

        if (tagsToRemove.length > 0) {
          allBatchPromises.push(removeTagToSims({ iccids: batch, tags: tagsToRemove }));
        }

        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      await Promise.all(allBatchPromises);

      toast.update(toastId, {
        render: t('simcards.assignTags.updateSuccessful', {
          simOrSims: iccids.length > 1 ? 'SIMs' : 'SIM',
        }),
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
      onClose();
      setProcessing(false);
      // Clear state variables
      setEditedTags([]);
      setNewTags([]);
      setTagsToDelete([]);
      setTagsToAdd([]);
      setTagsToRemove([]);
    };

    const handleSave = useCallback(async () => {
      setProcessing(true);

      const toastId = toast.loading(
        t('simcards.assignTags.updatingTags', {
          simOrSims: iccids.length > 1 ? 'SIMs' : 'SIM',
        }),
      );

      try {
        // Step 1: Create new tags and delete tags
        if (editedTags.length > 0 || newTags.length > 0 || tagsToDelete.length > 0) {
          // Create or update tags
          if (editedTags.length > 0 || newTags.length > 0) {
            const tagsToCreate = [...editedTags, ...newTags];
            const createTagPromises = tagsToCreate.map((tag) =>
              createTag({ tag: tag.title.trim(), color: tag.tagColor }),
            );
            await Promise.all(createTagPromises);
          }

          // Delete tags
          if (tagsToDelete.length > 0) {
            const deleteTagPromises = tagsToDelete.map((tag) => deleteTag({ tag: tag.title }));
            await Promise.all(deleteTagPromises);
          }
        }

        if (tagsToAdd.length > 0 || tagsToRemove.length > 0) {
          await processChunks(toastId);
        } else {
          toast.update(toastId, {
            render: t('simcards.assignTags.updateSuccessful', {
              simOrSims: iccids.length > 1 ? 'SIMs' : 'SIM',
            }),
            type: 'success',
            isLoading: false,
            autoClose: 5000,
          });
          onClose();
          setProcessing(false);
        }
      } catch (error) {
        toast.update(toastId, {
          render: error?.data?.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'),
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
        setProcessing(false);
      }
    }, [
      editedTags,
      newTags,
      tagsToDelete,
      tagsToAdd,
      tagsToRemove,
      createTag,
      deleteTag,
      addTagToSims,
      removeTagToSims,
      batchedIccids,
      t,
      onClose,
      iccids.length,
    ]);
    const disableSaveButton = useMemo(() => {
      return (
        (editedTags.length === 0 &&
          newTags.length === 0 &&
          tagsToDelete.length === 0 &&
          tagsToAdd.length === 0 &&
          tagsToRemove.length === 0) ||
        processing
      );
    }, [editedTags.length, newTags.length, tagsToDelete.length, tagsToAdd.length, tagsToRemove.length, processing]);

    return (
      <AlternateModal
        modalOpen={open}
        closeModal={onClose}
        title={t('simcards.assignTags')}
        modalSize="large"
        showClose
      >
        <div className="tw-flex tw-gap-6 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-p-6">
          <TransparentInput
            placeholder={t('simcard.searchTags')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            icon="search"
            className="tw-h-11 !tw-rounded-lg"
          />
          <Button
            variant="primaryLightV2"
            onClick={() => setShowCreateTag(true)}
            className="tw-gap-2.5 tw-whitespace-nowrap"
          >
            <Icon name="plus" className="" />
            {t('simcard.addNewTag')}
          </Button>
        </div>
        {showCreateTag && (
          <div className="tw-flex tw-items-center tw-gap-2.5 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-6 tw-py-4">
            <div className="tw-flex tw-w-full tw-items-center tw-gap-1.5">
              <ColorPickerDropdown
                selectedTag={tagBeingCreated.tagColor}
                setSelectedTag={(color) => setTagBeingCreated((prev) => ({ ...prev, tagColor: color }))}
              />
              <TransparentInput
                placeholder={t('simcard.enterTagName')}
                value={tagBeingCreated.title}
                onChange={(e) => setTagBeingCreated((prev) => ({ ...prev, title: e.target.value }))}
                icon="search"
                className="tw-h-11 !tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300"
              />
            </div>
            <Button
              variant="outline"
              onClick={() => {
                setTagBeingCreated((prev) => ({ ...prev, title: '' }));
                setShowCreateTag(false);
              }}
            >
              {t('Cancel')}
            </Button>
            <Button onClick={handleCreateTag} disabled={disableCreateConfirm}>
              {t('button.confirm')}
            </Button>
          </div>
        )}
        <div className="tw-h-[40vh] tw-overflow-auto tw-bg-white">
          {filteredTags.map((tag) => {
            return (
              <div
                className={`tw-h-14 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 ${tagBeingEdited.title !== tag.title && 'tw-p-1'}`}
              >
                <div
                  className={`tw-group tw-flex tw-h-full tw-items-center tw-justify-between tw-px-6 ${tagBeingEdited.title === tag.title ? 'tw-bg-white' : 'tw-rounded-md hover:tw-bg-sb-blue-grey-50'}`}
                >
                  <div className="tw-flex tw-items-center tw-gap-2.5">
                    {tagBeingEdited.title !== tag.title ? (
                      <div className="tw-flex tw-items-center tw-gap-4">
                        <IndeterminateCheckbox
                          {...{
                            checked: tag.applied,
                            indeterminate: tag.indeterminate,
                            onChange: () => handleTagToggle(tag.title),
                          }}
                        />
                        <div className="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center">
                          <Icon name="tag" height={20} width={14} strokeColor={TAG_COLORS[tag.tagColor]?.hexCode} />
                        </div>
                      </div>
                    ) : (
                      <ColorPickerDropdown
                        selectedTag={tagBeingEdited.tagColor}
                        setSelectedTag={(color) => setTagBeingEdited((prev) => ({ ...prev, tagColor: color }))}
                      />
                    )}
                    <span className="tw-text-base">{tag.title}</span>
                  </div>

                  {tagBeingEdited.title !== tag.title ? (
                    <div className="tw-hidden tw-items-center tw-gap-6 group-hover:tw-flex">
                      <Button
                        variant="newUnderline"
                        onClick={() => handleEditTag(tag)}
                        size="small"
                        className="!tw-text-base"
                      >
                        <Icon name="edit" />
                        {t('simcard.editTag')}
                      </Button>
                      <Button
                        variant="newUnderline"
                        onClick={() => handleOpenDeleteTagModal(tag)}
                        size="small"
                        className="!tw-text-base"
                      >
                        <Icon name="delete_bin_alternate" />
                        {t('button.delete')}
                      </Button>
                    </div>
                  ) : (
                    <div className="tw-flex tw-items-center tw-gap-2.5">
                      <Button variant="outline" onClick={() => setTagBeingEdited(DEFAULT_NEW_TAG)}>
                        {t('button.cancel')}
                      </Button>
                      <Button onClick={handleEditConfirm}>{t('button.confirm')}</Button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`tw-flex tw-items-center ${tagTitleTooLong ? 'tw-justify-between' : 'tw-justify-end'} tw-items-center tw-bg-sb-blue-grey-100 tw-px-6 tw-py-3`}
        >
          {tagTitleTooLong && <span className="tw-text-sb-orange-400">{t('simcard.tagTooLong')}</span>}
          <div className="tw-flex tw-items-center tw-gap-2.5">
            <Button variant="outline" className="tw-capitalize" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button onClick={handleSave} disabled={disableSaveButton}>
              {t('Save')}
            </Button>
          </div>
        </div>
        {deleteModalOpen && (
          <DeleteTagsModal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            handleDelete={handleDeleteTagConfirmation}
          />
        )}
      </AlternateModal>
    );
  },
);
