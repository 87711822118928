import { IconName } from '@assets/img/icons/icons';
import { BaseDropdown } from '@components/Atoms/BaseDropdown/BaseDropdown';
import { ModalDropdown } from '@components/Atoms/ModalDropdown/ModalDropdown';
import { AnchorProps } from 'node_modules/@headlessui/react/dist/internal/floating';
import React, { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type MultiSelectDropdownProps<T extends { title: string; isSpecialOption?: boolean }> = {
  options: T[];
  selectedOptions: T[];
  onChange: (options: T[], option: T) => void;
  renderOption: (option: T) => ReactNode;
  renderButton: (open: boolean, selectedOptions: T[]) => React.ReactElement;
  showFilter?: boolean;
  title?: string;
  filterPlaceholder?: string;
  anchorPosition?: AnchorProps;
  additionalItems?: ReactNode;
  bottomButton?: ReactNode;
  useModalDropdown?: boolean;
  ref: React.ForwardedRef<unknown>;
};

const MENU_ITEM_CLASS = `
  tw-text-sb-blue-grey-600 tw-flex tw-items-center 
  tw-gap-[10px] tw-rounded-md tw-border tw-border-solid tw-border-transparent
  tw-p-[10px] tw-text-[14px] hover:tw-cursor-pointer hover:tw-bg-sb-blue-grey-50 hover:!tw-border-sb-blue-grey-300 tw-justify-start tw-self-stretch 
`;

type Option = {
  title: string;
  isSpecialOption?: boolean;
  icon?: IconName;
};

const MultiSelectDropdownComponent = forwardRef(
  <T extends Option>(
    {
      options,
      selectedOptions,
      onChange,
      renderOption,
      renderButton,
      showFilter = false,
      title,
      filterPlaceholder,
      anchorPosition,
      additionalItems,
      bottomButton,
      useModalDropdown = false,
    }: MultiSelectDropdownProps<T>,
    ref,
  ) => {
    const { t } = useTranslation();
    const [internalSelectedOptions, setInternalSelectedOptions] = useState<T[]>(selectedOptions);

    const handleToggle = useCallback(
      (option: T) => {
        setInternalSelectedOptions((prevOptions) => {
          const isSelected = prevOptions.some((o) => o.title === option.title);
          const newOptions = isSelected
            ? prevOptions.filter((o) => o.title !== option.title)
            : [...prevOptions.filter((o) => !o.isSpecialOption), option];
          onChange(newOptions, option);
          return newOptions;
        });
      },
      [onChange],
    );

    useEffect(() => {
      setInternalSelectedOptions(selectedOptions);
    }, [selectedOptions]);

    const DropdownComponent = useModalDropdown ? ModalDropdown : BaseDropdown;

    return (
      <DropdownComponent
        ref={ref}
        showFilter={showFilter}
        {...(!useModalDropdown && { anchorPosition })}
        children={(open) => renderButton(open, internalSelectedOptions)}
        title={title}
        filterPlaceholder={filterPlaceholder}
        bottomButton={bottomButton}
        menuItems={(filterQuery) => {
          const filteredOptions = options.filter((option) =>
            option.title.toLowerCase().includes(filterQuery.toLowerCase()),
          );

          const regularOptions = filteredOptions.filter((option) => !option.isSpecialOption);
          const specialOptions = filteredOptions.filter((option) => option.isSpecialOption);

          if (filterQuery && regularOptions.length === 0 && filteredOptions.length === 0) {
            return (
              <div className="tw-flex tw-h-11 tw-w-full tw-items-center tw-px-[6px] tw-py-[5px] tw-pl-4">
                <span>{t('simcard.noMatching', { item: filterPlaceholder })}</span>
              </div>
            );
          }
          return (
            <>
              <div className="tw-w-full tw-px-[6px] tw-py-[5px]">
                {regularOptions.map((option, index) => (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggle(option);
                    }}
                    className={`${MENU_ITEM_CLASS}`}
                  >
                    {renderOption(option)}
                  </div>
                ))}
              </div>

              {specialOptions.length > 0 && (
                <div className="tw-w-full tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-sb-blue-grey-300"></div>
              )}

              {specialOptions.length > 0 && (
                <div className="tw-w-full tw-px-[6px] tw-py-[5px]">
                  {specialOptions.map((option, index) => (
                    <div
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggle(option);
                      }}
                      className={`${MENU_ITEM_CLASS} ${bottomButton ? 'tw-rounded-none' : 'tw-rounded-b-lg'}`}
                    >
                      {renderOption(option)}
                    </div>
                  ))}
                </div>
              )}
              {additionalItems}
            </>
          );
        }}
      />
    );
  },
);

export const MultiSelectDropdown = React.memo(MultiSelectDropdownComponent) as <T extends Option>(
  props: MultiSelectDropdownProps<T>,
) => JSX.Element;
