import { Icon } from '@components/Atoms/Icon/Icon';
import { LoadingPlaceholder } from '@components/Atoms/LoadingPlaceholder/LoadingPlaceholder';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemType } from 'src/types/types';
import { MultiSelectDropdown } from '../MultiSelectDropdown/MultiSelectDropdown';

type CoverageFilterProps = {
  mappedCoverage: MenuItemType[];
  selectedCoverage: MenuItemType[];
  handleCoverageChange: (options: MenuItemType[], option: MenuItemType) => void;
  filtersApplied: boolean;
  isLoading?: boolean;
};

export const CoverageFilter = React.memo(
  forwardRef(
    (
      {
        mappedCoverage,
        selectedCoverage,
        handleCoverageChange,
        filtersApplied,
        isLoading = false,
      }: CoverageFilterProps,
      ref,
    ) => {
      const { t } = useTranslation();

      return (
        <div className="tw-flex tw-flex-col">
          <span className="tw-py-2.5 tw-text-xs tw-font-bold tw-text-sb-blue-400">{t('coverage').toUpperCase()}</span>
          {isLoading ? (
            <LoadingPlaceholder className="tw-flex tw-h-[44.5px] tw-w-[140px] tw-items-center tw-justify-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 !tw-bg-white tw-p-3">
              <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
              <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-slate-200"></div>
            </LoadingPlaceholder>
          ) : (
            <MultiSelectDropdown<MenuItemType>
              ref={ref}
              options={mappedCoverage}
              selectedOptions={selectedCoverage}
              onChange={handleCoverageChange}
              renderOption={(option) => (
                <div className={`tw-flex tw-w-full tw-items-center tw-gap-2.5`}>
                  <input
                    type="checkbox"
                    readOnly
                    checked={selectedCoverage.some((o) => o.title === option.title)}
                    className="tw-form-checkbox tw-h-5 tw-w-5 tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-50"
                  />
                  {option.icon && (
                    <div className="tw-flex tw-w-[21px] tw-flex-shrink-0 tw-justify-center">
                      <Icon name={option.icon} />
                    </div>
                  )}
                  <div className="tw-w-full tw-flex-grow">
                    <span>{option.title}</span>
                  </div>
                </div>
              )}
              renderButton={(open) =>
                selectedCoverage.length ? (
                  selectedCoverage.length === 1 ? (
                    <div
                      className={`tw-flex tw-h-[44.5px] tw-w-full tw-items-center ${filtersApplied ? 'md:!tw-min-w-[136px]' : 'sm:!tw-min-w-[136px]'} tw-justify-between tw-gap-2.5 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5`}
                      role="button"
                    >
                      <div className="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden">
                        <div className="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center">
                          <Icon name={selectedCoverage[0].icon} />
                        </div>
                        <span className="tw-max-w-[90%] tw-truncate tw-whitespace-nowrap tw-text-sb-blue-grey-600">
                          {selectedCoverage[0].title}
                        </span>
                      </div>
                      <div className="tw-flex tw-h-1.5 tw-w-1.5 tw-items-center tw-justify-center">
                        <Icon name={open ? 'chevron_up' : 'chevron_down'} width={6} height={4} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`tw-flex tw-h-[44.5px] tw-w-full tw-items-center tw-justify-between tw-gap-2.5 tw-rounded-lg ${
                        filtersApplied ? 'md:!tw-min-w-[136px]' : 'sm:!tw-min-w-[136px]'
                      } tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5`}
                      role="button"
                    >
                      <div className="tw-flex tw-min-w-0 tw-flex-grow tw-items-center tw-gap-1.5">
                        <div className="tw-flex tw-h-6 tw-w-6 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25">
                          <span>{selectedCoverage.length}</span>
                        </div>
                        <div className="tw-flex tw-flex-grow tw-basis-0 tw-items-center tw-gap-0.5 tw-overflow-hidden">
                          {selectedCoverage.map((item, index) => (
                            <div
                              key={index}
                              className="tw-flex tw-h-[21px] tw-w-[19px] tw-flex-shrink-0 tw-items-center"
                            >
                              <Icon name={item.icon} />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="tw-flex tw-h-1.5 tw-w-1.5 tw-flex-shrink-0 tw-items-center tw-justify-center">
                        <Icon name={open ? 'chevron_up' : 'chevron_down'} width={6} height={4} />
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    className="tw-flex tw-items-center tw-justify-between tw-gap-2.5 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5"
                    role="button"
                  >
                    <span className="tw-max-w-[90%] tw-truncate tw-whitespace-nowrap tw-text-sb-blue-grey-600">
                      {t('simcards.filters.filterByRegion')}
                    </span>
                    <div className="tw-flex tw-h-1.5 tw-w-1.5 tw-items-center tw-justify-center">
                      <Icon name={open ? 'chevron_up' : 'chevron_down'} width={6} height={4} />
                    </div>
                  </div>
                )
              }
              showFilter={true}
              filterPlaceholder={t('regions')}
            />
          )}
        </div>
      );
    },
  ),
);
