import ErrorMessage from '@components/ErrorMessage';
import { LoadingWrapper } from '@components/LoadingWrapper';
import DeprecatedSimpleTable from '@components/Organisms/SimpleTable/DeprecatedSimpleTable';
import { WorldMap } from '@components/Organisms/WorldMap/WorldMap';
import { useChangePlanMutation } from '@modules/dashboard/simCard/simcards-api-slice';
import * as Sentry from '@sentry/react';
import { replaceWithDefaultJson } from '@utils/simCardUtils/simCardUtils';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CountryData, Simcard } from 'src/types/types';
import ChangePlanModal from '../../ChangePlanModal';
import Plan from '../../Plan';

type PlanAndCoverageTabProps = {
  simcard: Simcard;
  radio: string[];
  regions: string[];
  dataIsLoading: boolean;
};

const PlanAndCoverageTab = ({ simcard, radio, regions, dataIsLoading }: PlanAndCoverageTabProps) => {
  const { iccid } = useParams();

  // ChangePlan
  const [changePlan, changePlanInfo] = useChangePlanMutation();

  const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);
  const [changePlanErrorMessage, setChangePlanErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [filters, setFilters] = useState([]);

  // the contents of the coverage table are now downloaded from a url that comes in the simcard object
  // simcard.rateplan.coverage
  const [coverageRows, setCoverageRows] = useState<CountryData[]>([]);

  const formatCoverageRows = (data: CountryData[]) => {
    const result: { [key: string]: { country: string; carriers: any } } = {};

    for (const { country_iso, country, carriers } of data) {
      result[country_iso] = {
        country,
        carriers,
      };
    }

    return result;
  };

  const [countryData, setCountryData] = useState(formatCoverageRows(coverageRows));

  useEffect(() => {
    if (coverageRows) {
      setCountryData(formatCoverageRows(coverageRows));
    }
  }, [coverageRows]);

  useEffect(() => {
    (async () => {
      if (!dataIsLoading) {
        if (simcard?.rateplan?.coverage) {
          const response = await fetch(simcard.rateplan.coverage);
          if (response.ok) {
            const data = await response.json();
            setCoverageRows(data);
          } else {
            Sentry.captureException(new Error('Coverage map missing'), {
              level: 'error',
            });
            const response = await fetch(replaceWithDefaultJson(simcard.rateplan.coverage));
            const data = await response.json();
            setCoverageRows(data);
          }
        }
      }
    })();
  }, [simcard, dataIsLoading]);

  const memoizedFilters = useMemo(() => {
    return [
      {
        name: 'Region',
        column: 'continents',
        options: regions?.map((o) => ({ label: o, value: o })),
        selectedOption: {},
      },
      {
        name: 'Radio',
        column: 'network',
        options: radio?.map((o) => ({ label: o, value: o })),
        selectedOption: {},
      },
    ];
  }, [radio, regions]);

  useEffect(() => {
    setFilters(memoizedFilters);
  }, [memoizedFilters]);

  const updateFilter = useCallback((key, selectedOption) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => (filter.column === key ? { ...filter, selectedOption } : filter)),
    );
  }, []);

  const resetFilter = useCallback(() => {
    setFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, selectedOption: {} })));
  }, []);

  const columns = [
    {
      Header: 'COUNTRY',
      accessor: 'country',
    },
    {
      Header: 'REGION',
      accessor: 'continents',
      Cell: ({ value }) => <div>{value.join(', ')}</div>,
    },
    {
      Header: 'MOBILE NETWORK OPERATOR',
      accessor: 'carrier',
    },
    {
      Header: 'AVAILABLE RADIO TECHNOLOGIES',
      accessor: 'network',
      Cell: ({ value }) => <div>{value.join(', ')}</div>,
    },
  ];

  const handleChangePlan = async ({ plan_id }) => {
    setChangePlanErrorMessage('');
    const response = await changePlan({ iccids: [iccid], plan_id });
    if ('errorMessage' in response) {
      setChangePlanErrorMessage(response.errorMessage as string);
    } else {
      setIsChangePlanOpen(false);
    }
  };

  const parseTableData = (data: CountryData[], filters = null) => {
    const result = [];

    for (const { country, continents, carriers } of data) {
      const continentFilter = filters?.[0]?.selectedOption?.value || null;
      const networkFilter = filters?.[1]?.selectedOption?.value || null;
      if (!continentFilter || continents.includes(continentFilter)) {
        carriers.forEach(({ carrier, network }) => {
          if (!networkFilter || network.includes(networkFilter)) {
            result.push({
              country,
              continents,
              carrier,
              network,
            });
          }
        });
      }
    }
    return result;
  };

  return (
    <div className="mt-3 vstack tw-flex tw-flex-col tw-gap-3">
      {!!simcard?.rateplan && (
        <div className="tw-flex tw-flex-col tw-gap-3 md:tw-grid md:tw-h-full md:tw-grid-cols-3">
          <div className="tw-relative tw-w-full md:tw-col-span-1 md:tw-h-full">
            <Plan
              handleOpenChangePlan={() => setIsChangePlanOpen(true)}
              rateplan={simcard?.rateplan}
              iccid={simcard?.iccid}
            />
          </div>
          <div className="tw-h-[500px] tw-w-full md:tw-col-span-2 md:tw-h-full">
            <WorldMap countryData={countryData} showBorder={true} />
          </div>
        </div>
      )}
      <div className={`d-flex`}>
        <LoadingWrapper isLoading={!simcard || !radio || !regions}>
          <DeprecatedSimpleTable
            hasSearch
            hasExport={false}
            data={parseTableData(coverageRows, filters) || []}
            columns={columns}
            filters={filters}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
          />
        </LoadingWrapper>
      </div>
      <ChangePlanModal
        iccid={simcard?.iccid}
        isOpen={isChangePlanOpen}
        handleClose={() => setIsChangePlanOpen(false)}
        handleComplete={handleChangePlan}
        errorMessage={changePlanErrorMessage}
        currentPlan={simcard?.rateplan?.plan_id}
        changePlanIsPending={changePlanInfo?.status}
      />
      <ErrorMessage message={errorMessage} onClose={() => setErrorMessage('')} />
    </div>
  );
};

const MemoizedPlanAndCoverage = React.memo(PlanAndCoverageTab, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});

export const PlanAndCoverage = MemoizedPlanAndCoverage;
