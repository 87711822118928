import { AlternateModal } from '@components/Atoms/AlternateModal/AlternateModal';
import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { SeparatedRadioButtons } from '@components/Atoms/SeparatedRadioButtons/SeparatedRadioButtons';
import { SimcardsListViewItem } from '@modules/dashboard/simCard/simcards-slice';
import { Row } from '@tanstack/react-table';
import saveAs from 'file-saver';
import Papa from 'papaparse';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { utils, write } from 'xlsx';

interface ExportSimsModalProps<TData extends SimcardsListViewItem> {
  open: boolean;
  onClose: () => void;
  selectedRows: Row<TData>[];
}

type ExportOption = { title: '.xlsx' | '.csv' };

const EXPORT_OPTIONS: ExportOption[] = [{ title: '.xlsx' }, { title: '.csv' }];

export function exportToExcel<TData extends SimcardsListViewItem>(selectedRows: Row<TData>[], fileName: string) {
  const values = selectedRows.map((row) => ({
    ...row.original,
    tags: row.original.tags?.join(', '),
  }));
  const worksheet = utils.json_to_sheet(values);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const buf = write(workbook, { bookType: 'xlsx', type: 'buffer' });
  saveAs(new Blob([buf], { type: 'application/octet-stream' }), fileName);
}

export function exportToCSV<TData extends SimcardsListViewItem>(rows: Row<TData>[], fileName: string) {
  const values = rows.map((row) => row.original);
  const csv = Papa.unparse(values);
  const csvBlob = new Blob([csv], { type: 'text/csv' });
  saveAs(csvBlob, fileName);
}

const ExportSimsModalComponent = <TData extends SimcardsListViewItem>({
  open,
  onClose,
  selectedRows,
}: ExportSimsModalProps<TData>) => {
  const { t } = useTranslation();
  const [selectedExport, setSelectedExport] = useState<ExportOption | null>(null);

  const handleSelectExport = useCallback((option: ExportOption) => {
    setSelectedExport((prev) => (prev?.title === option.title ? null : option));
  }, []);

  const handleExport = useCallback(() => {
    const fileName = selectedExport.title === '.xlsx' ? 'data.xlsx' : 'product_data.csv';
    if (selectedExport.title === '.xlsx') {
      exportToExcel(selectedRows, fileName);
    } else {
      exportToCSV(selectedRows, fileName);
    }
    onClose();
  }, [selectedExport, selectedRows]);

  return (
    <AlternateModal
      modalOpen={open}
      closeModal={onClose}
      title={t('simcards.exportSims')}
      className="!tw-w-[360px]"
      showClose
    >
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-6 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-p-6">
        <div className="tw-flex tw-items-center tw-gap-1.5">
          <Icon name="info_warning" strokeColor="#2467CA" />
          <span className="tw-text-base">
            {t('simcards.exportSims.numberOfSims', {
              noOfSims: selectedRows.length,
              simOrSims: selectedRows.length > 1 ? t('SIMs') : t('SIM'),
            })}
          </span>
        </div>

        <div className="tw-flex tw-flex-col tw-items-center tw-gap-3.5">
          <SeparatedRadioButtons options={EXPORT_OPTIONS} selected={selectedExport} setSelected={handleSelectExport} />
          <span className="tw-text-xs tw-italic tw-text-sb-blue-grey-600">{t('simcards.exportSims.selectFormat')}</span>
        </div>
      </div>
      <div className="tw-flex tw-justify-end tw-gap-2.5 tw-bg-sb-blue-grey-100 tw-px-6 tw-py-3.5">
        <Button variant="outline" className="tw-w-full" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          variant="primaryDark"
          className="tw-w-full disabled:tw-text-sb-blue-grey-500"
          onClick={handleExport}
          disabled={!selectedExport?.title}
        >
          {t('simcards.exportSims.downloadSims', { xlsxOrCsv: selectedExport ? selectedExport.title : null })}
        </Button>
      </div>
    </AlternateModal>
  );
};

export const ExportSimsModal = React.memo(ExportSimsModalComponent) as <TData extends SimcardsListViewItem>(
  props: ExportSimsModalProps<TData>,
) => JSX.Element;
