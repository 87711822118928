import TagDropdown, { Checkmark } from '@components/TagDropdown/element';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTags } from 'src/hooks/useTags';

const ActivateSimsTagDropdown = ({ tags = [], setTags }: { tags: string[]; setTags: any }) => {
  const [checkmarks, setCheckmarks] = useState<Checkmark[]>([]);
  const { tags: allTags, getTagsResponse } = useTags();
  const applyTags = async (tagsToAdd, tagsToRemove) => {
    setTags([...tags, ...tagsToAdd].filter((tag) => !tagsToRemove.includes(tag)));
  };

  useEffect(() => {
    const checkmarks: Checkmark[] = allTags?.map((allTag) => {
      return {
        label: allTag,
        checked: tags.includes(allTag),
        indeterminate: false,
        action: 'none',
        loading: false,
      };
    });
    setCheckmarks(checkmarks);
  }, [tags, getTagsResponse.status]);

  return (
    <TagDropdown
      applyTags={applyTags}
      checkmarks={checkmarks}
      setCheckmarks={setCheckmarks}
      customDropdownToggle={(onClick) => (
        <Button variant="outline-primary" size="sm" onClick={onClick}>
          <div className="d-flex align-items-center">Add tags</div>
        </Button>
      )}
    />
  );
};

export default ActivateSimsTagDropdown;
