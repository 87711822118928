type SeparatedRadioButtonsProps = {
  options: { title: string }[];
  selected: { title: string };
  setSelected: ({ title }: { title: string }) => void;
};

export const SeparatedRadioButtons = ({ options, selected, setSelected }: SeparatedRadioButtonsProps) => {
  return (
    <div className="tw-flex tw-gap-2.5">
      {options.map((option) => (
        <label
          key={option.title}
          className="tw-relative tw-inline-flex tw-cursor-pointer tw-items-center tw-gap-1.5 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-py-0.5 tw-pl-2 tw-pr-3"
        >
          <input
            type="radio"
            name="separated-radio-buttons"
            value={option.title}
            checked={selected?.title === option.title}
            onChange={() => setSelected(option)}
            className="tw-absolute tw-z-10 tw-h-full tw-w-full tw-cursor-pointer tw-appearance-none tw-opacity-0"
            aria-checked={selected?.title === option.title}
          />
          <span
            className={`tw-z-20 tw-flex tw-h-3 tw-w-3 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-solid ${
              selected?.title === option.title
                ? 'hover:tw-shadow-input-selected-hover tw-border-sb-blue-300 tw-bg-sb-blue-grey-100'
                : 'hover:tw-shadow-input-unselected-hover tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 hover:tw-bg-white'
            }`}
          >
            {selected?.title === option.title && (
              <span className="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-sb-blue-300"></span>
            )}
          </span>
          <span className="tw-text-base">{option.title}</span>
        </label>
      ))}
    </div>
  );
};
