import { Tab, TabGroup, TabList } from '@headlessui/react';
import { useState } from 'react';

type PageNavBarProps = {
  tabs: { key: string; label: string }[];
  onSelect: (tab: string) => void;
  selected?: string;
};

export const PageNavBar = ({ tabs, onSelect, selected }: PageNavBarProps) => {
  const defaultTab = tabs.findIndex((item) => item.key === selected);
  const [selectedIndex, setSelectedIndex] = useState(defaultTab !== -1 ? defaultTab : 0);

  return (
    <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <TabList className="tw-relative tw-z-0 tw-flex tw-flex-wrap tw-gap-x-10 tw-gap-y-2 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-100">
        {tabs.map((tab) => (
          <Tab
            className={({ selected }) =>
              `tw-relative tw-z-10 tw-border-0 tw-bg-transparent tw-px-0 tw-py-2.5 tw-text-xl tw-font-normal tw-text-sb-blue-grey-600 ${selected ? 'tw-font-semibold !tw-text-sb-blue-500' : ''} before:tw-absolute before:tw-bottom-[-1px] before:tw-left-0 before:tw-right-0 before:tw-h-[2px] before:tw-bg-transparent before:tw-content-[''] data-[selected]:before:tw-bg-sb-orange-400`
            }
            key={tab.key}
            onClick={() => onSelect(tab.key)}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
};
