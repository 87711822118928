import batchSimImage from '@assets/img/batch.png';
import individualSimImage from '@assets/img/individual.png';
import { LoadingWrapper } from '@components/LoadingWrapper';
import { IMEI_LOCK_STATE } from '@core/constants';
import { useLazyGetBillingBalanceQuery } from '@modules/dashboard/billing/billing-api-slice';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useActivateBatchSimsMutation, useActivateSimsMutation, useGetPlansQuery } from '../activateSims-api-slice';
// import { Creators as BillingCreators } from "../../billing/reducer";
import { AddSims, Plans, SimOption } from '../index';

const WIZARD_STEPS = {
  INITIAL: 'INITIAL',
  ADD_SIMS: 'ADD_SIMS',
  CHOOSE_PLAN: 'CHOOSE_PLAN',
};

const SIM_OPTION = {
  INDIVIDUAL: 'INDIVIDUAL',
  PACK: 'PACK',
};

const DescriptionText = ({ step, simOption }) => {
  if (step === WIZARD_STEPS.INITIAL) {
    return (
      <div>
        Register your SIM to activate it and begin using it.
        <br />
        Add SIMs individually or batch register a pack of SIMs all at once.
      </div>
    );
  }
  if (step === WIZARD_STEPS.ADD_SIMS && simOption === SIM_OPTION.INDIVIDUAL) {
    return (
      <div>
        Find the ICCID and Serial Number on the back of your SIM card.
        <br />
        Add both codes below and optionally assign tags.
      </div>
    );
  }
  if (step === WIZARD_STEPS.ADD_SIMS && simOption === SIM_OPTION.PACK) {
    return (
      <div>
        Find the Batch ID and Serial Number on the back of your SIM card.
        <br />
        Add both codes below and all SIMs from your pack are registered at once.
      </div>
    );
  }
  if (step === WIZARD_STEPS.CHOOSE_PLAN) {
    return (
      <div>
        Only pay for the data you need.
        <br />
        Switch plans and coverage at any time after registration.
      </div>
    );
  }
};

const RegisterSims = () => {
  const { t } = useTranslation();
  const STEP_TEXT = {
    INITIAL: t('simCard.step1of3'),
    ADD_SIMS: t('simCard.step2of3'),
    CHOOSE_PLAN: t('simCard.step3of3'),
  };
  // ActivateSims
  const [activateSims, activateSimsInfo] = useActivateSimsMutation();

  const [activateBatchSims, activateBatchSimsInfo] = useActivateBatchSimsMutation();

  const [activateSimsIsPending, setActivateSimsIsPending] = useState(undefined);

  useEffect(() => {
    if (activateSimsInfo?.status === 'pending') {
      setActivateSimsIsPending(activateSimsInfo.status);
    } else {
      setActivateSimsIsPending(activateBatchSimsInfo?.status);
    }
  }, [activateSimsInfo?.status, activateBatchSimsInfo?.status]);
  // GetBillingBalance
  const [getBillingBalance] = useLazyGetBillingBalanceQuery();
  const navigate = useNavigate();

  const [step, setStep] = useState(WIZARD_STEPS.INITIAL);
  const [tags, setTags] = useState<string[]>([]);
  const [sims, setSims] = useState([]);
  const [simOption, setSimOption] = useState(null);
  const [imeiLockValue, setImeiLockValue] = useState<boolean>(false);

  const { data: PlanData, isLoading: plansIsLoading } = useGetPlansQuery({ iccid: sims[0]?.iccid ?? 'default' });

  const resetData = () => {
    setTags([]);
    setSims([]);
    setSimOption(null);
  };

  const handleXClick = () => {
    resetData();
    navigate('/dashboard/sim-cards');
  };

  const handleSimOptionClick = (option) => {
    setSimOption(option);
    setStep(WIZARD_STEPS.ADD_SIMS);
  };

  const handleAddSims = async () => {
    setStep(WIZARD_STEPS.CHOOSE_PLAN);
  };

  const handleComplete = async ({ plan_id }) => {
    if (simOption === SIM_OPTION.INDIVIDUAL) {
      await activateSims({
        sims: sims.map(({ iccid, serialNumber }) => ({
          iccid,
          code: serialNumber,
        })),
        plan_id,
        tags,
        imei_lock: imeiLockValue ? IMEI_LOCK_STATE.ON : IMEI_LOCK_STATE.OFF,
      });
    } else {
      await activateBatchSims({
        batches: sims.map(({ iccid, serialNumber }) => ({
          batch_id: iccid,
          code: serialNumber,
        })),
        plan_id,
        tags,
        imei_lock: imeiLockValue ? IMEI_LOCK_STATE.ON : IMEI_LOCK_STATE.OFF,
      });
    }
    await getBillingBalance();
    setStep(WIZARD_STEPS.INITIAL);
    navigate('/dashboard/sim-cards', {
      state: { registerSimsModalOpen: true },
    });
  };

  const handleCancel = () => {
    resetData();
    setStep(WIZARD_STEPS.INITIAL);
  };

  return (
    <div className="h-100">
      <Modal fullscreen show={true}>
        <Modal.Body className="p-0 bg-dark bg-ellipses register-sims-bg">
          <div className="d-flex flex-column header-text-container">
            <div className="d-flex justify-content-end close-button">
              <span onClick={handleXClick} className="fe fe-x cursor-pointer text-white" />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h6 className={`mb-4 text-uppercase text-center text-white`}>{STEP_TEXT[step]}</h6>
              <h1 className="display-4 text-center text-white strong">
                {step === WIZARD_STEPS.CHOOSE_PLAN ? 'Select Plan' : 'Register SIMs'}
              </h1>
              <div className="d-flex justify-content-center">
                <div className="fs-16 text-center text-white w-50">
                  <DescriptionText simOption={simOption} step={step} />
                </div>
              </div>
            </div>
          </div>
          {step === WIZARD_STEPS.INITIAL && (
            <div className="sim-option-cards options">
              <div className="d-flex justify-content-center w-100">
                <div className="w-30">
                  <SimOption
                    onClick={() => handleSimOptionClick(SIM_OPTION.INDIVIDUAL)}
                    title="Individual SIMs"
                    buttonText="Register SIMs individually"
                    image={individualSimImage}
                    data-test-id="register-sims:single-sim-button"
                  />
                </div>
                <div className="w-30 ms-4">
                  <SimOption
                    onClick={() => handleSimOptionClick(SIM_OPTION.PACK)}
                    title="SIM packs"
                    buttonText="Register batch of SIMs"
                    image={batchSimImage}
                    data-test-id="register-sims:batch-button"
                  />
                </div>
              </div>
            </div>
          )}
          {step === WIZARD_STEPS.ADD_SIMS && (
            <div className="sim-option-cards sims">
              <div className="d-flex justify-content-center w-100">
                <div className="w-75">
                  {/* <LoadingWrapper isLoading={getTagsResponse.isFetching}> */}
                  <AddSims
                    sims={sims}
                    tags={tags}
                    setTags={setTags}
                    setSims={setSims}
                    handleAddSims={handleAddSims}
                    handleCancel={handleCancel}
                    isIndividual={simOption === SIM_OPTION.INDIVIDUAL}
                    imeiLock={imeiLockValue}
                    setImeiLock={setImeiLockValue}
                  />
                  {/* </LoadingWrapper> */}
                </div>
              </div>
            </div>
          )}
          {step === WIZARD_STEPS.CHOOSE_PLAN && (
            <div className="sim-option-cards plans">
              <div className="d-flex justify-content-center w-100">
                <div className="w-75">
                  <div className="d-flex w-100 justify-content-between">
                    <LoadingWrapper isLoading={plansIsLoading}>
                      <Plans
                        initialPlans={PlanData}
                        handleComplete={handleComplete}
                        changePlanIsPending={activateSimsIsPending}
                      />
                    </LoadingWrapper>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisterSims;
