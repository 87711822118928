import { LoadingPlaceholder } from '@components/Atoms/LoadingPlaceholder/LoadingPlaceholder';
import { Row } from '@tanstack/react-table';
import { handleExport, hideTooltip, showTooltip } from '@utils/tableUtils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISimpleTableProps } from 'src/types/types';
import { PAGE_SIZE_OPTIONS } from '../../../core/constants';
import { Select, TransparentInput } from '../../../modules/dashboard/simCard';
import { SimpleTableBody } from './SimpleTableBody';
import { SimpleTableHeader } from './SimpleTableHeader';

interface ISimpleTablePropsExtended<TData extends object> extends ISimpleTableProps<TData> {
  onRowClick?: (row: Row<TData>) => void;
  showAlternateBody?: boolean;
  AlternateBody?: () => JSX.Element;
}

const SimpleTable = <TData extends object>({
  table,
  globalFilter,
  setGlobalFilter,
  hasExport = true,
  hasPerPage = true,
  hasActions = false,
  hasSearch = false,
  actionItems,
  noDataText = 'No data',
  processing = false,
  customActionButton,
  onRowClick,
  showAlternateBody = false,
  AlternateBody,
}: ISimpleTablePropsExtended<TData>) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (tableRef.current && wrapperRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          wrapperRef.current.style.height = `${entry.contentRect.height}px`;
        }
      });
      resizeObserver.observe(tableRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [tableRef, wrapperRef]);
  const [tooltipVisibility, setTooltipVisibility] = useState<Record<string, boolean>>({});

  const handleExportCallback = useCallback(
    () => handleExport(table.getRowModel().rows.map((row) => row.original)),
    [table],
  );

  const { pageSize } = table.getState().pagination;

  const selectedPageSizeOption = useMemo(() => PAGE_SIZE_OPTIONS.find(({ value }) => value === pageSize), [pageSize]);

  const memoizedSetPageSize = useCallback(
    (value: number) => {
      table.setPageSize(value);
    },
    [table],
  );

  return (
    <div className="tw-mb-0 tw-mt-0 tw-w-full">
      <div
        ref={wrapperRef}
        className="tw-relative tw-h-full tw-w-full tw-overflow-hidden tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300"
      >
        <div ref={tableRef} className="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-overflow-x-auto tw-bg-blue-300">
          {(hasSearch || hasPerPage || hasExport) && (
            <div className="tw-flex tw-justify-between tw-rounded tw-rounded-b-none tw-bg-white tw-p-3 tw-text-bs-header">
              <div className="tw-flex tw-items-center tw-justify-between tw-align-middle">
                {hasSearch && (
                  <div className="tw-flex-grow">
                    <TransparentInput
                      placeholder="Search"
                      value={globalFilter || ''}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      icon="search"
                    />
                  </div>
                )}
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-gap-5 tw-py-2">
                {hasPerPage && (
                  <Select
                    options={PAGE_SIZE_OPTIONS}
                    selected={selectedPageSizeOption}
                    onClick={({ value }) => memoizedSetPageSize(value)}
                  />
                )}
                {hasExport && (
                  <button
                    className="tw-inline-flex tw-items-center tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-px-3 tw-py-1 tw-text-sm tw-font-medium tw-text-gray-700 tw-shadow-none hover:tw-bg-gray-50"
                    onClick={handleExportCallback}
                  >
                    <i className="fe fe-download tw-mr-2" />
                    {t('export')}
                  </button>
                )}
                {customActionButton}
              </div>
            </div>
          )}

          <table className="tw-w-full tw-table-auto tw-bg-white tw-text-xs">
            <SimpleTableHeader
              headerGroups={table.getHeaderGroups()}
              tooltipVisibility={tooltipVisibility}
              showTooltip={(columnId) => showTooltip(setTooltipVisibility, columnId)}
              hideTooltip={(columnId) => hideTooltip(setTooltipVisibility, columnId)}
            />
            {processing && (
              <tbody>
                <tr>
                  <td colSpan={100}>
                    <div className="tw-flex tw-justify-center">
                      <LoadingPlaceholder className="tw-border-b-solid tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-rounded-b-lg tw-border-b tw-border-none tw-border-sb-blue-grey-300 !tw-bg-white">
                        <div className="tw-w-full tw-flex-col">
                          {Array.from({ length: 10 }).map((_, index) => (
                            <div key={index}>
                              <div className="tw-flex tw-h-14 tw-w-full tw-items-center tw-gap-6 tw-bg-sb-blue-grey-25 tw-p-3">
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                              </div>
                              <div className="tw-flex tw-h-14 tw-w-full tw-items-center tw-gap-2 tw-p-3">
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                                <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </LoadingPlaceholder>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!processing && table.getRowModel().rows.length === 0 && !showAlternateBody && (
              <tbody>
                <tr>
                  <td colSpan={100} className="tw-p-6">
                    <div className="tw-flex tw-justify-center">
                      <h3 className="tw-m-0 tw-py-2">{noDataText}</h3>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!processing && !showAlternateBody && (
              <SimpleTableBody
                rows={table.getRowModel().rows}
                hasActions={hasActions}
                actionItems={actionItems}
                onRowClick={onRowClick}
              />
            )}
            {showAlternateBody && AlternateBody && (
              <tbody>
                <tr>
                  <td colSpan={100}>
                    <AlternateBody />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SimpleTable) as typeof SimpleTable;
