import { Button } from '@components/Atoms/Button/Button';
import ErrorMessage from '@components/ErrorMessage';
import { ApiDetailsModal } from '@components/Molecules/ApiDetailsModal/ApiDetailsModal';
import { ApiSuccessModal } from '@components/Molecules/ApiSuccessModal/ApiSuccessModal';
import { CreateApiKeyModal } from '@components/Molecules/CreateApiKeyModal/CreateApiKeyModal';
import NewSimpleTable from '@components/Organisms/NewSimpleTable/DeprecatedNewSimpleTable';
import { useGetSelfQuery } from '@modules/dashboard/account/account-api-slice';
import {
  useDeleteApiKeyMutation,
  useGetApiKeysQuery,
  useGetApiScopesQuery,
} from '@modules/dashboard/integrations/integrations-api-slice';
import useIsProcessing from '@utils/hooks/useIsProcessing';
import { handleCreatePermissions, transformPermissionsData } from '@utils/integrationUtils';
import { convertTimeFields } from '@utils/timezoneConversion';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ListViewApiKey, PermissionNode } from 'src/types/types';

const DEFAULT_IP = '0.0.0.0/0';
const INITIAL_PAGE_SIZE = 1000;
const DELETE_DELAY = 500;

type ApiDetails = {
  transformedPermissions: PermissionNode | null;
  selectedPermissions: Record<string, string[] | string> | null;
  selectedKeyName: string | null;
  allowedIp: string | null;
  expirationDate: string | null;
  keyId: string | null;
  errorMessage: string;
  apiDetailsModalOpen: boolean;
  apiSuccess: boolean;
  apiKey: string;
};

export const Api = React.memo(() => {
  const { t } = useTranslation();
  const { data: userData } = useGetSelfQuery();
  const getApiKeysResponse = useGetApiKeysQuery();
  const getApiScopesResponse = useGetApiScopesQuery();
  const [deleteApiKey] = useDeleteApiKeyMutation();
  const [apiKeys, setApiKeys] = useState<ListViewApiKey[]>([]);
  const lastDeletedKeyIdRef = useRef<string | null>(null);
  const [createApiModalOpen, setCreateApiModalOpen] = useState(false);
  const [preferredTime, setPreferredTime] = useState(moment.tz.guess());

  const [apiDetails, setApiDetails] = useState<ApiDetails>({
    transformedPermissions: null as PermissionNode,
    selectedPermissions: null as Record<string, string[]>,
    selectedKeyName: null as string,
    allowedIp: null as string,
    expirationDate: null as string,
    keyId: null as string,
    errorMessage: '',
    apiDetailsModalOpen: false,
    apiSuccess: false,
    apiKey: '',
  });

  useEffect(() => {
    if (userData?.preferred_timezone) {
      setPreferredTime(userData.preferred_timezone);
    }
  }, [userData]);

  useEffect(() => {
    if (getApiKeysResponse?.data?.keys) {
      setApiKeys(convertTimeFields(getApiKeysResponse.data.keys, preferredTime, ['last_used']));
    }
  }, [getApiKeysResponse?.data?.keys]);

  useEffect(() => {
    if (getApiScopesResponse?.data?.scopes) {
      const transformedPermissions = transformPermissionsData(getApiScopesResponse.data.scopes);
      setApiDetails((prev) => ({ ...prev, transformedPermissions }));
    }
  }, [getApiScopesResponse?.data?.scopes]);

  const handleModalClose = useCallback(() => {
    setApiDetails((prev) => ({
      ...prev,
      selectedPermissions: null,
      allowedIp: null,
      expirationDate: null,
      keyId: null,
      selectedKeyName: null,
      apiDetailsModalOpen: false,
    }));
  }, []);

  const handleRowClick = useCallback(
    (data: ListViewApiKey) => {
      if (lastDeletedKeyIdRef.current === data.key_id) return;
      const selectedPermissions = handleCreatePermissions(data.scopes, apiDetails.transformedPermissions);
      setApiDetails((prev) => ({
        ...prev,
        selectedPermissions,
        selectedKeyName: data.key_name,
        keyId: data.key_id,
        allowedIp: data.allowed_ips !== DEFAULT_IP ? data.allowed_ips : null,
        expirationDate: data.expiration ? data.expiration.split(' ')[0] : null,
        apiDetailsModalOpen: true,
      }));
    },
    [apiDetails.transformedPermissions],
  );

  const handleDeleteApiKey = useCallback(
    async (keyId: string) => {
      lastDeletedKeyIdRef.current = keyId;
      try {
        await toast.promise(deleteApiKey({ keyId }), {
          pending: t('integrations.api.deletingApiKey'),
          success: t('integrations.api.apiKeyDeletedSuccess'),
          error: t('integrations.api.apiKeyDeleteFailed'),
        });
      } catch (error) {
        console.error('Failed to delete API key:', error);
        setApiDetails((prev) => ({ ...prev, errorMessage: t('integrations.api.apiKeyDeleteFailed') }));
      } finally {
        setTimeout(() => {
          lastDeletedKeyIdRef.current = null;
        }, DELETE_DELAY);
      }
    },
    [deleteApiKey, t],
  );

  const getKeyType = useCallback(
    (scopes: string[]) => {
      if (scopes.some((scope) => scope === '*:*')) return t('general.readWrite');
      if (scopes.some((scope) => scope === '*:read')) return t('general.readOnly');
      return t('general.custom');
    },
    [t],
  );

  const getKeyTypeClassName = useCallback(
    (keyType: string) => {
      if (keyType === t('general.custom')) return 'tw-border-sb-green-400 tw-bg-sb-green-400 tw-text-white';
      if (keyType === t('general.readOnly')) return 'tw-border-sb-blue-300 tw-text-sb-blue-300';
      return 'tw-border-sb-blue-300 tw-bg-sb-blue-300 tw-text-white';
    },
    [t],
  );

  const KeyIdCell: React.FC<{ row: { original: { key_mode: string; key_id: string } } }> = ({ row }) => (
    <span>
      {row.original.key_mode}-{row.original.key_id}...
    </span>
  );

  const KeyTypeCell: React.FC<{ value: string[] }> = ({ value: scopes }) => {
    if (!Array.isArray(scopes)) return <span>{t('general.unknown')}</span>;
    const keyType = getKeyType(scopes);
    return (
      <span
        className={`tw-rounded-[4px] tw-border tw-border-solid tw-px-2.5 tw-py-[1px] ${getKeyTypeClassName(keyType)}`}
      >
        {keyType}
      </span>
    );
  };

  const LastApiCallCell: React.FC<{ value: string }> = ({ value }) => {
    if (value.split(' ').length === 1) {
      return <span>{value}</span>;
    }
    return (
      <>
        <span className="tw-sb-blue-500 tw-font-semibold">{value.split(' ').slice(0, 3).join(' ')}</span>
        <span> {value.split(' ').slice(3).join(' ')}</span>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t('integrations.table.name'),
        accessor: 'key_name',
      },
      {
        Header: t('integrations.table.keyId'),
        accessor: 'key_id',
        Cell: KeyIdCell,
      },
      {
        Header: t('integrations.table.keyType'),
        accessor: 'scopes',
        Cell: KeyTypeCell,
      },
      {
        Header: t('integrations.table.lastApiCall'),
        accessor: 'last_used',
        extraInfo: t('timezone.extraInfo', { preferredTime: preferredTime }),
        Cell: LastApiCallCell,
      },
      {
        Header: t('integrations.table.allowedIps'),
        accessor: 'allowed_ips',
      },
    ],
    [t, preferredTime, getKeyType, getKeyTypeClassName],
  );

  const openSuccessModal = (apiKey: string) => {
    setApiDetails((prev) => ({
      ...prev,
      apiKey: apiKey,
      apiSuccess: true,
    }));
  };
  const closeSuccessModal = () => {
    setApiDetails((prev) => ({
      ...prev,
      apiKey: '',
      apiSuccess: false,
    }));
  };

  const isProcessing = useIsProcessing([getApiKeysResponse.isFetching, getApiKeysResponse.isLoading]);
  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-6">
        <div className="tw-flex tw-items-center tw-justify-between">
          <h4 className="tw-mb-0 tw-text-xl tw-font-semibold tw-leading-none tw-text-sb-blue-400">
            {t('integrations.api.apiKeys')}
          </h4>
          <Button disabled={getApiScopesResponse.isLoading} onClick={() => setCreateApiModalOpen(true)}>
            {t('integrations.api.createNewApi')}
          </Button>
        </div>
        <NewSimpleTable
          title={t('integrations.api.apiKeys')}
          hasPagination={false}
          initialPageSize={INITIAL_PAGE_SIZE}
          data={apiKeys}
          columns={columns}
          hasPerPage={false}
          hasExport={false}
          processing={isProcessing}
          hasActions
          onRowClick={(data) => handleRowClick(data)}
          actionItems={[
            {
              title: t('button.delete'),
              handleClick: (row) => handleDeleteApiKey(row.values.key_id),
            },
          ]}
        />
      </div>
      {createApiModalOpen && (
        <CreateApiKeyModal
          open={createApiModalOpen}
          closeModal={() => setCreateApiModalOpen(false)}
          data={getApiScopesResponse.data.scopes}
          openSuccessModal={openSuccessModal}
        />
      )}
      {apiDetails.apiDetailsModalOpen && (
        <ApiDetailsModal
          open={apiDetails.apiDetailsModalOpen}
          closeModal={handleModalClose}
          data={apiDetails.transformedPermissions}
          scopes={apiDetails.selectedPermissions}
          name={apiDetails.selectedKeyName}
          allowedIp={apiDetails.allowedIp}
          expirationDate={apiDetails.expirationDate}
          keyId={apiDetails.keyId}
        />
      )}
      {apiDetails.apiSuccess && (
        <ApiSuccessModal open={apiDetails.apiSuccess} onClose={closeSuccessModal} apiKey={apiDetails.apiKey} />
      )}
      <ErrorMessage
        message={apiDetails.errorMessage}
        onClose={() => setApiDetails((prev) => ({ ...prev, errorMessage: '' }))}
      />
    </div>
  );
});
