import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

const AmountSelector = ({
  limit = 10000,
  prefix = '',
  label = 'Please enter amount',
  noLabel = false,
  placeholder = 'Please enter a number',
  step = 1,
  min = 10,
  value,
  setValue,
  setValues,
  decimalsLimit = 2,
  disableGroupSeparators = true,
  maxLength = 10,
  disabled = false,
  subtitleText = '',
  hasSubtitle = true,
  setIsValueValid,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [className, setClassName] = useState('');

  const handleOnValueChange = (value, _, values) => {
    setValues(values);
    if (!value) {
      setClassName('');
      setIsValueValid(false);
      setValue('');
      return;
    }

    if (Number.isNaN(Number(value))) {
      setErrorMessage('Please enter a valid number');
      setClassName('is-invalid');
      setIsValueValid(false);
      return;
    }

    if (limit && Number(value) > limit) {
      setErrorMessage(`Max: ${prefix}${limit}`);
      setClassName('is-invalid');
      setIsValueValid(false);
      setValue(value);
      return;
    }

    if (Number(value) < min) {
      setErrorMessage(`Min: ${prefix}${min}`);
      setClassName('is-invalid');
      setIsValueValid(false);
      setValue(value);
      return;
    }

    setClassName('is-valid');
    setIsValueValid(true);
    setValue(value);
  };

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex w-100">
        <FormGroup className="w-100">
          {!noLabel && <label className="pb-1">{label}</label>}
          <div className="d-flex justify-content-center">
            <div className="w-100">
              <CurrencyInput
                className={`form-control ${className}`}
                value={value}
                onValueChange={handleOnValueChange}
                placeholder={placeholder}
                prefix={prefix}
                disabled={disabled}
                step={step}
                allowNegativeValue={false}
                min={min}
                max={limit}
                decimalsLimit={decimalsLimit}
                disableGroupSeparators={disableGroupSeparators}
                disableAbbreviations
                maxLength={maxLength}
              />
              <div className="invalid-feedback">{errorMessage}</div>
            </div>
          </div>
        </FormGroup>
      </div>
      {hasSubtitle && <small className="text-black-50">{subtitleText}</small>}
    </div>
  );
};

AmountSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default AmountSelector;
