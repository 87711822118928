import { BaseDropdown } from '@components/Atoms/BaseDropdown/BaseDropdown';
import { MenuItem } from '@headlessui/react';
import { AnchorProps } from 'node_modules/@headlessui/react/dist/internal/floating';
import React, { ForwardedRef, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SingleSelectDropdownProps<T extends { title: string }> = {
  options: T[];
  selectedOption: T | null;
  onChange: (option: T) => void;
  renderOption: (option: T) => ReactNode;
  renderButton: (open: boolean, selectedOption: T | null) => React.ReactElement;
  showFilter?: boolean;
  title?: string;
  filterPlaceholder?: string;
  anchorPosition?: AnchorProps;
  bottomButton?: ReactNode;
};

const MENU_ITEM_CLASS = `
  tw-text-sb-blue-grey-600 tw-flex tw-items-center 
  tw-gap-[10px] tw-rounded-md tw-border tw-border-solid tw-border-transparent
  tw-p-[10px] tw-text-[14px] hover:tw-cursor-pointer  hover:tw-bg-sb-blue-grey-50 hover:!tw-border-sb-blue-grey-300  tw-flex-grow tw-self-stretch`;

type SingleSelectDropdownComponent = (<T extends { title: string; isSpecialOption?: boolean }>(
  props: SingleSelectDropdownProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => React.ReactElement) & {
  displayName?: string;
};

export const SingleSelectDropdown: SingleSelectDropdownComponent = React.forwardRef(
  <T extends { title: string; isSpecialOption?: boolean }>(
    props: SingleSelectDropdownProps<T>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      options,
      selectedOption,
      onChange,
      renderOption,
      renderButton,
      showFilter = false,
      title,
      filterPlaceholder,
      anchorPosition,
      bottomButton,
    } = props;
    const { t } = useTranslation();
    const [internalSelectedOption, setInternalSelectedOption] = useState<T | null>(selectedOption);

    const handleSelect = useCallback(
      (option: T) => {
        setInternalSelectedOption(option);
        onChange(option);
      },
      [onChange],
    );

    return (
      <BaseDropdown
        ref={ref}
        showFilter={showFilter}
        anchorPosition={anchorPosition}
        children={(open) => renderButton(open, internalSelectedOption)}
        title={title}
        filterPlaceholder={filterPlaceholder}
        bottomButton={bottomButton}
        menuItems={(filterQuery) => {
          const filteredOptions = options.filter((option) =>
            option.title.toLowerCase().includes(filterQuery.toLowerCase()),
          );

          const regularOptions = filteredOptions.filter((option) => !option.isSpecialOption);
          const specialOptions = filteredOptions.filter((option) => option.isSpecialOption);

          if (filterQuery && regularOptions.length === 0 && specialOptions.length === 0) {
            return (
              <div className="tw-flex tw-h-11 tw-w-full tw-items-center tw-px-[6px] tw-py-[5px] tw-pl-4">
                <span>{t('simcard.noMatching', { item: filterPlaceholder })}</span>
              </div>
            );
          }
          return (
            <>
              <div className="tw-w-full tw-px-[6px] tw-py-[5px]">
                {regularOptions.map((option, index) => (
                  <MenuItem key={index} as="div" onClick={() => handleSelect(option)} className={MENU_ITEM_CLASS}>
                    {renderOption(option)}
                  </MenuItem>
                ))}
              </div>

              {specialOptions.length > 0 && (
                <div className="tw-w-full tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-sb-blue-grey-300" />
              )}

              {specialOptions.length > 0 && (
                <div className="tw-w-full tw-px-[6px] tw-py-[5px]">
                  {specialOptions.map((option, index) => (
                    <MenuItem key={index} as="div" onClick={() => handleSelect(option)} className={MENU_ITEM_CLASS}>
                      {renderOption(option)}
                    </MenuItem>
                  ))}
                </div>
              )}
            </>
          );
        }}
      />
    );
  },
) as SingleSelectDropdownComponent;

SingleSelectDropdown.displayName = 'SingleSelectDropdown';
