import { PageNavBar } from '@components/Molecules/PageNavBar/PageNavBar';
import { VIEW_PERMISSIONS } from '@core/constants';
import useIsProcessing from '@utils/hooks/useIsProcessing';
import { useSearchParams } from 'react-router-dom';
import { useEmailVerified } from 'src/hooks/useConfirmEmail';
import { checkPermission } from '../../../../lib/utils/permissions';
import { OverviewHeader } from '../../components';
import { useGetCustomerQuery, useGetSelfQuery, useGetSubscriptionsQuery, useGetUsersQuery } from '../account-api-slice';
import { Notifications, Organization, Profile, Subscriptions } from '../index';

const tabs = [
  {
    key: 'organization',
    label: 'Organization',
    permission: VIEW_PERMISSIONS.ORGANIZATION,
  },
  {
    key: 'subscriptions',
    label: 'Subscriptions',
  },
  {
    key: 'profile',
    label: 'Profile',
  },
  {
    key: 'notifications',
    label: 'Notifications',
  },
];

type AccountProps = {
  sidebarOpen: boolean;
};

export const Account = ({ sidebarOpen }: AccountProps) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const getCustomerResponse = useGetCustomerQuery();
  const { refetch: refetchCustomer } = getCustomerResponse;

  const getSubscriptionsResponse = useGetSubscriptionsQuery();

  const { name, email, isFetching: isFetchingEmail, updateEmailAndSendCode } = useEmailVerified();

  const { data: userResponseData } = useGetUsersQuery();

  const { data: userData } = useGetSelfQuery();

  const handleSelect = (tab) => {
    setSearchParams((prev) => {
      if (tab === tabs[0].key && !checkPermission(VIEW_PERMISSIONS.ORGANIZATION)) {
        prev.set('tab', tabs[2].key);
      } else {
        prev.set('tab', tab);
      }
      if (tab !== tabs[1].key) {
        prev.delete('subscription');
      }
      return prev;
    });
  };

  const tabParam = searchParams.get('tab') ? searchParams.get('tab') : tabs[0].key;

  const isProcessing = useIsProcessing([getCustomerResponse.isLoading, getCustomerResponse.isFetching]);

  return (
    <div className="d-flex justify-content-center h-100">
      <div className={`mx-auto tw-flex tw-w-full tw-items-center tw-justify-center tw-px-6`}>
        <div className="tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col">
          <OverviewHeader title="Account" sidebarOpen={sidebarOpen} />

          <PageNavBar tabs={tabs} onSelect={handleSelect} selected={tabParam} />

          <div className="py-4">
            {tabParam === tabs[0].key && (
              <Organization
                setTab={handleSelect}
                customer={getCustomerResponse?.data}
                customerIsLoading={isProcessing}
                refetchCustomer={refetchCustomer}
                userResponseData={userResponseData}
                preferredTimezone={userData?.preferred_timezone}
              />
            )}
            {tabParam === tabs[1].key && (
              <Subscriptions
                customer={getCustomerResponse?.data}
                subscriptionsResponseIsLoading={getSubscriptionsResponse.isLoading}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
              />
            )}
            {tabParam === tabs[2].key && (
              <Profile
                name={name}
                email={email}
                isFetchingEmail={isFetchingEmail}
                updateEmailAndSendCode={updateEmailAndSendCode}
              />
            )}
            {tabParam === tabs[3].key && <Notifications />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
