import { SIM_STATE } from '@core/constants';
import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { Simcard } from 'src/types/types';
import { useUpdateSimStatusMutation } from '../simcards-api-slice';
import { SimcardsListViewItem } from '../simcards-slice';

/**
 * Convenience hook to determine what simcards need to be updated and then update them.
 *
 * @returns {mutateSimStatus} - Function to update simcards
 * @returns {updateSimStatusResponse} - Response from the updateSimStatus mutation
 */

export const useMutateSimStatus = () => {
  const [updateSimStatus, updateSimStatusResponse] = useUpdateSimStatusMutation();

  /**
   * Determines which cards need to be updated and updates them
   * @param {simcards} - Simcards to be updated
   * @param {desiredState} - Desired state of the simcards
   * @returns {mutatedIccids} - Iccids of simcards that were updated
   * @returns {notMutatedIccids} - Iccids of simcards that were not updated
   */

  type MutateSimStatusProps = {
    simcards: Simcard[] | SimcardsListViewItem[];
    desiredState: SIM_STATE;
    t: TFunction<'translation', undefined>;
  };
  const mutateSimStatus = async ({ simcards, desiredState, t }: MutateSimStatusProps) => {
    let blockingStates = [];
    if (desiredState === SIM_STATE.ENABLED) {
      blockingStates = [SIM_STATE.ENABLED, SIM_STATE.ENABLING];
    } else {
      blockingStates = [SIM_STATE.DISABLED, SIM_STATE.DISABLING];
    }
    const mutatedIccids = simcards
      .filter((simcard) => !blockingStates.includes(simcard.sim_state))
      .map((simcard) => simcard.iccid);
    const notMutatedIccids = simcards
      .filter((simcard) => blockingStates.includes(simcard.sim_state))
      .map((simcard) => simcard.iccid);

    if (mutatedIccids.length > 0) {
      await toast.promise(updateSimStatus({ iccids: mutatedIccids, sim_state: desiredState }).unwrap(), {
        pending: {
          render() {
            return (
              <>
                <div>
                  {t('simcards.mutateSimsStatus.requestingStatusUpdate', {
                    numberOfSims: mutatedIccids.length,
                    simOrSims: mutatedIccids.length > 1 ? 'SIMs' : 'SIM',
                  })}
                </div>
                {notMutatedIccids.length > 0 && (
                  <>
                    <hr />
                    {t('simcards.mutateSimsStatus.alreadyInDesiredState', {
                      numberOfSims: notMutatedIccids.length,
                      simOrSims: notMutatedIccids.length > 1 ? 'SIMs are' : 'SIM is',
                    })}
                  </>
                )}
              </>
            );
          },
        },
        success: {
          render() {
            return (
              <>
                <div>
                  {t('simcards.mutateSimsStatus.successfulStatusUpdate', {
                    numberOfSims: mutatedIccids.length,
                    simOrSims: mutatedIccids.length > 1 ? 'SIMs' : 'SIM',
                  })}
                </div>
              </>
            );
          },
        },
        error: {
          render() {
            return (
              <>
                <div>
                  {t('simcards.mutateSimsStatus.failedStatusUpdate', {
                    numberOfSims: mutatedIccids.length,
                    simOrSims: mutatedIccids.length > 1 ? 'SIMs' : 'SIM',
                  })}
                </div>
              </>
            );
          },
        },
      });
    }
    return { mutatedIccids, notMutatedIccids };
  };

  return { mutateSimStatus, updateSimStatusResponse };
};
