import Loader from '@components/Loader';
import React from 'react';
import { Trans } from 'react-i18next';

type SimCounterProps = {
  simsCounterText: string;
  SimCardStateIsLoading: boolean;
};

export const SimCounter = React.memo(({ simsCounterText, SimCardStateIsLoading }: SimCounterProps) => {
  return (
    <div className="tw-flex tw-items-center tw-py-2">
      <span className="tw-text-2xl">
        <Trans>{simsCounterText}</Trans>
      </span>
      <span className="">{SimCardStateIsLoading && <Loader size={0.8} variant="primary" animation="grow" />}</span>
    </div>
  );
});
