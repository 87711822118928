import React from 'react';
import ListPageOptions from '../../../modules/dashboard/simCard/components/ListPageOptions';

type PaginationProps = {
  pageIndex: number;
  pageCount: number;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
};

export const DeprecatedSimpleTablePagination: React.FC<PaginationProps> = React.memo(
  ({ pageIndex, pageCount, gotoPage, previousPage, nextPage }) => (
    <div className="card-footer d-flex justify-content-between card-footer-width">
      <ul
        className="list-pagination-prev pagination pagination-tabs tw-mt-[-16px] !tw-border-none"
        onClick={previousPage}
      >
        <li className="page-item">
          <button className="page-link ps-0 pe-4 border-end d-flex !tw-border-none">
            <i className="fe fe-arrow-left me-1" /> Prev
          </button>
        </li>
      </ul>
      <ListPageOptions currentPageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} />
      <ul className="list-pagination-next pagination pagination-tabs card-pagination" onClick={nextPage}>
        <li className="page-item">
          <button className="page-link ps-4 pe-0 border-start d-flex">
            Next <i className="fe fe-arrow-right ms-1" />
          </button>
        </li>
      </ul>
    </div>
  ),
);
