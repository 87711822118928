import React from 'react';
import { Tag } from 'src/types/types';

const darkColors = [
  'light-purple',
  'blue-grey-600',
  'blue-700',
  'blue-400',
  'blue-300',
  'blue-200',
  'blue-100',
  'green-300',
  'green-500',
  'green-600',
  'orange-300',
  'orange-400',
  'orange-500',
  'orange-700',
];

const calculateClasses = (tag: string) =>
  `tw-px-2.5 tw-py-0.5 tw-rounded tw-border tw-border-solid tw-border-sb-${tag === 'blue-grey-100' ? 'blue-grey-300' : tag} tw-bg-sb-${tag} ${darkColors.includes(tag) && 'tw-text-white'} tw-inline-flex tw-gap-2.5 tw-items-center tw-text-xs`;
export const SimTagLabel = React.memo(({ tag, tags }: { tag: string; tags: Tag[] }) => {
  const selectedTag: Tag = tags.find((searchedTag) => searchedTag.tag === tag);
  const classes = calculateClasses(selectedTag?.color || 'blue-grey-100');

  return (
    <div className={classes}>
      <span>{tag}</span>
    </div>
  );
});
