import { AlternateModal } from '@components/Atoms/AlternateModal/AlternateModal';
import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import TransparentInput from '@components/Atoms/TrasparentInput/TransparentInput';
import {
  useAssignSimToNetworkMutation,
  useRemoveSimFromNetworkMutation,
} from '@modules/dashboard/network/network-api-slice';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SimcardNetwork } from 'src/types/types';

const NO_NAME = '[NO NAME]';

type Network = {
  network_id: string;
  network_name: string;
};

type NetworkOption = {
  title: string;
  networkId?: string;
};

type AssignToNetworkModalProps = {
  open: boolean;
  onClose: () => void;
  iccids: string[];
  networksData: SimcardNetwork[];
};

export const AssignToNetworkModal: React.FC<AssignToNetworkModalProps> = React.memo(
  ({ open, onClose, iccids, networksData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState<string>('');
    const [processing, setProcessing] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState<NetworkOption | null>(null);

    const networkDropdownOptions = useMemo(() => {
      const networkOptions = networksData.map((network: Network) => ({
        title: network.network_name || NO_NAME,
        networkId: network.network_id,
      }));

      return networkOptions;
    }, [networksData]);

    const [filteredNetworks, setFilteredNetworks] = useState(networkDropdownOptions);

    useEffect(() => {
      const filtered = networkDropdownOptions.filter((network) => network.title.includes(searchValue));
      setFilteredNetworks(filtered);
    }, [searchValue, iccids, networkDropdownOptions]);

    const [removeSimFromNetwork] = useRemoveSimFromNetworkMutation();
    const [assignSimToNetwork] = useAssignSimToNetworkMutation();

    const handleRemoveFromNetwork = useCallback(async () => {
      await toast.promise(removeSimFromNetwork({ iccids }).unwrap(), {
        pending: t('simcards.assignToNetwork.unassignPending', {
          simOrSims: iccids.length > 1 ? t('SIMs') : t('SIM'),
        }),
        success: t('simcard.simsSuccessfullyRemovedFromNetworks'),
        error: t('errorMessage.somethingWentWrongGetInTouch'),
      });
    }, [removeSimFromNetwork, iccids, t]);

    const handleAssignToNetwork = useCallback(
      async (networkId: string, networkName: string) => {
        await toast.promise(
          assignSimToNetwork({
            id: networkId,
            iccids,
          }).unwrap(),
          {
            pending: t('simcards.assignToNetwork.pending', {
              simOrSims: iccids.length > 1 ? t('SIMs') : t('SIM'),
            }),
            success: t('simcard.simsAssignedToXNetwork', { selectedNetwork: networkName }),
            error: t('errorMessage.somethingWentWrongGetInTouch'),
          },
        );
      },
      [assignSimToNetwork, iccids, t],
    );

    const handleAssignNetwork = useCallback(async () => {
      if (!selectedNetwork) return;

      setProcessing(true);
      try {
        if (selectedNetwork.title === t('simcards.assignToNetwork.noNetwork')) {
          await handleRemoveFromNetwork();
        } else {
          await handleAssignToNetwork(selectedNetwork.networkId, selectedNetwork.title);
        }
        onClose();
      } catch (error) {
        console.error('Error assigning/removing network:', error);
        toast.error(error.data?.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
      } finally {
        setProcessing(false);
      }
    }, [selectedNetwork, handleRemoveFromNetwork, handleAssignToNetwork, onClose, t]);

    const handleSelectNetwork = useCallback((option: NetworkOption) => {
      setSelectedNetwork((prev) => (prev?.title === option.title ? null : option));
    }, []);

    return (
      <AlternateModal
        modalOpen={open}
        closeModal={onClose}
        title={t('simcards.assignToPrivateNetwork')}
        modalSize="large"
        showClose
      >
        <div className="tw-flex tw-gap-6 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-p-6">
          <TransparentInput
            placeholder={t('simcards.searchPrivateNetwork')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            icon="search"
            className="tw-h-11 !tw-rounded-lg"
          />
          <Button
            variant="primaryLightV2"
            onClick={() => navigate('/dashboard/private-network/create?step=configure')}
            className="tw-gap-2.5 tw-whitespace-nowrap"
          >
            <Icon name="plus" className="" />
            {t('simcards.addPrivateNetwork')}
          </Button>
        </div>
        <div className="tw-relative tw-bg-white">
          <div className="tw-h-[40vh] tw-overflow-auto tw-bg-white">
            {!!filteredNetworks &&
              filteredNetworks.map((network) => {
                return (
                  <div key={network.title} className="tw-p-1">
                    <div
                      className={`tw-group tw-flex tw-h-14 tw-items-center tw-justify-between tw-rounded-md tw-border-0 tw-border-solid tw-border-sb-blue-grey-300 tw-px-6 hover:tw-bg-sb-blue-grey-50`}
                    >
                      <div className="tw-flex tw-items-center tw-gap-2.5">
                        <label className="tw-relative tw-flex tw-cursor-pointer tw-items-center tw-gap-2.5">
                          <input
                            type="radio"
                            name="separated-radio-buttons"
                            value={network.title}
                            checked={selectedNetwork?.title === network.title}
                            onChange={() => handleSelectNetwork(network)}
                            className="tw-absolute tw-z-10 tw-h-full tw-w-full tw-cursor-pointer tw-appearance-none tw-opacity-0"
                            aria-checked={selectedNetwork?.title === network.title}
                          />
                          <span
                            className={`tw-z-20 tw-flex tw-h-3 tw-w-3 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-solid ${
                              selectedNetwork?.title === network.title
                                ? 'tw-border-sb-blue-300 tw-bg-sb-blue-grey-100 hover:tw-shadow-input-selected-hover'
                                : 'tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 hover:tw-bg-white hover:tw-shadow-input-unselected-hover'
                            }`}
                          >
                            {selectedNetwork?.title === network.title && (
                              <span className="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-sb-blue-300"></span>
                            )}
                          </span>
                        </label>

                        <span className="tw-text-base">{network.title}</span>
                      </div>
                      <div className="tw-hidden tw-items-center tw-gap-6 group-hover:tw-flex">
                        <Button
                          variant="newUnderline"
                          size="small"
                          onClick={() => navigate(`/dashboard/private-network/${network.networkId}`)}
                          className="!tw-text-base"
                        >
                          <Icon name="gear_alternate" />
                          {t('Manage')}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="tw-border-0 tw-border-t tw-border-solid tw-border-sb-blue-grey-300 tw-p-1">
              <div
                className={`tw-group tw-flex tw-h-14 tw-items-center tw-justify-between tw-rounded-md tw-px-6 hover:tw-bg-sb-blue-grey-50`}
              >
                <div className="tw-flex tw-items-center tw-gap-2.5">
                  <div className="tw-relative">
                    <input
                      type="radio"
                      name="separated-radio-buttons"
                      value={t('simcards.assignToNetwork.noNetwork')}
                      checked={selectedNetwork?.title === t('simcards.assignToNetwork.noNetwork')}
                      onChange={() => handleSelectNetwork({ title: t('simcards.assignToNetwork.noNetwork') })}
                      className="tw-absolute tw-z-10 tw-h-full tw-w-full tw-cursor-pointer tw-appearance-none tw-opacity-0"
                      aria-checked={selectedNetwork?.title === t('simcards.assignToNetwork.noNetwork')}
                    />
                    <span
                      className={`tw-z-20 tw-flex tw-h-3 tw-w-3 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-solid ${
                        selectedNetwork?.title === t('simcards.assignToNetwork.noNetwork')
                          ? 'tw-border-sb-blue-300 tw-bg-sb-blue-grey-100 hover:tw-shadow-input-selected-hover'
                          : 'tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 hover:tw-bg-white hover:tw-shadow-input-unselected-hover'
                      }`}
                    >
                      {selectedNetwork?.title === t('simcards.assignToNetwork.noNetwork') && (
                        <span className="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-sb-blue-300"></span>
                      )}
                    </span>
                  </div>
                  <span className="tw-text-base">{t('simcards.assignToNetwork.noNetwork')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-justify-end tw-gap-2.5 tw-border-0 tw-border-t tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 tw-px-6 tw-py-3">
          <Button variant="outline" onClick={onClose} className="tw-capitalize">
            {t('cancel')}
          </Button>
          <Button onClick={() => handleAssignNetwork()} disabled={processing || !selectedNetwork}>
            {t('Save')}
          </Button>
        </div>
      </AlternateModal>
    );
  },
);
