import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { AnchorProps } from 'node_modules/@headlessui/react/dist/internal/floating';
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type BaseDropdownProps = {
  children: (open: boolean) => React.ReactElement;
  menuItems: (filterQuery: string) => ReactNode;
  //Use the values top, right, bottom, or left to center the dropdown along the appropriate edge,
  //or combine it with start or end to align the dropdown to a specific corner,
  //such as 'top start' or 'bottom end'.
  anchorPosition?: AnchorProps;
  title?: string;
  showFilter?: boolean;
  filterPlaceholder?: string;
  bottomButton?: ReactNode;
  keyFunction?: string;
};

const MENU_ITEMS_CLASS = `
  tw-border-sb-blue-grey-300 tw-flex tw-flex-col tw-items-start tw-justify-center 
  tw-gap-0 tw-border tw-border-solid tw-bg-white tw-py-0 tw-z-20 
`;

const getMenuItemsClassName = (title: string | undefined, showFilter: boolean, bottomButton: ReactNode | undefined) => `
  ${MENU_ITEMS_CLASS}
  ${title || showFilter ? 'tw-rounded-b-lg tw-border-t-0' : 'tw-rounded-lg'} 
  ${bottomButton ? 'tw-border-b-0 !tw-rounded-b-none tw-rounded-none' : ''}
`;

export const BaseDropdown = React.memo(
  forwardRef(
    (
      {
        children,
        menuItems,
        anchorPosition = 'bottom start',
        showFilter = false,
        title,
        filterPlaceholder,
        bottomButton,
        keyFunction,
      }: BaseDropdownProps,
      ref,
    ) => {
      const { t } = useTranslation();
      const [filterQuery, setFilterQuery] = useState('');

      const menuItemsClassName = useMemo(
        () => getMenuItemsClassName(title, showFilter, bottomButton),
        [title, showFilter, bottomButton],
      );

      return (
        <Menu>
          {({ open, close }) => {
            useImperativeHandle(ref, () => ({
              closeMenu: () => close(),
            }));

            useEffect(() => {
              if (!open) {
                setFilterQuery('');
              }
            }, [open]);
            return (
              <>
                <MenuButton as={React.Fragment}>{({ open: menuOpen }) => children(menuOpen)}</MenuButton>

                <div>
                  <MenuItems
                    anchor={anchorPosition}
                    className="tw-z-20 tw-shadow-dropdown-shadow [--anchor-gap:4px] focus:tw-outline-none"
                  >
                    {!!title && (
                      <div className="tw-flex tw-w-full tw-flex-grow tw-items-center tw-justify-between tw-rounded-t-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5 tw-pl-4">
                        <div>
                          <span className="tw-text-base tw-text-sb-blue-grey-600">{t(title)}</span>
                        </div>
                        {keyFunction && (
                          <div className="tw-flex tw-h-[25px] tw-w-[25px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300">
                            <span>{keyFunction}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {showFilter && (
                      <div className="tw-flex tw-w-full tw-flex-grow tw-items-center tw-rounded-t-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5 tw-pl-4">
                        <input
                          type="text"
                          value={filterQuery}
                          onChange={(e) => setFilterQuery(e.target.value)}
                          onKeyDown={(e) => e.stopPropagation()}
                          placeholder={t('dropdown.filterBy', { filter: filterPlaceholder })}
                          className="tw-flex-grow tw-border-none tw-p-0 tw-text-sb-blue-grey-600 placeholder:tw-text-sb-blue-grey-300 focus:tw-outline-none focus:tw-ring-0"
                        />
                        {keyFunction && (
                          <div className="tw-flex tw-h-[25px] tw-w-[25px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300">
                            <span>{keyFunction}</span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={menuItemsClassName}>{menuItems(filterQuery)}</div>
                    {!!bottomButton && bottomButton}
                  </MenuItems>
                </div>
              </>
            );
          }}
        </Menu>
      );
    },
  ),
);
