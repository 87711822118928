import { useAppDispatch, useAppSelector } from '@core/rtk-hooks';
import { fetchCostsThunk } from '@modules/dashboard/billing/costs-slice';
import { useEffect } from 'react';

export const useCosts = (month: string) => {
  const dispatch = useAppDispatch();
  const costsState = useAppSelector((state) => state.costsState);
  const costs = useAppSelector((state) => state.costsState.costs);

  useEffect(() => {
    dispatch(fetchCostsThunk(month));
  }, [month, dispatch]);

  return {
    refetch: () => dispatch(fetchCostsThunk(month)),
    costs,
    isLoading: costsState.isLoading,
    isFetching: costsState.isFetching,
    isFulfilled: costsState.isFulfilled,
    status: costsState.status,
    error: costsState.error,
  };
};
