import { Icon } from '@components/Atoms/Icon/Icon';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { AnchorProps } from 'node_modules/@headlessui/react/dist/internal/floating';
import { FC, ReactNode, useCallback, useState } from 'react';

type SidebarDropdownProps = {
  children: (open: boolean) => ReactNode;
  menuItems: { title: string; onClick: () => void; icon?: 'account_settings' | 'log_out' }[];
  anchorPosition?: AnchorProps;
};

const FILLED_CLASSES = 'tw-bg-sb-blue-grey-25 !tw-border-sb-blue-grey-300';
const MENU_ITEMS_CLASS = `
  tw-border-sb-blue-grey-300 tw-flex tw-flex-col tw-items-start tw-justify-center 
  tw-gap-[5px] tw-rounded-lg tw-border tw-border-solid tw-bg-white tw-px-[6px] 
  tw-py-[5px] tw-shadow-[0_0_15px_-5px_#46719C5C] [--anchor-gap:10px] focus:tw-outline-none
  tw-z-40
`;
const MENU_ITEM_CLASS = `
  tw-text-sb-blue-grey-600 tw-flex tw-h-[45px] tw-w-[168px] tw-items-center 
  tw-gap-[10px] tw-rounded-md tw-border tw-border-solid tw-border-transparent 
  tw-p-[10px] tw-text-[14px] hover:tw-cursor-pointer 
`;

export const SidebarDropdown: FC<SidebarDropdownProps> = ({ children, menuItems, anchorPosition = 'bottom' }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [pressedIndex, setPressedIndex] = useState<number | null>(null);

  const handleMouseEnter = useCallback((index: number) => setHoveredIndex(index), []);
  const handleMouseLeave = useCallback(() => setHoveredIndex(null), []);
  const handleMouseDown = useCallback((index: number) => setPressedIndex(index), []);
  const handleMouseUp = useCallback(() => setPressedIndex(null), []);

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton as="div">{children(open)}</MenuButton>
          <MenuItems anchor={anchorPosition} className={MENU_ITEMS_CLASS}>
            {menuItems.map(({ title, icon, onClick }, index) => (
              <MenuItem
                key={index}
                as="div"
                className={`${MENU_ITEM_CLASS} ${hoveredIndex === index ? FILLED_CLASSES : ''}`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onMouseDown={() => handleMouseDown(index)}
                onMouseUp={handleMouseUp}
                onClick={onClick}
              >
                {icon && <Icon name={pressedIndex === index ? `${icon}_pressed` : icon} />}
                {title}
              </MenuItem>
            ))}
          </MenuItems>
        </>
      )}
    </Menu>
  );
};
