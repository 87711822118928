import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { MenuItemType } from 'src/types/types';

type SimcardActionBarProps = {
  options: MenuItemType[];
  width: number;
  isSticky: boolean;
};

export const SimcardActionBar = ({ options, width, isSticky }: SimcardActionBarProps) => {
  return (
    <div
      className={`tw-flex tw-items-stretch tw-justify-around tw-gap-3 tw-rounded-lg tw-border tw-border-solid tw-bg-white tw-p-3 ${isSticky ? 'tw-shadow-action-bar tw-rounded-t-none tw-border tw-border-t-0 tw-border-sb-blue-500' : 'tw-border-sb-blue-grey-300'}`}
    >
      {options.map((option) => (
        <div className="tw-flex tw-flex-grow tw-flex-col tw-self-stretch">
          <Button
            onClick={option.handleClick}
            variant="primaryLightV2"
            size="small"
            className={`hover:tw-shadow-action-bar-button tw-flex tw-flex-grow tw-justify-center tw-gap-2.5 tw-border tw-bg-sb-blue-grey-50 disabled:hover:tw-border-transparent disabled:hover:tw-shadow-none disabled:active:tw-font-normal disabled:active:!tw-text-sb-blue-grey-500 ${width < 1000 ? 'tw-text-sm' : '!tw-text-base'} active:tw-shadow-action-bar-button tw-text-sb-blue-500 hover:tw-border hover:tw-border-solid hover:tw-border-sb-blue-grey-300 hover:tw-bg-white active:tw-border active:tw-border-solid active:tw-border-sb-blue-grey-300 active:tw-bg-white active:tw-font-semibold active:!tw-text-sb-blue-500`}
            disabled={option.disabled}
          >
            {option.icon && (
              <div className="tw-flex tw-h-[13px] tw-w-[13px] tw-items-center">
                <Icon name={option.icon} />
              </div>
            )}
            {option.title}
          </Button>
        </div>
      ))}
    </div>
  );
};
