import Loader from '@components/Loader';
import DeprecatedSimpleTable from '../../../../components/Organisms/SimpleTable/DeprecatedSimpleTable';
import { useGetVasDetailsQuery } from '../billing-api-slice';

const columns = [
  {
    Header: 'ID',
    accessor: 'vas_id',
  },
  {
    Header: 'TYPE',
    accessor: 'type',
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'product',
  },
  {
    Header: 'COST',
    accessor: 'cost',
  },
];

const SubscriptionDetails = ({ month, id, handleGoBack }) => {
  const getVasDetailsResponse = useGetVasDetailsQuery({ id, params: { month } });
  const vasDetails = getVasDetailsResponse.data ?? {};

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <i onClick={handleGoBack} className="fe fe-arrow-left fs-2 fw-400 cursor-pointer" />
        <div onClick={handleGoBack} className="mt-1 ms-1 cursor-pointer text-capitalize">
          Back to Subscriptions
        </div>
      </div>
      <div>
        {!getVasDetailsResponse.isSuccess ? (
          <Loader />
        ) : (
          <DeprecatedSimpleTable
            title="Billed items"
            columns={columns}
            data={vasDetails?.items || []}
            processing={getVasDetailsResponse.isFetching}
            hasPagination={false}
            hasPerPage={false}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionDetails;
