import { Icon } from '@components/Atoms/Icon/Icon';
import { LoadingPlaceholder } from '@components/Atoms/LoadingPlaceholder/LoadingPlaceholder';
import { TAG_COLORS } from '@core/constants/simcards_constants';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemType } from 'src/types/types';
import { MultiSelectDropdown } from '../MultiSelectDropdown/MultiSelectDropdown';

type TagFilterProps = {
  mappedTags: MenuItemType[];
  selectedTags: MenuItemType[];
  handleTagChange: (options: MenuItemType[], option: MenuItemType) => void;
  buttonButtonFunction: () => void;
  filtersApplied: boolean;
  isLoading?: boolean;
};
const TagFilterComponent = forwardRef(
  (
    {
      mappedTags,
      selectedTags,
      handleTagChange,
      buttonButtonFunction,
      filtersApplied,
      isLoading = false,
    }: TagFilterProps,
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <div className="tw-flex tw-flex-col">
        <span className="tw-py-2.5 tw-text-xs tw-font-bold tw-text-sb-blue-400">{t('tags').toUpperCase()}</span>
        {isLoading ? (
          <LoadingPlaceholder className="tw-flex tw-h-[44.5px] tw-w-[127px] tw-items-center tw-justify-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 !tw-bg-white tw-p-3">
            <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
            <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-slate-200"></div>
          </LoadingPlaceholder>
        ) : (
          <MultiSelectDropdown<MenuItemType>
            options={mappedTags}
            selectedOptions={selectedTags}
            onChange={handleTagChange}
            ref={ref}
            renderOption={(option) => {
              return (
                <div className={`tag-item tw-flex tw-w-full tw-items-center tw-gap-2.5`}>
                  <input
                    type="checkbox"
                    checked={selectedTags.some((o) => o.title === option.title)}
                    readOnly
                    className="tw-form-checkbox tw-h-5 tw-w-5 tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-50"
                  />
                  {option.tagColor && (
                    <div className="tw- tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-justify-center">
                      <Icon
                        name={option.tagColor === 'without_tags' ? 'without_tags' : 'tag'}
                        strokeColor={option.tagColor !== 'without_tags' && TAG_COLORS[option.tagColor]?.hexCode}
                        height={20}
                        width={14}
                      />
                    </div>
                  )}
                  <div className="tw-w-full tw-flex-grow">
                    <span>{option.title}</span>
                  </div>
                </div>
              );
            }}
            renderButton={(open) =>
              selectedTags.length ? (
                selectedTags.length === 1 ? (
                  <div
                    className={`tw-flex tw-items-center tw-justify-between tw-gap-2.5 ${filtersApplied ? 'md:!tw-min-w-[127px]' : 'sm:!tw-min-w-[127px]'} tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5`}
                    role="button"
                  >
                    <div className="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden">
                      {selectedTags[0].tagColor && (
                        <div className="tw- tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-justify-center">
                          <Icon
                            name={selectedTags[0].tagColor === 'without_tags' ? 'without_tags' : 'tag'}
                            strokeColor={
                              selectedTags[0].tagColor !== 'without_tags' &&
                              TAG_COLORS[selectedTags[0].tagColor]?.hexCode
                            }
                            height={20}
                            width={14}
                          />
                        </div>
                      )}
                      <span className="tw-max-w-[90%] tw-truncate tw-whitespace-nowrap tw-text-sb-blue-grey-600">
                        {selectedTags[0].title}
                      </span>
                    </div>
                    <div className="tw-flex tw-h-1.5 tw-w-1.5 tw-items-center tw-justify-center">
                      <Icon name={open ? 'chevron_up' : 'chevron_down'} width={6} height={4} />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`tw-flex tw-h-[44.5px] tw-w-full tw-items-center ${filtersApplied ? 'md:!tw-min-w-[127px]' : 'sm:!tw-min-w-[127px]'} tw-justify-between tw-gap-2.5 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5`}
                    role="button"
                  >
                    <div className="tw-flex tw-min-w-0 tw-flex-grow tw-items-center tw-gap-1.5">
                      <div className="tw-flex tw-h-6 tw-w-6 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25">
                        <span>{selectedTags.length}</span>
                      </div>
                      <div className="tw-flex tw-overflow-hidden">
                        {selectedTags.map((item, index) => (
                          <div key={index} className="tw--ml-2 tw-flex tw-flex-shrink-0 tw-items-center first:tw-ml-0">
                            <Icon
                              name={item.tagColor === 'without_tags' ? 'without_tags' : 'tag_with_white_border'}
                              strokeColor={item.tagColor !== 'without_tags' && TAG_COLORS[item.tagColor]?.hexCode}
                              height={20}
                              width={14}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="tw-flex tw-h-1.5 tw-w-1.5 tw-items-center tw-justify-center">
                      <Icon name={open ? 'chevron_up' : 'chevron_down'} width={6} height={4} />
                    </div>
                  </div>
                )
              ) : (
                <div
                  className="tw-flex tw-items-center tw-justify-between tw-gap-2.5 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5"
                  role="button"
                >
                  <span className="tw-max-w-[90%] tw-truncate tw-whitespace-nowrap tw-text-sb-blue-grey-600">
                    {t('simcard.filters.filterByTags')}
                  </span>
                  <div className="tw-flex tw-h-1.5 tw-w-1.5 tw-items-center tw-justify-center">
                    <Icon name={open ? 'chevron_up' : 'chevron_down'} width={6} height={4} />
                  </div>
                </div>
              )
            }
            showFilter={true}
            filterPlaceholder={t('tags')}
            bottomButton={
              <div onClick={buttonButtonFunction}>
                <div
                  className="tw-flex tw-items-center tw-gap-2.5 tw-rounded-b-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-50 tw-p-2.5 tw-pl-4"
                  role="button"
                >
                  <div className="tw-flex tw-items-center tw-justify-center">
                    <Icon name="gear" />
                  </div>
                  <span className="tw-whitespace-nowrap tw-text-sb-blue-grey-600">
                    {t('simcard.filters.manageTags')}
                  </span>
                </div>
              </div>
            }
          />
        )}
      </div>
    );
  },
);

export const TagFilter = React.memo(TagFilterComponent);
