import { FilterFn } from '@tanstack/react-table';
import { Simcard } from 'src/types/types';

export const customTagsFilter: FilterFn<Simcard> = (row, columnId, filterValue) => {
  const tags = row.getValue(columnId) as string[] | undefined;

  // Handle the 'Devices with no tags' case
  if (Array.isArray(filterValue) && filterValue.includes('Devices with no tags')) {
    if (!tags || tags.length === 0) {
      return true; // Include rows with no tags
    }
    if (filterValue.length === 1) {
      return false; // Exclude rows with tags if 'Devices with no tags' is the only filter value
    }
  }

  // Exclude rows with no tags if we're filtering for specific tags
  if (!tags || tags.length === 0) {
    return false;
  }

  // Exclude 'Devices with no tags' from filterValue
  const filterTags = Array.isArray(filterValue) ? filterValue.filter((val) => val !== 'Devices with no tags') : [];

  // Check that all filterTags are included in the row's tags
  return filterTags.every((val) => tags.includes(val));
};

export const customNetworkFilter: FilterFn<Simcard> = (row, columnId, filterValue) => {
  const networks = row.getValue(columnId) as string | undefined;

  if (filterValue.includes('Without network')) {
    if (!networks) {
      return true;
    } else {
      return false;
    }
  } else {
    return networks === filterValue;
  }
};

/*
  This function is used to filter the data based on the coverage column
  This is how it works:
  - React table scans all the rows for every filter, if you have two active filters both are run, one after another.
  - If one row is returned true by the first filter but false by the second one, it will be considered false. 
  - With this logic, if the row has been found to match the first filter, it will skip the second check, making the filter inclusive. 
*/
export const customCoverageFilter: FilterFn<Simcard> = (row, columnId, filterValue) => {
  const coverage = row.getValue(columnId) as string | undefined;

  if (!coverage) {
    return false;
  }

  if (Array.isArray(filterValue)) {
    // OR logic: include row if coverage matches any selected value
    return filterValue.includes(coverage);
  } else {
    // Handle case where filterValue is a single string
    return coverage === filterValue;
  }
};
