import { IconName } from '@assets/img/icons/icons';
import { Input } from '@headlessui/react';
import { forwardRef } from 'react';
import { Icon } from '../Icon/Icon';

type TransparentInputProps = {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: IconName;
  className?: string;
  buttonText?: string;
  buttonFunction?: () => void;
};

const TransparentInput = forwardRef<HTMLInputElement, TransparentInputProps>(
  ({ placeholder, value, onChange, icon, className = '', buttonText, buttonFunction }, ref) => {
    return (
      <div className="tw-relative tw-w-full">
        <Input
          type="text"
          className={`tw-w-full tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-py-2 tw-pl-3 tw-pr-10 tw-text-sm tw-text-gray-600 tw-placeholder-gray-400 tw-outline-none focus:tw-border-gray-300 ${className}`}
          placeholder={placeholder}
          aria-label={placeholder}
          value={value}
          onChange={onChange}
          ref={ref}
        />
        {icon && (
          <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-2 tw-top-2 tw-flex tw-h-[28px] tw-w-[28px] tw-items-center tw-justify-center tw-rounded-[4px] tw-bg-sb-blue-grey-50">
            <Icon name={icon} />
          </div>
        )}
        {buttonText && !icon && (
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-justify-center tw-pr-3">
            <div
              className="tw-cursor-pointer tw-text-sb-blue-300 tw-underline tw-underline-offset-4"
              onClick={buttonFunction}
            >
              {buttonText}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default TransparentInput;
