import { Popover, PopoverButton, PopoverOverlay, PopoverPanel } from '@headlessui/react';
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModalDropdownProps = {
  children: (open: boolean) => React.ReactElement;
  menuItems: (filterQuery: string) => ReactNode;
  title?: string;
  showFilter?: boolean;
  filterPlaceholder?: string;
  bottomButton?: ReactNode;
  keyFunction?: string;
};

export const ModalDropdown = React.memo(
  forwardRef(
    (
      {
        children,
        menuItems,
        showFilter = false,
        title,
        filterPlaceholder,
        bottomButton,
        keyFunction,
      }: ModalDropdownProps,
      ref,
    ) => {
      const { t } = useTranslation();
      const [filterQuery, setFilterQuery] = useState('');

      return (
        <Popover className="tw-relative">
          {({ open, close }) => {
            useImperativeHandle(ref, () => ({
              closeMenu: () => close(),
            }));

            useEffect(() => {
              if (!open) {
                setFilterQuery('');
              }
            }, [open]);
            return (
              <>
                <PopoverButton as={React.Fragment}>{children(open)}</PopoverButton>
                <PopoverOverlay className="tw-fixed tw-inset-0 tw-z-10" />
                <PopoverPanel className="tw-absolute tw-z-20 tw-mt-2 tw-shadow-dropdown-shadow focus:tw-outline-none">
                  {!!title && (
                    <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-t-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5 tw-pl-4">
                      <div>
                        <span className="tw-text-base tw-text-sb-blue-grey-600">{t(title)}</span>
                      </div>
                      {keyFunction && (
                        <div className="tw-flex tw-h-[25px] tw-w-[25px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300">
                          <span>{keyFunction}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {showFilter && (
                    <div className="tw-flex tw-w-full tw-items-center tw-rounded-t-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5 tw-pl-4">
                      <input
                        type="text"
                        value={filterQuery}
                        onChange={(e) => setFilterQuery(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                        placeholder={t('dropdown.filterBy', { filter: filterPlaceholder })}
                        className="tw-flex-grow tw-border-none tw-p-0 tw-text-sb-blue-grey-600 placeholder:tw-text-sb-blue-grey-300 focus:tw-outline-none focus:tw-ring-0"
                      />
                      {keyFunction && (
                        <div className="tw-flex tw-h-[25px] tw-w-[25px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-sb-blue-grey-300">
                          <span>{keyFunction}</span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="tw-z-20 tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-0 tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-py-0">
                    {menuItems(filterQuery)}
                  </div>
                  {!!bottomButton && bottomButton}
                </PopoverPanel>
              </>
            );
          }}
        </Popover>
      );
    },
  ),
);
