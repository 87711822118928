import { IndeterminateCheckbox } from '@components/Atoms/IndeterminateCheckbox/IndeterminateCheckbox';
import { SimTagLabel } from '@components/Atoms/SimTagLabel/SimTagLabel';
import { StateCells } from '@components/Atoms/StateCells/StateCells';
import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { CustomColumnDef, Tag } from 'src/types/types';
import { SimcardsListViewItem } from '../../simcards-slice';
import { customCoverageFilter, customNetworkFilter, customTagsFilter } from './filters';

const columnHelper = createColumnHelper<SimcardsListViewItem>();

export const columns = ({
  t,
  tags,
}: {
  t: TFunction<'translation', undefined>;
  tags: Tag[];
}): CustomColumnDef<SimcardsListViewItem>[] => {
  return [
    columnHelper.accessor('iccid', {
      id: 'iccid',
      header: ({ table }) => (
        <div className="tw-flex tw-gap-3 tw-pl-2">
          <IndeterminateCheckbox
            data-test-id="simcards-table:select-all-simcards-checkbox"
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
          <span>{t('ICCID')}</span>
        </div>
      ),
      cell: (info) => (
        <div className="tw-flex tw-gap-3 tw-pl-2">
          <IndeterminateCheckbox
            data-test-id={`simcards-table:select-simcard-checkbox-${info.row.id}`}
            {...{
              checked: info.row.getIsSelected(),
              disabled: !info.row.getCanSelect(),
              indeterminate: info.row.getIsSomeSelected(),
              onChange: info.row.getToggleSelectedHandler(),
            }}
          />
          <span>{info.getValue()}</span>
        </div>
      ),
      sortingFn: 'text',
    }),
    columnHelper.accessor('sim_state', {
      id: 'sim_state',
      header: t('state').toUpperCase(),
      cell: ({ row }) => <StateCells simState={row.original.sim_state} />,
    }),
    columnHelper.accessor('device_name', {
      id: 'device_name',
      header: t('generic.name').toUpperCase(),
      cell: (info) => (
        <div className="tw-h-full tw-max-w-48 tw-truncate">
          <span className="tw-w-full">{info.getValue()}</span>
        </div>
      ),
    }),

    columnHelper.accessor((simcard) => simcard.coverage, {
      id: 'coverage',
      header: t('coverage').toUpperCase(),
      filterFn: customCoverageFilter,
      cell: ({ row }) => <span>{row.original.coverage ?? 'Custom'}</span>,
    }),
    columnHelper.accessor((simcard) => simcard.ip_address, {
      id: 'ip_address',
      header: t('ipAddress').toUpperCase(),
    }),
    columnHelper.accessor('usage_data', {
      id: 'usage_data',
      header: t('simcard.dataUsage').toUpperCase(),
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor('network_id', {
      id: 'network_id',
      filterFn: customNetworkFilter,
      header: t('simcard.networkId').toUpperCase(),
    }),
    columnHelper.accessor((row) => row.sms_sent + row.sms_received, {
      id: 'sms',
      header: t('simcard.smsUsage').toUpperCase(),
      cell: ({ row }) => {
        const { sms_sent, sms_received } = row.original;
        return (
          <span className="tw-capitalize">
            <i className="fe fe-arrow-up" />
            <span>{sms_sent || 0}</span>
            &nbsp;
            <i className="fe fe-arrow-down" />
            <span>{sms_received || 0}</span>
          </span>
        );
      },
      sortingFn: (rowA, rowB) => {
        const valueA = rowA.original.sms_sent + rowA.original.sms_received;
        const valueB = rowB.original.sms_sent + rowB.original.sms_received;

        // Handle NaN values
        if (isNaN(valueA) && isNaN(valueB)) return 0;
        if (isNaN(valueA)) return -1;
        if (isNaN(valueB)) return 1;

        return valueA - valueB;
      },
    }),
    columnHelper.accessor((simcard) => simcard.tags, {
      id: 'tags',
      header: t('tags').toUpperCase(),
      filterFn: customTagsFilter,
      cell: ({ row }) => (
        <div className="tw-flex tw-h-full tw-flex-wrap tw-gap-1" style={{ maxWidth: '120px' }}>
          {row.original.tags
            ?.filter((tag) => tags.some((currentTag) => currentTag.tag === tag))
            .map((tag) => <SimTagLabel key={tag} tag={tag} tags={tags} />)}
        </div>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      cell: () => <span className="tw-w-0"></span>,
    }),
  ];
};
