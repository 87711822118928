import Loader from '@components/Loader';
import { GenericDropdown } from '@components/Molecules/GenericDropdown/GenericDropdown';
import { flexRender, Row } from '@tanstack/react-table';
import React from 'react';
import { IActionItem } from 'src/types/types';

type SimpleTableBodyProps<TData extends object> = {
  rows: Row<TData>[];
  hasActions: boolean;
  actionItems?: IActionItem[];
  executingActionRow?: number | null;
  onRowClick?: (row: Row<TData>) => void;
};

export const SimpleTableBody = <TData extends object>({
  rows,
  hasActions,
  actionItems,
  executingActionRow,
  onRowClick,
}: SimpleTableBodyProps<TData>) => (
  <tbody>
    {rows.map((row, rowIndex) => {
      const isLastRow = rowIndex === rows.length - 1;
      return (
        <tr
          key={row.id}
          className={`tw-h-full ${!isLastRow && 'tw-border-b tw-border-gray-200'} hover:tw-bg-gray-100 ${rowIndex % 2 === 0 ? 'tw-bg-sb-blue-grey-25' : 'tw-bg-white'}`}
          onClick={
            onRowClick
              ? (e: React.MouseEvent<HTMLTableRowElement>) => {
                  const target = e.target as Element;
                  // Prevent row click if clicking on the actions column
                  if (!hasActions) {
                    onRowClick(row);
                  } else if (!target.closest('td:last-child')) {
                    onRowClick(row);
                  }
                }
              : undefined
          }
        >
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className={`${cell.column.id !== 'select' && 'tw-cursor-pointer'} tw-p-4 tw-text-sm`}>
              {flexRender(cell.column.columnDef.cell, { ...cell.getContext() })}
            </td>
          ))}
          {hasActions && (
            <td className="tw-text-right" key={`${row.id}-actions-column`}>
              {executingActionRow !== undefined && rowIndex === executingActionRow ? (
                <Loader size={1} animation="grow" className="tw-justify-end" />
              ) : (
                <GenericDropdown menuItems={actionItems} contextData={row} anchorPosition="bottom end">
                  {(_) => (
                    <div className="tw-whitespace-nowrap tw-text-lg tw-text-sb-blue-grey-400" role="button">
                      <i className="fe fe-more-vertical" />
                    </div>
                  )}
                </GenericDropdown>
              )}
            </td>
          )}
        </tr>
      );
    })}
  </tbody>
);
