import { Icon } from '@components/Atoms/Icon/Icon';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MenuItemType } from 'src/types/types';
import { SingleSelectDropdown } from '../SingleSelectDropdown/SingleSelectDropdown';

type NetworkFilterProps = {
  networkItems: MenuItemType[];
  selectedNetwork: MenuItemType;
  handleNetworkChange: (option: MenuItemType) => void;
};

export const NetworkFilterComponent = forwardRef<HTMLDivElement, NetworkFilterProps>(
  ({ networkItems, selectedNetwork, handleNetworkChange }: NetworkFilterProps, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
      <div className="tw-flex tw-flex-col">
        <span className="tw-py-2.5 tw-text-xs tw-font-bold tw-text-sb-blue-400">{t('Network').toUpperCase()}</span>
        <SingleSelectDropdown<MenuItemType>
          ref={ref}
          options={networkItems}
          selectedOption={selectedNetwork}
          onChange={handleNetworkChange}
          showFilter
          filterPlaceholder={t('Networks')}
          renderOption={(option) => (
            <div className={`tw-flex tw-w-[156px] tw-items-center tw-gap-2.5`}>
              <label className="tw-relative tw-flex tw-cursor-pointer tw-items-center tw-gap-2.5">
                <input
                  type="radio"
                  name="separated-radio-buttons"
                  value={option.title}
                  checked={selectedNetwork?.title === option.title}
                  className="tw-absolute tw-z-10 tw-h-full tw-w-full tw-cursor-pointer tw-appearance-none tw-opacity-0"
                  aria-checked={selectedNetwork?.title === option.title}
                />
                <span
                  className={`tw-z-20 tw-flex tw-h-3 tw-w-3 tw-items-center tw-justify-center tw-rounded-full ${
                    selectedNetwork?.title === option.title
                      ? 'tw-bg-sb-blue-grey-100 hover:tw-shadow-input-selected-hover'
                      : 'tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 hover:tw-bg-white hover:tw-shadow-input-unselected-hover'
                  }`}
                >
                  {selectedNetwork?.title === option.title && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M10.8182 7C10.8182 9.10872 9.10872 10.8182 7 10.8182C4.89128 10.8182 3.18182 9.10872 3.18182 7C3.18182 4.89128 4.89128 3.18182 7 3.18182C9.10872 3.18182 10.8182 4.89128 10.8182 7Z"
                        fill="#2467CA"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                        fill="#2467CA"
                      />
                    </svg>
                  )}
                </span>
              </label>
              {option.icon && (
                <div className="tw-flex tw-w-6 tw-flex-shrink-0 tw-justify-center">
                  <Icon name={option.icon} />
                </div>
              )}
              <div className="tw-w-full tw-flex-grow">
                <span className="">{option.title}</span>
              </div>
            </div>
          )}
          renderButton={(open) => {
            return (
              <div
                className="tw-flex tw-h-[44.5px] tw-items-center tw-justify-between tw-gap-2.5 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-2.5"
                role="button"
              >
                <span className="tw-max-w-[90%] tw-truncate tw-whitespace-nowrap tw-text-sb-blue-grey-600">
                  {selectedNetwork ? selectedNetwork?.title : t('all')}
                </span>
                <div className="tw-flex tw-h-1.5 tw-w-1.5 tw-items-center tw-justify-center">
                  <Icon name={open ? 'chevron_up' : 'chevron_down'} width={6} height={4} />
                </div>
              </div>
            );
          }}
          bottomButton={
            <div onClick={() => navigate('/dashboard/private-network')}>
              <div
                className="tw-flex tw-items-center tw-gap-2.5 tw-rounded-b-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-50 tw-p-2.5 tw-pl-4"
                role="button"
              >
                <div className="tw-flex tw-items-center tw-justify-center">
                  <Icon name="gear" />
                </div>
                <span className="tw-whitespace-nowrap tw-text-sb-blue-grey-600">
                  {t('simcard.filters.manageNetworks')}
                </span>
              </div>
            </div>
          }
        />
      </div>
    );
  },
);

export const NetworkFilter = React.memo(NetworkFilterComponent);
