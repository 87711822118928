import { PageNavBar } from '@components/Molecules/PageNavBar/PageNavBar';
import { Api } from '@components/Organisms/Api/Api';
import { useSearchParams } from 'react-router-dom';
import { OverviewHeader } from '../../components';
// import { PERMISSIONS } from '../../../../core/constants';
import { Webhooks } from '../index';

const tabs = [
  { key: 'api', label: 'API' }, //permission: PERMISSIONS.VIEW_ACCOUNT_API
  {
    key: 'webhooks',
    label: 'Webhooks',
  },
];

type ItergrationsProps = {
  sidebarOpen: boolean;
};
const Integrations = ({ sidebarOpen }: ItergrationsProps) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const tab = searchParams.get('tab') || null;

  const handleSelect = (tab: string) => {
    setSearchParams((prev) => {
      prev.set('tab', tab);

      return prev;
    });
  };

  const tabParam = searchParams.get('tab') ? searchParams.get('tab') : tabs[0].key;

  return (
    <div className="tw-flex tw-w-full tw-justify-center tw-px-6">
      <div className={`tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col`}>
        <OverviewHeader title="Integrations" sidebarOpen={sidebarOpen} />
        <div>
          <PageNavBar tabs={tabs} onSelect={handleSelect} selected={tab} />
        </div>
        <div className="tw-h-full tw-py-6">
          {tabParam === tabs[0].key && <Api />}
          {tabParam === tabs[1].key && <Webhooks />}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
