import { IconName } from '@assets/img/icons/icons';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from '../Icon/Icon';

type AlternateModalProps = {
  modalOpen: boolean;
  closeModal: () => void;
  title: string;
  titleIcon?: IconName;
  children?: ReactNode;
  showClose?: boolean;
  modalSize?: 'small' | 'medium' | 'large';
  className?: string;
};

const MaxWidth = {
  small: 'tw-max-w-[389px]',
  medium: `tw-max-w-md`,
  large: `tw-max-w-[620px]`,
};

export const AlternateModal: FC<AlternateModalProps> = ({
  title,
  titleIcon,
  modalOpen,
  closeModal,
  children,
  showClose = false,
  modalSize = 'medium',
  className,
}) => {
  const modalContent = (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog as="div" className="tw-fixed tw-inset-0 tw-z-40 tw-overflow-y-auto" onClose={closeModal}>
        <div
          className="tw-fixed tw-inset-0 tw-overflow-visible tw-bg-black/50 tw-backdrop-blur-sm"
          aria-hidden="true"
        />
        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
          <TransitionChild
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <DialogPanel
              className={`tw-relative tw-w-full tw-overflow-hidden tw-rounded-xl ${MaxWidth[modalSize]} ${className}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={`tw-flex tw-items-center tw-justify-between tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 tw-p-5 tw-pl-6`}
              >
                <DialogTitle
                  as="div"
                  className="tw-flex tw-items-center tw-gap-1.5 tw-text-center tw-text-base tw-font-semibold tw-text-sb-blue-500"
                >
                  {!!titleIcon && <Icon name={titleIcon} />}
                  {title}
                </DialogTitle>
                {showClose && (
                  <div onClick={closeModal} className="tw-flex tw-cursor-pointer tw-items-center">
                    <Icon name="close" />
                  </div>
                )}
              </div>
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
  return createPortal(modalContent, document.body);
};
