import {
  useAddTagToSimsMutation,
  useGetTagsQuery,
  useRemoveTagToSimsMutation,
} from '@modules/dashboard/simCard/simcards-api-slice';

export const useTags = () => {
  const getTagsResponse = useGetTagsQuery();
  const tags = getTagsResponse?.data?.tags.map((tag) => tag.tag) || [];

  const [addTagToSims, addTagToSimsResponse] = useAddTagToSimsMutation();
  const [removeTagToSims, removeTagToSimsResponse] = useRemoveTagToSimsMutation();

  return { tags, getTagsResponse, addTagToSims, addTagToSimsResponse, removeTagToSims, removeTagToSimsResponse };
};
