import { ConfirmationModal } from '@components/ConfirmationModal';
import { LoadingWrapper } from '@components/LoadingWrapper';
import { getAuthenticatedUser } from '@modules/auth/utils';
import { convertTimeFields } from '@utils/timezoneConversion';
import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Customer, Users } from 'src/types/types';
import { Loader } from '../../../../components';
import DeprecatedSimpleTable from '../../../../components/Organisms/SimpleTable/DeprecatedSimpleTable';
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditCustomerMutation,
  useEditUserMutation,
} from '../account-api-slice';
import { EditOrganization, EditUser, InviteMember } from '../index';

type OrganizationProps = {
  setTab: (tab: string) => void;
  customer: Customer;
  refetchCustomer: () => void;
  userResponseData: Users;
  preferredTimezone: string | undefined;
  customerIsLoading: boolean;
};

const Organization: FC<OrganizationProps> = ({
  setTab,
  customer,
  refetchCustomer,
  userResponseData,
  preferredTimezone,
  customerIsLoading,
}) => {
  const [createUser] = useCreateUserMutation();

  const [editUser] = useEditUserMutation();

  const [deleteUser] = useDeleteUserMutation();

  const defaultTimezone = moment.tz.guess();
  const [preferredTime, setPreferredTime] = useState(defaultTimezone);
  const [users, setUsers] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (preferredTimezone) {
      setPreferredTime(preferredTimezone);
    }
  }, [preferredTimezone]);

  useEffect(() => {
    if (userResponseData?.users) {
      const convertedUser = convertTimeFields(userResponseData.users, preferredTime, ['last_login']);
      setUsers(convertedUser);
    }
  }, [userResponseData?.users, preferredTime]);

  const [editCustomer] = useEditCustomerMutation();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isEditOrganizationModalOpen, setIsEditOrganizationModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const columns = [
    { Header: 'Id', accessor: 'user_id', isVisible: false },
    {
      Header: 'MEMBER',
      accessor: 'name',
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: ({ value }) => {
        return value === 'active' ? (
          <Badge className="pt-2" bg="success">
            Active
          </Badge>
        ) : (
          <Badge className="pt-2" bg="warning">
            Inactive
          </Badge>
        );
      },
    },
    {
      Header: 'LAST LOGIN',
      accessor: 'last_login',
      extraInfo: t('timezone.extraInfo', { preferredTime: preferredTime }),
    },
    {
      Header: 'ROLE',
      accessor: 'role',
    },
  ];

  const handleEdit = async ({ name, email, role }) => {
    try {
      await editUser({ id: activeUser, name, email, role }).unwrap();
      toast.success(t('organization.userEditedSuccessfully'));
      setIsEditUserModalOpen(false);
      setActiveUser(null);
    } catch (error) {
      console.error('Error editing user:', error);
      setErrorMessage(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  const handleDelete = async () => {
    try {
      await deleteUser(activeUser).unwrap();
      toast.success(t('organization.userDeletedSuccessfully'));
      setIsDeleteConfirmModalOpen(false);
      setActiveUser(null);
    } catch (error) {
      console.error('Error deleting user:', error);
      setErrorMessage(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  const handleCreateUser = async (values) => {
    try {
      await createUser(values).unwrap();
      toast.success(t('organization.userCreatedSuccessfully'));
      setIsInviteModalOpen(false);
    } catch (error) {
      console.error('Error creating user:', error);
      setErrorMessage(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  const handleOrganizationEdit = async ({ name }) => {
    try {
      await editCustomer({ company_name: name }).unwrap();
      toast.success(t('organization.organizationEditedSuccessfully'));
      refetchCustomer();
      setIsEditOrganizationModalOpen(false);
    } catch (error) {
      console.error('Error editing organization:', error);
      setErrorMessage(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  if (customerIsLoading) {
    return (
      <div className="tw-h-full tw-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {customer && (
        <div className="d-flex flex-column">
          <div>
            <Card className="h-100">
              <Card.Body>
                <Row className="align-items-center gx-0 h-100">
                  <Col>
                    <h6 className="text-uppercase text-muted mb-2">{t('organization.organizationName')}</h6>
                    <h2 className="header-title">{customer?.customerDetails?.company_name}</h2>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      className=""
                      variant="outline-primary"
                      size="sm"
                      onClick={() => setIsEditOrganizationModalOpen(true)}
                    >
                      Edit name
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <LoadingWrapper isLoading={!users}>
            <div>
              <DeprecatedSimpleTable
                title="Team"
                hasPagination={false}
                initialPageSize={1000}
                autoResetPage={false}
                autoResetSortBy={false}
                data={users}
                columns={columns}
                hasPerPage={false}
                hasExport={false}
                customActionButton={
                  <Button
                    className="text-nowrap"
                    variant="primary"
                    size="sm"
                    onClick={() => setIsInviteModalOpen(true)}
                  >
                    Invite member
                  </Button>
                }
                hasActions
                actionItems={[
                  {
                    title: 'Edit',
                    handleClick: (row) => {
                      setActiveUser(row.values.user_id);
                      setIsEditUserModalOpen(true);
                    },
                  },
                  {
                    title: 'Delete',
                    handleClick: async (row) => {
                      const user = await getAuthenticatedUser();
                      if (user?.email === row.values.email) {
                        setTab('profile');
                      } else {
                        setActiveUser(row.values.user_id);
                        setIsDeleteConfirmModalOpen(true);
                      }
                    },
                  },
                ]}
              />
            </div>
            <InviteMember
              isOpen={isInviteModalOpen}
              handleClose={() => setIsInviteModalOpen(false)}
              handleCreate={handleCreateUser}
            />
            {!!users && (
              <>
                <EditUser
                  isOpen={isEditUserModalOpen}
                  handleClose={() => setIsEditUserModalOpen(false)}
                  handleEdit={handleEdit}
                  user={users.find(({ user_id }) => user_id === activeUser)}
                />
                <ConfirmationModal
                  isOpen={isDeleteConfirmModalOpen}
                  handleCancel={() => {
                    setIsDeleteConfirmModalOpen(false);
                    setActiveUser(null);
                  }}
                  handleConfirm={handleDelete}
                  subtitle={t('organization.areYouSureDeleteTeamMember', {
                    user: users.find(({ user_id }) => user_id === activeUser)?.email,
                  })}
                  title={t('organization.deleteTeamMember')}
                />
              </>
            )}
            <EditOrganization
              isOpen={isEditOrganizationModalOpen}
              organization={customer.customerDetails}
              handleClose={() => setIsEditOrganizationModalOpen(false)}
              handleEdit={handleOrganizationEdit}
            />
            {errorMessage && (
              <div className="mt-3">
                <Alert dismissible onClose={() => setErrorMessage('')} variant="danger my-0">
                  {errorMessage}
                </Alert>
              </div>
            )}
          </LoadingWrapper>
        </div>
      )}
    </>
  );
};

export default Organization;
