export const TAG_COLORS = {
  'light-purple': { name: 'Light Purple', hexCode: '#C985FF' },
  'blue-grey-600': { name: 'Blue Grey 600', hexCode: '#46719C' },
  'blue-700': { name: 'Blue 700', hexCode: '#0A1E3B' },
  'blue-400': { name: 'Blue 400', hexCode: '#1B4E98' },
  'blue-300': { name: 'Blue 300', hexCode: '#2467CA' },
  'blue-200': { name: 'Blue 200', hexCode: '#6091D9' },
  'blue-100': { name: 'Blue 100', hexCode: '#9CBBE9' },
  'blue-50': { name: 'Blue 50', hexCode: '#D8E5F8' },
  'green-100': { name: 'Green 100', hexCode: '#CBF4E2' },
  'green-300': { name: 'Green 300', hexCode: '#7BE3B5' },
  'green-500': { name: 'Green 500', hexCode: '#2CCD87' },
  'green-600': { name: 'Green 600', hexCode: '#229F68' },
  'yellow-400': { name: 'Yellow 400', hexCode: '#FADA5E' },
  'orange-100': { name: 'Orange 100', hexCode: '#FFDCD9' },
  'orange-300': { name: 'Orange 300', hexCode: '#FF8C80' },
  'orange-400': { name: 'Orange 400', hexCode: '#FF5745' },
  'orange-500': { name: 'Orange 500', hexCode: '#E8402E' },
  'orange-700': { name: 'Orange 700', hexCode: '#BB1200' },
};
