import { Icon } from '@components/Atoms/Icon/Icon';
import { flexRender, HeaderGroup } from '@tanstack/react-table';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CustomColumnDef } from 'src/types/types';

type SimpleTableHeaderProps<TData extends object> = {
  headerGroups: HeaderGroup<TData>[];
  tooltipVisibility: Record<string, boolean>;
  showTooltip: (columnId: string) => void;
  hideTooltip: (columnId: string) => void;
};

export const SimpleTableHeader = <TData extends object>({
  headerGroups,
  tooltipVisibility,
  showTooltip,
  hideTooltip,
}: SimpleTableHeaderProps<TData>) => {
  const tooltipTriggerRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const positionUpdateRef = useRef<() => void>(() => {});

  const setTooltipTriggerRef = useCallback(
    (columnId: string) => (el: HTMLDivElement | null) => {
      tooltipTriggerRefs.current[columnId] = el;
    },
    [],
  );

  useEffect(() => {
    const handlePositionUpdate = () => {
      positionUpdateRef.current();
    };

    window.addEventListener('resize', handlePositionUpdate);
    window.addEventListener('scroll', handlePositionUpdate);

    return () => {
      window.removeEventListener('resize', handlePositionUpdate);
      window.removeEventListener('scroll', handlePositionUpdate);
    };
  }, []);

  return (
    <thead className="tw-border-b tw-border-gray-200">
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            const columnDef = header.column.columnDef as CustomColumnDef<TData, unknown>;
            return (
              <th
                key={header.id}
                className="tw-whitespace-nowrap tw-p-4 tw-text-xs tw-font-semibold tw-text-sb-blue-grey-600 hover:tw-cursor-pointer"
                colSpan={header.colSpan}
                onClick={header.column.getToggleSortingHandler()}
              >
                <div className="tw-flex tw-h-8">
                  <div className="tw-flex tw-items-center">
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {columnDef.extraInfo && (
                          <div
                            ref={setTooltipTriggerRef(header.id)}
                            className="tw-relative tw-flex tw-items-center"
                            onMouseEnter={() => showTooltip(header.id)}
                            onMouseLeave={() => hideTooltip(header.id)}
                          >
                            <span className="tw-ml-2 tw-cursor-help tw-text-gray-500">
                              <Icon name="question_info" width={20} height={20} />
                            </span>
                            {tooltipVisibility[header.id] && (
                              <TooltipContent
                                columnId={header.id}
                                triggerElement={tooltipTriggerRefs.current[header.id]}
                                index={index}
                                extraInfo={columnDef.extraInfo}
                                positionUpdateRef={positionUpdateRef}
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!!header.column.getIsSorted() && (
                    <button className="tw-border-0 tw-bg-transparent">
                      {header.column.getIsSorted() === 'asc' ? (
                        <i className="fe fe-chevron-up" />
                      ) : header.column.getIsSorted() === 'desc' ? (
                        <i className="fe fe-chevron-down" />
                      ) : (
                        ''
                      )}
                    </button>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export const useTooltipPosition = (triggerElement: HTMLElement | null) => {
  const [position, setPosition] = useState<'top' | 'bottom' | null>(null);
  const rafId = useRef<number | null>(null);

  const calculatePosition = useCallback(() => {
    if (!triggerElement) return;

    if (rafId.current) {
      cancelAnimationFrame(rafId.current);
    }

    rafId.current = requestAnimationFrame(() => {
      const rect = triggerElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceAbove = rect.top;
      const spaceBelow = viewportHeight - rect.bottom;

      const buffer = 20;
      const newPosition = spaceAbove > spaceBelow - buffer ? 'top' : 'bottom';

      setPosition(newPosition);
    });
  }, [triggerElement]);

  const debouncedCalculatePosition = useCallback(debounce(calculatePosition, 100), [calculatePosition]);

  useEffect(() => {
    if (!triggerElement) return;

    calculatePosition();

    const resizeObserver = new ResizeObserver(debouncedCalculatePosition);
    resizeObserver.observe(triggerElement);

    window.addEventListener('scroll', debouncedCalculatePosition);
    window.addEventListener('resize', debouncedCalculatePosition);

    return () => {
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener('scroll', debouncedCalculatePosition);
      window.removeEventListener('resize', debouncedCalculatePosition);
    };
  }, [triggerElement, calculatePosition, debouncedCalculatePosition]);

  return { position, recalculatePosition: calculatePosition };
};

const TooltipContent: React.FC<{
  columnId: string;
  triggerElement: HTMLElement | null;
  index: number;
  extraInfo: React.ReactNode;
  positionUpdateRef: React.MutableRefObject<() => void>;
}> = React.memo(({ columnId, triggerElement, index, extraInfo, positionUpdateRef }) => {
  const { position: tooltipPosition, recalculatePosition } = useTooltipPosition(triggerElement);

  useEffect(() => {
    positionUpdateRef.current = recalculatePosition;
  }, [positionUpdateRef, recalculatePosition]);

  if (tooltipPosition === null) return null;

  return (
    <div className="tw-relative tw-z-10" key={`tooltip-${columnId}`}>
      <span
        className={`tw-absolute ${tooltipPosition === 'bottom' ? 'tw-top-0' : 'tw-bottom-4'} ${
          index === 0 ? 'tw-left-0' : 'tw-right-0'
        } tw-mb-2 tw-w-48 tw-overflow-auto tw-whitespace-normal tw-break-words tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-50 tw-bg-white tw-p-3 tw-text-sm tw-text-gray-700 tw-shadow-lg`}
      >
        {extraInfo}
      </span>
    </div>
  );
});
