import './wdyr';
import React from 'react';
import './init';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import '@assets/scss/theme.scss';
import { store as rtkstore } from '@core/rtk-store.ts';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import './i18n';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (import.meta.env.MODE === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-WWNKBT8',
  };

  TagManager.initialize(tagManagerArgs);
}

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  person_profiles: 'always',
});

// Add global error handler
window.addEventListener('error', (event) => {
  console.error('Uncaught error:', event.error);
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.Suspense fallback="loading">
    <PostHogProvider client={posthog}>
      <Provider store={rtkstore}>
        <App />
      </Provider>
    </PostHogProvider>
  </React.Suspense>,
);
