import { BaseDropdown } from '@components/Atoms/BaseDropdown/BaseDropdown';
import { MenuItem } from '@headlessui/react';
import { AnchorProps } from 'node_modules/@headlessui/react/dist/internal/floating';
import React, { ReactNode } from 'react';

type ActionDropdownProps<T extends { title: string; disabled?: boolean }> = {
  options: T[];
  onAction: (option: T) => void;
  renderOption: (option: T) => ReactNode;
  renderButton: (open: boolean) => React.ReactElement;
  showFilter?: boolean;
  title?: string;
  filterPlaceholder?: string;
  anchorPosition?: AnchorProps;
  bottomButton?: ReactNode;
};

const MENU_ITEM_CLASS = `
  tw-text-sb-blue-grey-600 tw-flex tw-items-center 
  tw-gap-[10px] tw-rounded-md tw-border tw-border-solid tw-border-transparent
  tw-p-[10px] tw-text-[14px] hover:tw-cursor-pointer hover:tw-bg-sb-blue-grey-50 hover:!tw-border-sb-blue-grey-300 tw-flex-grow tw-self-stretch 
  data-[disabled]:tw-text-sb-blue-grey-200 data-[disabled]:hover:tw-cursor-default data-[disabled]:hover:tw-bg-transparent data-[disabled]:hover:!tw-border-transparent
`;

export const ActionDropdownComponent = <T extends { title: string; disabled: boolean }>({
  options,
  onAction,
  renderOption,
  renderButton,
  showFilter = false,
  title,
  filterPlaceholder,
  anchorPosition,
  bottomButton,
}: ActionDropdownProps<T>) => {
  return (
    <BaseDropdown
      showFilter={showFilter}
      anchorPosition={anchorPosition}
      title={title}
      filterPlaceholder={filterPlaceholder}
      bottomButton={bottomButton}
      children={(open) => renderButton(open)}
      menuItems={(filterQuery) => {
        const filteredOptions = options.filter((option) =>
          option.title.toLowerCase().includes(filterQuery.toLowerCase()),
        );

        return (
          <>
            {filteredOptions.map((option, index) => (
              <MenuItem
                key={index}
                as="div"
                disabled={option.disabled}
                onClick={() => onAction(option)}
                className={MENU_ITEM_CLASS}
              >
                {renderOption(option)}
              </MenuItem>
            ))}
          </>
        );
      }}
    />
  );
};

export const ActionDropdown = React.memo(ActionDropdownComponent) as <T extends { title: string }>(
  props: ActionDropdownProps<T>,
) => JSX.Element;
