import { AlternateModal } from '@components/Atoms/AlternateModal/AlternateModal';
import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import TransparentInput from '@components/Atoms/TrasparentInput/TransparentInput';
import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type DeleteTagsModalProps = {
  open: boolean;
  onClose: () => void;
  handleDelete: () => void;
};

export const DeleteTagsModal = ({ open, onClose, handleDelete }: DeleteTagsModalProps) => {
  const { t } = useTranslation();

  const [confirmationText, setConfirmationText] = useState<string>('');

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmationText(e.target.value);
  };

  return (
    <AlternateModal
      modalOpen={open}
      closeModal={onClose}
      title={t('simcard.deleteTag')}
      titleIcon="info_warning"
      modalSize="small"
    >
      <div className="tw-flex tw-flex-col tw-gap-6 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-6">
        <span className="tw-text-sm">{t('simcard.areYouSureYouWantToDeleteThisTag')}</span>

        <div className="tw-flex tw-flex-col tw-gap-4">
          <span className="tw-text-sm">
            <Trans>{t('simcard.typeDeleteToConfirm', { Delete: 'DELETE' })}</Trans>
          </span>
          <TransparentInput
            value={confirmationText}
            onChange={handleMessageChange}
            className="!tw-bg-sb-blue-grey-25"
            placeholder=""
          />
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-gap-2.5 tw-bg-sb-blue-grey-100 tw-px-6 tw-py-3.5">
        <Button variant="outline" onClick={onClose} className="!tw-w-full">
          {t('keepIt')}
        </Button>
        <Button
          variant="primaryDark"
          onClick={handleDelete}
          disabled={confirmationText !== 'DELETE'}
          className="!tw-w-full tw-gap-2.5 tw-bg-sb-orange-400 tw-capitalize hover:tw-bg-sb-orange-500 active:tw-bg-sb-orange-500 disabled:tw-bg-sb-orange-100"
        >
          <Icon name={confirmationText !== 'DELETE' ? 'delete_bin_trasparent' : 'delete_bin'} />
          {t('delete')}
        </Button>
      </div>
    </AlternateModal>
  );
};
