import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { InputModal } from '@components/Atoms/InputModal/InputModal';
import { PaginationButtons } from '@components/Atoms/PaginationButtons/PaginationButtons';
import { SimCounter } from '@components/Atoms/SimCounter/SimCounter';
import TransparentInput from '@components/Atoms/TrasparentInput/TransparentInput';
import { ActionDropdown } from '@components/Molecules/ActionDropdown/ActionDropdown';
import { AssignTagsModal } from '@components/Molecules/AssignTagsModal/AssignTagsModal';
import { AssignToNetworkModal } from '@components/Molecules/AssignToNetworkModal/AssignToNetworkModal';
import { CoverageFilter } from '@components/Molecules/CoverageFilter/CoverageFilter';
import { DeleteSimModal } from '@components/Molecules/DeleteSimModal/DeleteSimModal';
import { ExportSimsModal } from '@components/Molecules/ExportSimsModal/ExportSimsModal';
import { ManageTagsModal } from '@components/Molecules/ManageTagsModal/ManageTagsModal';
import { NetworkFilter } from '@components/Molecules/NetworkFilter/NetworkFilter';
import { NoFilteredSimsTableBody } from '@components/Molecules/NoFilteredSims/NoFilteredSimsTableBody';
import { SendSmsModal } from '@components/Molecules/SendSmsModal/SendSmsModal';
import { SimcardActionBar } from '@components/Molecules/SimcardActionBar/SimcardActionBar';
import { StateFilter } from '@components/Molecules/StateFilter/StateFilter';
import { TagFilter } from '@components/Molecules/TagFilter/TagFilter';
import { SIM_STATE } from '@core/constants';
import { useAppSelector } from '@core/rtk-hooks';
import { useMutateSimStatus } from '@modules/dashboard/simCard/hooks/useMutateSimStatus';
import { SimcardsListViewItem, SimcardsState } from '@modules/dashboard/simCard/simcards-slice';
import { PaginationState, Row, Table } from '@tanstack/react-table';
import {
  getSimsCounter,
  handleCoverageChange,
  handleNetworkChange,
  handleStateChange,
  handleTagChange,
  tagIsValidColor,
} from '@utils/simCardUtils/simCardUtils';
import isEqual from 'lodash/isEqual';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetURLSearchParams, useNavigate } from 'react-router-dom';
import { MenuItemType, SimcardNetwork, Tag } from 'src/types/types';
import SimpleTable from '../SimpleTable/SimpleTable';

const INITIAL_PAGE_SIZE = 50;

interface SimCardsTableProps<TData extends SimcardsListViewItem> {
  tags: Tag[];
  coverage: string[];
  table: Table<TData>;
  globalFilter?: string;
  setGlobalFilter?: (value: string) => void;
  selectedCoverage: string[];
  selectedState: string | null;
  selectedNetwork: string | null;
  selectedTags: string[];
  inputValue: string;
  handleFilter: (value: string) => void;
  simCardState: SimcardsState;
  setSearchParams: SetURLSearchParams;
  searchParams: URLSearchParams;
  clearFilters: () => void;
  rowSelection: { [key: string]: boolean };
  paginationState: PaginationState;
  setRowSelection: React.Dispatch<React.SetStateAction<{}>>;
  simcardsData: SimcardsListViewItem[];
  simsResponseIsFulfilled: boolean;
  sidebarOpen: boolean;
  coverageResponseIsLoading: boolean;
  tagsResponseIsLoading: boolean;
  networksData: SimcardNetwork[];
  networksDataIsLoading: boolean;
  scrollContainerRef: MutableRefObject<any>;
}

type FilterStatus = {
  selectedCoverage: MenuItemType[];
  selectedState: MenuItemType | null;
  selectedTags: MenuItemType[];
  selectedNetwork: MenuItemType | null;
  mappedCoverage: MenuItemType[];
  mappedTags: MenuItemType[];
  mappedNetworks: MenuItemType[];
};

const SimcardsTableComponent = <TData extends SimcardsListViewItem>({
  tags,
  coverage,
  table,
  selectedCoverage,
  selectedState,
  selectedTags,
  selectedNetwork,
  inputValue,
  handleFilter,
  simCardState,
  globalFilter,
  setGlobalFilter,
  setSearchParams,
  searchParams,
  clearFilters,
  rowSelection,
  setRowSelection,
  simcardsData,
  simsResponseIsFulfilled,
  sidebarOpen,
  tagsResponseIsLoading,
  coverageResponseIsLoading,
  networksData,
  networksDataIsLoading,
  scrollContainerRef,
}: SimCardsTableProps<TData>): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  // State for modals visibility
  const [smsModalOpen, setSmsModalOpen] = useState<boolean>(false);
  const [assignToNetworkModalOpen, setAssignToNetworkModalOpen] = useState<boolean>(false);
  const [assignTagsModalOpen, setAssignTagsModalOpen] = useState<boolean>(false);
  const [deleteSimModalOpen, setDeleteSimModalOpen] = useState<boolean>(false);
  const [exportSimsModalOpen, setExportSimsModalOpen] = useState<boolean>(false);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [manageTagsModalOpen, setManageTagsModalOpen] = useState<boolean>(false);
  const [anyModalIsOpen, setAnyModalIsOpen] = useState<boolean>(false);

  const stateFilterRef = useRef<any>(null);
  const coverageFilterRef = useRef<any>(null);
  const tagFilterRef = useRef<any>(null);
  const networkFilterRef = useRef<any>(null);

  useEffect(() => {
    if (
      smsModalOpen ||
      assignToNetworkModalOpen ||
      assignTagsModalOpen ||
      deleteSimModalOpen ||
      exportSimsModalOpen ||
      manageTagsModalOpen
    ) {
      setAnyModalIsOpen(true);
    } else {
      setAnyModalIsOpen(false);
    }
  }, [
    smsModalOpen,
    assignToNetworkModalOpen,
    assignTagsModalOpen,
    deleteSimModalOpen,
    exportSimsModalOpen,
    manageTagsModalOpen,
  ]);

  const [filterState, setFilterState] = useState<FilterStatus>({
    selectedCoverage: [],
    selectedState: null,
    selectedTags: [],
    selectedNetwork: null,
    mappedCoverage: [],
    mappedTags: [],
    mappedNetworks: [],
  });

  const stateItems = useMemo(
    () => [
      { title: t('enabled'), icon: 'play' },
      { title: t('Disabled'), icon: 'pause' },
    ],
    [t],
  );

  //The searchContainerRef is used to set the width of the containerWidth which search component should be shown
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  //This ref is used to target the transparent input when user presses 's' on their keyboard
  const transparentInputRef = useRef<HTMLInputElement>(null);

  // Focuses on the search input when 's' is clicked
  const setInputRef = useCallback(
    (inputElement: HTMLInputElement | null) => {
      setTimeout(() => {
        if (searchModalOpen && inputElement) {
          inputElement.focus();
        }
      }, 100);
    },
    [searchModalOpen],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement as HTMLElement;
      const isInputFocused =
        activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA');

      if (
        !isInputFocused &&
        (event.key === 'S' || event.key === 's') &&
        transparentInputRef.current !== document.activeElement &&
        !anyModalIsOpen
      ) {
        event.preventDefault();
        if (transparentInputRef.current === null) {
          setSearchModalOpen(true);
        } else {
          transparentInputRef.current.focus();
        }
      }
      if (event.key === 'Enter' && searchModalOpen === true) {
        event.preventDefault();
        setSearchModalOpen(false);
      }
    },
    [searchModalOpen, anyModalIsOpen],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);

  //Calculate the width of the searchContainerDiv to determine whether to show the TransparentInput or the search button
  useEffect(() => {
    const updateWidth = () => {
      if (searchContainerRef.current) {
        setTimeout(() => {
          setContainerWidth(searchContainerRef.current.offsetWidth);
        }, 50);
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [filterState.selectedCoverage.length, filterState.selectedState, filterState.selectedTags.length, sidebarOpen]);

  // The tableWidth is used to determine whether to show the ActionBar or the ActionButton and to set the width of the ActionBar
  const tableRef = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState(0);

  //Used to set some styling in the ActionButton
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  //Calculate the width of the table to set the width of the ActionBar
  useEffect(() => {
    const updateWidth = () => {
      if (tableRef.current) {
        setTimeout(() => {
          setTableWidth(tableRef.current.offsetWidth);
        }, 50);
      }
      setViewportWidth(window.innerWidth);
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [sidebarOpen]);

  const actionBarRef = useRef(null);
  // Used to set different styles once the actionBar or ActionButton becomes sticky
  const [isSticky, setIsSticky] = useState(false);

  // Determines if the ActionBar or ActionButton has become sticky
  useEffect(() => {
    const handleScroll = () => {
      stateFilterRef.current?.closeMenu();
      coverageFilterRef.current?.closeMenu();
      tagFilterRef.current?.closeMenu();
      networkFilterRef.current?.closeMenu();

      if (!actionBarRef.current) return;

      const stickyTop = 38;
      const offsetTop = actionBarRef.current.getBoundingClientRect().top;

      if (offsetTop <= stickyTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef]);

  // NumberOfSims is used for the pagination display
  const [fromNumberOfSims, setFromNumberOfSims] = useState(0);
  const [toNumberOfSims, setToNumberOfSims] = useState(0);
  // For the pagination buttons
  const pageIndex = table.getState().pagination.pageIndex;
  const pageCount = table?.getPageCount();

  // The iccids for the selected simcards
  const iccids = useMemo(
    () => table?.getSelectedRowModel().rows.map((row) => (row.original as SimcardsListViewItem).iccid),
    [rowSelection],
  );

  const { mutateSimStatus } = useMutateSimStatus();

  const { simcards } = useAppSelector((state) => state.simcardsState);
  const selectedSimcards = useMemo(() => simcards?.filter((sim) => iccids.includes(sim.iccid)), [simcards, iccids]);

  // Gets the table rows to export depending on whether the user has selected or filtered
  const exportRowsData: Row<TData>[] = useMemo(
    () =>
      table.getSelectedRowModel().rows.length > 0
        ? table.getSelectedRowModel().flatRows
        : table.getState().columnFilters?.length > 0 || table.getState().globalFilter
          ? table.getFilteredRowModel().flatRows
          : table.getCoreRowModel().flatRows,
    [iccids],
  );

  // for the pagination buttons
  const numberOfSimsInTable =
    table.getState().columnFilters?.length > 0 || table.getState().globalFilter
      ? table.getFilteredRowModel().flatRows.length
      : table.getCoreRowModel().flatRows.length;

  useEffect(() => {
    setFromNumberOfSims(pageIndex * INITIAL_PAGE_SIZE + 1);
    setToNumberOfSims(
      (pageIndex + 1) * INITIAL_PAGE_SIZE > numberOfSimsInTable
        ? numberOfSimsInTable
        : (pageIndex + 1) * INITIAL_PAGE_SIZE,
    );
  }, [pageIndex, numberOfSimsInTable]);

  const actionItems: MenuItemType[] = [
    {
      title: t('enable'),
      handleClick: () =>
        mutateSimStatus({
          simcards: selectedSimcards,
          desiredState: SIM_STATE.ENABLED,
          t: t,
        }),
      disabled: selectedSimcards.every((simcard) => simcard.sim_state === 'enabled'),
      icon: 'play',
    },
    {
      title: t('Disable'),
      handleClick: () =>
        mutateSimStatus({
          simcards: selectedSimcards,
          desiredState: SIM_STATE.DISABLED,
          t: t,
        }),
      disabled: selectedSimcards.every((simcard) => simcard.sim_state === 'disabled'),
      icon: 'pause',
    },
    {
      title: t('sendSms'),
      handleClick: () => {
        setSmsModalOpen(true);
      },
      icon: 'send_sms',
    },
    {
      title: t('simcards.assignToPrivateNetwork'),
      handleClick: () => setAssignToNetworkModalOpen(true),
      icon: 'private_network_dark',
      disabled: networksDataIsLoading,
    },
    {
      title: t('simcards.assignTags'),
      handleClick: () => setAssignTagsModalOpen(true),
      icon: 'assign_tags',
    },
    {
      title: t('export'),
      handleClick: () => setExportSimsModalOpen(true),
      icon: 'export',
    },
    {
      title: t('simcards.deleteSelected'),
      handleClick: () => setDeleteSimModalOpen(true),
      icon: 'delete_bin',
    },
  ];

  // mappedCoverage
  useEffect(() => {
    if (coverage) {
      const mapped = coverage.map((region) => {
        return {
          title: region,
          icon: region === 'Global+' ? 'global_plus' : region.toLowerCase(),
        };
      });

      setFilterState((prev) => ({
        ...prev,
        mappedCoverage: mapped,
      }));
    }
  }, [coverage]);

  // mappedTags
  useEffect(() => {
    if (tags) {
      const mapped: MenuItemType[] = tags.map((tag) => {
        const validColor = tag.color && tagIsValidColor(tag.color) ? tag.color : 'orange-300';
        return {
          title: tag.tag,
          tagColor: validColor,
        };
      });
      mapped.push({
        title: t('simcard.filters.devicesWithNoTags'),
        tagColor: 'without_tags',
        isSpecialOption: true,
      });
      setFilterState((prev) => ({
        ...prev,
        mappedTags: mapped,
      }));
    }
  }, [tags, t]);

  // mappedNetworks
  useEffect(() => {
    if (networksData) {
      const mapped: MenuItemType[] = networksData.map((network) => {
        return {
          title: network.network_name,
          id: network.network_id,
        };
      });
      mapped.push({
        title: t('simcard.filters.withoutNetwork'),
        id: 'Without network',
        isSpecialOption: true,
      });
      setFilterState((prev) => ({
        ...prev,
        mappedNetworks: mapped,
      }));
    }
  }, [networksData, t]);

  // Update the filter state with the correct selected states
  useEffect(() => {
    const currentState = stateItems.find((item) => {
      return item.title.toLowerCase() === selectedState?.toLowerCase();
    });

    const currentNetwork = filterState.mappedNetworks.length
      ? filterState.mappedNetworks.find((network) => {
          return network?.id === selectedNetwork;
        })
      : null;

    const currentCoverage = filterState.mappedCoverage.filter((coverageItem) =>
      selectedCoverage.includes(coverageItem.title),
    );

    const currentTags = filterState.mappedTags.filter((tagItem) => {
      if (selectedTags.includes('__NO_TAGS__') && tagItem.title === t('simcard.filters.devicesWithNoTags')) return true;
      if (selectedTags.includes(tagItem.title)) return true;
      return false;
    });

    setFilterState((prev) => {
      if (
        isEqual(prev.selectedCoverage, currentCoverage) &&
        isEqual(prev.selectedState, currentState ?? null) &&
        isEqual(prev.selectedTags, currentTags) &&
        isEqual(prev.selectedNetwork, currentNetwork ?? null)
      ) {
        return prev;
      }

      return {
        ...prev,
        selectedCoverage: currentCoverage,
        selectedState: currentState ?? null,
        selectedTags: currentTags,
        selectedNetwork: currentNetwork,
      };
    });
  }, [
    selectedCoverage,
    selectedTags,
    selectedState,
    selectedNetwork,
    filterState.mappedNetworks,
    filterState.mappedCoverage,
    filterState.mappedTags,
    stateItems,
    t,
  ]);

  //Text for total/filtered/selected sims
  const simsCounterText = useCallback(() => getSimsCounter(table, t), [table, t]);
  const numberOfSelectedSims = table.getSelectedRowModel().rows.length;

  // Pagination functions
  const { setPageIndex, previousPage, nextPage, getCanPreviousPage, getCanNextPage } = table;
  const beginningOnClick = useCallback(() => setPageIndex(0), [setPageIndex]);
  const prevOnClick = useCallback(() => previousPage(), [previousPage]);
  const nextOnClick = useCallback(() => nextPage(), [nextPage]);
  const endOnClick = useCallback(() => setPageIndex(pageCount - 1), [setPageIndex, pageCount]);

  //Table body to be shown when there are no filtered SimCards to show
  const alternateBody = () => useMemo(() => <NoFilteredSimsTableBody clearFilters={clearFilters} />, [clearFilters]);

  // Boolean if any filters are Applied
  const filtersApplied = useMemo(
    () =>
      !!filterState.selectedCoverage.length ||
      !!filterState.selectedState ||
      !!filterState.selectedTags.length ||
      !!filterState.selectedNetwork ||
      !!globalFilter,
    [
      filterState.selectedCoverage.length,
      filterState.selectedState,
      filterState.selectedTags.length,
      filterState.selectedNetwork,
      globalFilter,
    ],
  );

  return (
    <div>
      {/* Filter Section */}
      <div
        className={`tw-mb-6 tw-flex tw-w-full tw-flex-col tw-justify-between tw-gap-5 tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-pb-6 ${filtersApplied ? 'md:tw-flex-row' : 'sm:tw-flex-row'}`}
      >
        <div
          className={`tw-flex tw-w-full tw-flex-col tw-gap-5 ${filtersApplied ? 'md:tw-flex-row md:tw-pr-3' : 'sm:tw-flex-row sm:tw-pr-3'}`}
        >
          {/* State Filter */}
          <div
            className={`tw-grid tw-w-full tw-grid-cols-2 tw-gap-5 ${filtersApplied ? 'md:tw-flex md:tw-flex-row' : 'sm:tw-flex sm:tw-flex-row'}`}
          >
            <StateFilter
              ref={stateFilterRef}
              stateItems={stateItems}
              selectedState={filterState.selectedState}
              handleStateChange={(option) => {
                handleStateChange(option, setSearchParams, searchParams);
                setRowSelection({});
              }}
            />

            <CoverageFilter
              ref={coverageFilterRef}
              mappedCoverage={filterState.mappedCoverage}
              selectedCoverage={filterState.selectedCoverage}
              handleCoverageChange={(_, option) => {
                handleCoverageChange(option, setSearchParams, searchParams);
                setRowSelection({});
              }}
              isLoading={coverageResponseIsLoading}
              filtersApplied={filtersApplied}
            />
            <TagFilter
              ref={tagFilterRef}
              mappedTags={filterState.mappedTags}
              selectedTags={filterState.selectedTags}
              handleTagChange={(_, option) => {
                handleTagChange(option, setSearchParams, searchParams);
                setRowSelection({});
              }}
              buttonButtonFunction={() => setManageTagsModalOpen(true)}
              isLoading={tagsResponseIsLoading}
              filtersApplied={filtersApplied}
            />
            {!networksDataIsLoading && !!networksData.length && (
              <NetworkFilter
                ref={networkFilterRef}
                networkItems={filterState.mappedNetworks}
                selectedNetwork={filterState.selectedNetwork}
                handleNetworkChange={(option) => {
                  handleNetworkChange(option, setSearchParams, searchParams);
                  setRowSelection({});
                }}
              />
            )}
          </div>
          {filtersApplied && (
            <div
              className={`tw-flex tw-w-full tw-items-end tw-justify-end ${filtersApplied ? 'md:tw-items-end md:tw-justify-start' : 'sm:tw-items-end sm:tw-justify-start'}`}
            >
              <div
                className={`${filtersApplied ? 'md:tw-items-end md:tw-justify-start' : 'sm:tw-items-end sm:tw-justify-start'} tw-flex tw-flex-col tw-justify-end`}
              >
                <Button
                  variant="borderless"
                  className="tw-whitespace-nowrap tw-pl-0 tw-text-base !tw-font-normal tw-text-sb-blue-grey-600 tw-underline tw-underline-offset-4"
                  onClick={() => {
                    clearFilters();
                    setRowSelection({});
                  }}
                >
                  {t('simcard.filters.clearFilters')}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="tw-flex tw-w-full tw-justify-end">
          <div
            ref={searchContainerRef}
            className={`tw-flex tw-w-full tw-max-w-none ${filtersApplied ? 'md:tw-max-w-[425px]' : 'sm:tw-max-w-[425px]'} tw-flex-grow tw-flex-col tw-items-end tw-justify-end`}
          >
            {containerWidth > 250 ? (
              <TransparentInput
                placeholder={t('simcard.filters.searchDeviceNameICCIDAndMore')}
                value={inputValue}
                onChange={(e) => {
                  handleFilter(e.target.value);
                  setRowSelection({});
                }}
                className="tw-h-[44px] tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 focus:!tw-shadow-search-button"
                icon="search"
                ref={transparentInputRef}
              />
            ) : (
              <div
                onClick={() => setSearchModalOpen(true)}
                className="tw-flex tw-h-[45px] tw-w-[46px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white hover:tw-border-sb-blue-300 hover:tw-shadow-search-button"
              >
                <div className="tw-flex tw-h-[28px] tw-w-[28px] tw-items-center tw-justify-center tw-rounded-[4px] tw-bg-sb-blue-grey-50">
                  <Icon name="search" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        ref={tableRef}
        className="tw-flex tw-w-full tw-flex-col tw-items-end tw-gap-2.5 tw-pb-6 sm:tw-flex-row sm:tw-items-center sm:tw-justify-between"
      >
        <div className="tw-flex tw-items-center tw-gap-6">
          <SimCounter SimCardStateIsLoading={simCardState.isLoading} simsCounterText={simsCounterText()} />
        </div>
        <div className="tw-flex tw-items-center">
          <div className="tw-px-6 tw-text-sm">
            <span className="tw-font-bold">{`${fromNumberOfSims} - ${toNumberOfSims}`}</span>
            <span>{`${t('pagination.ofNoOfSimCards', { noOfSimCards: numberOfSimsInTable })}`}</span>
          </div>
          <PaginationButtons
            beginningOnClick={beginningOnClick}
            prevOnClick={prevOnClick}
            nextOnClick={nextOnClick}
            endOnClick={endOnClick}
            prevDisabled={!getCanPreviousPage()}
            nextDisabled={!getCanNextPage()}
          />
        </div>
      </div>
      {!!numberOfSelectedSims && (
        <div ref={actionBarRef} className="tw-sticky tw-top-20 tw-z-[5] tw-w-full tw-pb-5 sm:tw-top-[38px]">
          {tableWidth > 810 ? (
            <SimcardActionBar options={actionItems} width={tableWidth} isSticky={isSticky} />
          ) : (
            <div className="tw-flex tw-w-full tw-justify-end">
              <ActionDropdown<MenuItemType>
                options={actionItems}
                onAction={(option) => option.handleClick()}
                anchorPosition="bottom end"
                renderOption={(option) => (
                  <div className="tw-flex tw-items-center tw-gap-2.5">
                    {option.icon && (
                      <div className="tw-flex tw-w-3 tw-flex-shrink-0 tw-justify-center">
                        <Icon name={option.icon} />
                      </div>
                    )}
                    <div className="tw-w-full tw-flex-grow">
                      <span>{option.title}</span>
                    </div>
                  </div>
                )}
                renderButton={(open) => (
                  <div
                    className={`${!open && isSticky && 'tw-shadow-action-bar'} ${!open && viewportWidth < 640 && 'tw-shadow-action-bar'} tw-flex tw-items-center tw-gap-2.5 tw-rounded-lg tw-border tw-border-solid tw-bg-white tw-p-2.5 ${isSticky ? 'tw-rounded-t-none tw-border-t-0 tw-border-sb-blue-500' : 'tw-border-sb-blue-grey-300'}`}
                    role="button"
                  >
                    <Icon name="gear" width={13} height={14} />
                    <span className="tw-text-sb-blue-grey-600">Actions</span>
                    <Icon name="chevron_down" width={6} height={4} />
                  </div>
                )}
                title={t('Actions')}
              />
            </div>
          )}
        </div>
      )}
      <SimpleTable
        table={table}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        hasPagination={false}
        initialPageSize={INITIAL_PAGE_SIZE}
        hasPerPage={false}
        hasExport={false}
        processing={simcardsData.length === 0 && !simsResponseIsFulfilled}
        hasActions={false}
        showAlternateBody={numberOfSimsInTable === 0 && simsResponseIsFulfilled && filtersApplied}
        AlternateBody={alternateBody}
        onRowClick={(row) => {
          const rowId = row.id;

          if (Object.keys(rowSelection).length) {
            setRowSelection((prev) => {
              const newSelection = { ...prev };
              if (newSelection[rowId]) {
                delete newSelection[rowId];
              } else {
                newSelection[rowId] = true;
              }
              return newSelection;
            });
          } else {
            navigate(`${row.original.iccid}`);
          }
        }}
      />
      {simcardsData.length > 0 && (
        <div className="tw-flex tw-items-center tw-justify-end tw-py-7">
          <div className="tw-px-6 tw-text-sm">
            <span className="tw-font-bold">{`${fromNumberOfSims} - ${toNumberOfSims}`}</span>
            <span>{`${t('pagination.ofNoOfSimCards', { noOfSimCards: numberOfSimsInTable })}`}</span>
          </div>
          <PaginationButtons
            beginningOnClick={beginningOnClick}
            prevOnClick={prevOnClick}
            nextOnClick={nextOnClick}
            endOnClick={endOnClick}
            prevDisabled={!getCanPreviousPage()}
            nextDisabled={!getCanNextPage()}
          />
        </div>
      )}
      {smsModalOpen && <SendSmsModal open={smsModalOpen} onClose={() => setSmsModalOpen(false)} iccids={iccids} />}
      {assignToNetworkModalOpen && (
        <AssignToNetworkModal
          open={assignToNetworkModalOpen}
          onClose={() => setAssignToNetworkModalOpen(false)}
          iccids={iccids}
          networksData={networksData}
        />
      )}
      {assignTagsModalOpen && (
        <AssignTagsModal
          open={assignTagsModalOpen}
          onClose={() => setAssignTagsModalOpen(false)}
          iccids={iccids}
          tags={filterState.mappedTags}
          simcards={selectedSimcards}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      )}
      {deleteSimModalOpen && (
        <DeleteSimModal open={deleteSimModalOpen} onClose={() => setDeleteSimModalOpen(false)} iccids={iccids} />
      )}
      {exportSimsModalOpen && (
        <ExportSimsModal<TData>
          open={exportSimsModalOpen}
          onClose={() => setExportSimsModalOpen(false)}
          selectedRows={exportRowsData}
        />
      )}
      {searchModalOpen && (
        <InputModal
          modalOpen={searchModalOpen}
          closeModal={() => setSearchModalOpen(false)}
          placeholder={t('simcard.filters.searchDeviceNameICCIDAndMore')}
          inputValue={inputValue}
          onChange={(newValue) => handleFilter(newValue)}
          className="tw-h-[44px] tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-shadow-seach-modal-shadow"
          buttonText={t('Enter')}
          buttonFunction={() => setSearchModalOpen(false)}
          setInputRef={setInputRef}
        />
      )}
      {manageTagsModalOpen && (
        <ManageTagsModal
          open={manageTagsModalOpen}
          onClose={() => setManageTagsModalOpen(false)}
          tags={filterState.mappedTags.slice(0, -1)}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
    </div>
  );
};

export const SimcardsTable = React.memo(SimcardsTableComponent, (prevProps, nextProps) => {
  const prevTableState = prevProps.table.getState();
  const nextTableState = nextProps.table.getState();

  return (
    isEqual(prevProps.searchParams, nextProps.searchParams) &&
    prevProps.setSearchParams === nextProps.setSearchParams &&
    prevProps.coverage === nextProps.coverage &&
    isEqual(prevProps.tags, nextProps.tags) &&
    prevProps.networksData === nextProps.networksData &&
    prevProps.networksDataIsLoading === nextProps.networksDataIsLoading &&
    prevProps.simCardState === nextProps.simCardState &&
    isEqual(prevTableState, nextTableState) &&
    isEqual(prevProps.rowSelection, nextProps.rowSelection) &&
    isEqual(prevProps.paginationState, nextProps.paginationState) &&
    prevProps.sidebarOpen === nextProps.sidebarOpen
  );
});
