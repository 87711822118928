import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ColumnInstance, HeaderGroup } from 'react-table';

type DeprecatedNewSimpleTableHeader = {
  headerGroups: HeaderGroup<object>[];
  tooltipVisibility: Record<string, boolean>;
  showTooltip: (columnId: string) => void;
  hideTooltip: (columnId: string) => void;
};

export const DeprecatedSimpleTableHeader: React.FC<DeprecatedNewSimpleTableHeader> = React.memo(
  ({ headerGroups, tooltipVisibility, showTooltip, hideTooltip }) => {
    const tooltipTriggerRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const positionUpdateRef = useRef<() => void>(() => {});

    const setTooltipTriggerRef = useCallback(
      (columnId: string) => (el: HTMLDivElement | null) => {
        tooltipTriggerRefs.current[columnId] = el;
      },
      [],
    );

    useEffect(() => {
      const handlePositionUpdate = () => {
        positionUpdateRef.current();
      };

      window.addEventListener('resize', handlePositionUpdate);
      window.addEventListener('scroll', handlePositionUpdate);

      return () => {
        window.removeEventListener('resize', handlePositionUpdate);
        window.removeEventListener('scroll', handlePositionUpdate);
      };
    }, []);
    return (
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={headerGroupKey} {...headerGroupProps}>
              {headerGroup.headers.map((column: ColumnInstance<object>, index) => {
                const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());

                return (
                  <th
                    key={columnKey}
                    {...columnProps}
                    className="tw-border-b tw-border-t tw-border-gray-200 tw-p-4 tw-text-xs tw-text-custom-header-grey"
                  >
                    <div className="tw-flex tw-items-center">
                      {column.render('Header')}
                      {column.extraInfo && (
                        <div
                          ref={setTooltipTriggerRef(column.id)}
                          className="tw-relative tw-flex tw-items-center"
                          onMouseEnter={() => showTooltip(column.id)}
                          onMouseLeave={() => hideTooltip(column.id)}
                        >
                          <span className="tw-ml-2 tw-cursor-help tw-text-gray-500">ⓘ</span>
                          {tooltipVisibility[column.id] && (
                            <TooltipContent
                              columnId={column.id}
                              triggerElement={tooltipTriggerRefs.current[column.id]}
                              index={index}
                              extraInfo={column.extraInfo}
                              positionUpdateRef={positionUpdateRef}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <span className="tw-text-sb-blue-grey-500">
                      {column.isSorted && (
                        <i
                          key={`sort-icon-${column.id}`}
                          className={`fe fe-chevron-${column.isSortedDesc ? 'down' : 'up'}`}
                        />
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
    );
  },
);

export const useTooltipPosition = (triggerElement: HTMLElement | null) => {
  const [position, setPosition] = useState<'top' | 'bottom' | null>(null);
  const rafId = useRef<number | null>(null);
  const calculatePosition = useCallback(() => {
    if (!triggerElement) return;
    if (rafId.current) {
      cancelAnimationFrame(rafId.current);
    }
    rafId.current = requestAnimationFrame(() => {
      const rect = triggerElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceAbove = rect.top;
      const spaceBelow = viewportHeight - rect.bottom;
      const buffer = 20;
      const newPosition = spaceAbove > spaceBelow - buffer ? 'top' : 'bottom';
      setPosition(newPosition);
    });
  }, [triggerElement]);
  const debouncedCalculatePosition = useCallback(debounce(calculatePosition, 100), [calculatePosition]);
  useEffect(() => {
    if (!triggerElement) return;
    calculatePosition();
    const resizeObserver = new ResizeObserver(debouncedCalculatePosition);
    resizeObserver.observe(triggerElement);
    window.addEventListener('scroll', debouncedCalculatePosition);
    window.addEventListener('resize', debouncedCalculatePosition);
    return () => {
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener('scroll', debouncedCalculatePosition);
      window.removeEventListener('resize', debouncedCalculatePosition);
    };
  }, [triggerElement, calculatePosition, debouncedCalculatePosition]);
  return { position, recalculatePosition: calculatePosition };
};
const TooltipContent: React.FC<{
  columnId: string;
  triggerElement: HTMLElement | null;
  index: number;
  extraInfo: React.ReactNode;
  positionUpdateRef: React.MutableRefObject<() => void>;
}> = React.memo(({ columnId, triggerElement, index, extraInfo, positionUpdateRef }) => {
  const { position: tooltipPosition, recalculatePosition } = useTooltipPosition(triggerElement);
  useEffect(() => {
    positionUpdateRef.current = recalculatePosition;
  }, [positionUpdateRef, recalculatePosition]);
  if (tooltipPosition === null) return null;
  return (
    <div className="tw-relative tw-z-10" key={`tooltip-${columnId}`}>
      <span
        className={`tw-absolute ${tooltipPosition === 'bottom' ? 'tw-top-0' : 'tw-bottom-4'} ${
          index === 0 ? 'tw-left-0' : 'tw-right-0'
        } tw-mb-2 tw-w-48 tw-overflow-auto tw-whitespace-normal tw-break-words tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-50 tw-bg-white tw-p-3 tw-text-sm tw-text-gray-700 tw-shadow-lg`}
      >
        {extraInfo}
      </span>
    </div>
  );
});
