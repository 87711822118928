import { coreApi } from '@core/rtk-api';
import type { BillingBalance, Countries, Invoices, PaymentMethod, Vas, VasOverview } from 'src/types/types';

export const billingApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getVasDetails: builder.query<any, { id: string; params: any }>({
        query: ({ id, params = {} }) => ({ url: `/costs/vas/${id}?` + new URLSearchParams(params).toString() }),
      }),
      getVasOverview: builder.query<VasOverview, { params: any }>({
        query: ({ params = {} }) => ({ url: '/costs/vas?' + new URLSearchParams(params).toString() }),
      }),
      getVas: builder.query<Vas, void>({
        query: () => ({ url: '/vas' }),
      }),
      getPayments: builder.query<any, any>({
        query: ({ cursor = null, count = null }: { cursor: string | null; count: number | null }) => {
          let queryString = '';
          if (cursor && count) {
            queryString = `?cursor=${cursor}&count=${count}`;
          } else if (cursor) {
            queryString = `?cursor=${cursor}`;
          } else if (count) {
            queryString = `?count=${count}`;
          }
          return {
            url: `/billing/balance/payments-v2${queryString}`,
          };
        },
      }),
      getCostReport: builder.query<any, { iccid: string; month: string }>({
        query: ({ iccid, month }) => ({ url: `/costs/simcards/${iccid}?month=${month}` }),
      }),
      updateBillingBalance: builder.mutation<any, any>({
        query: (data) => ({
          url: `/billing/balance`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: ['BillingBalance'],
      }),
      deletePaymentMethod: builder.mutation<string, { id: string }>({
        query: ({ id }) => {
          return {
            url: `/billing/methods/${id}`,
            method: 'DELETE',
          };
        },
      }),
      updatePaymentMethod: builder.mutation<string, { id: string }>({
        query: ({ id }) => {
          return {
            url: `/billing/methods/${id}`,
            method: 'PATCH',
          };
        },
        transformResponse(_, __, arg) {
          return arg.id;
        },
      }),
      getPaymentMethods: builder.query<{ methods: PaymentMethod[] }, void>({
        query: () => ({ url: `/billing/methods` }),
      }),
      getBillingBalance: builder.query<BillingBalance, void>({
        query: () => ({ url: `/billing/balance` }),
        providesTags: ['BillingBalance'],
        keepUnusedDataFor: 300,
      }),
      getBillingInvoice: builder.query<any, void>({
        query: () => ({ url: `/billing/invoice` }),
      }),
      createBillingInvoice: builder.mutation<any, any>({
        query: ({ data }) => ({
          url: `/billing/invoice`,
          method: 'POST',
          body: data,
        }),
      }),
      getCountries: builder.query<Countries, void>({ query: () => ({ url: '/countries' }) }),
      getInvoicePdf: builder.query<string, any>({
        query: ({ id }) => ({
          url: `/invoices/${id}`,
          responseHandler: async (response) => {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            return url;
          },
        }),
      }),
      getInvoices: builder.query<Invoices, void>({ query: () => ({ url: '/invoices' }) }),
      createFuturePayment: builder.mutation<any, void>({
        query: () => ({
          url: 'billing/setup_intents',
          method: 'POST',
          body: {},
          transformResponse: (response) => {
            return { payload: response };
          },
        }),
      }),
      createPayment: builder.mutation<any, { amount_in_cents: number; currency: string; payment_method?: string }>({
        query: ({ amount_in_cents, currency, payment_method }) => ({
          url: '/billing/payment_intents',
          method: 'POST',
          body: {
            amount_in_cents,
            currency,
            payment_method,
          },
        }),
        async onQueryStarted({ amount_in_cents }, { dispatch, queryFulfilled }) {
          dispatch(
            billingApi.util.updateQueryData('getBillingBalance', undefined, (draft) => {
              draft.balance += amount_in_cents / 100;
            }),
          );

          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(billingApi.util.invalidateTags(['BillingBalance']));
            }, 15000);
          } catch {
            dispatch(
              billingApi.util.updateQueryData('getBillingBalance', undefined, (draft) => {
                draft.balance -= amount_in_cents / 100;
              }),
            );
          }
        },
      }),
    };
  },
});

export const {
  useGetVasDetailsQuery,
  useLazyGetVasOverviewQuery,
  useLazyGetVasQuery,
  useLazyGetPaymentsQuery,
  useLazyGetCostReportQuery,
  useUpdateBillingBalanceMutation,
  useDeletePaymentMethodMutation,
  useLazyGetPaymentMethodsQuery,
  useUpdatePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useGetBillingBalanceQuery,
  useLazyGetBillingBalanceQuery,
  useGetBillingInvoiceQuery,
  useCreateBillingInvoiceMutation,
  useGetCountriesQuery,
  useLazyGetInvoicePdfQuery,
  useGetInvoicesQuery,
  useCreateFuturePaymentMutation,
  useCreatePaymentMutation,
} = billingApi;
